
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useFetchRequest from '../../../hooks/api/useFetchRequest';
import useLanguage from '../../../hooks/use-language';


import ErrorMessage from '../../typography/ErrorMessage';
import Loader from '../../UI/Loader'
import AnimateIn from '../../UI/AnimateIn';
import MenuItemCard from '../components/MenuItemCard';
import SubcategorySelector from '../components/SubcategorySelector';
import { ReactFitty } from "react-fitty";

import useREMCalculator from '../../../hooks/useREMCalculator';

import dir from '../../../constants/directoryConstants';
import { menuCategories, alcSubcategories, drinkSubcategory, drinkSubcategories, setMenuSubcategory } from 'common/constants/menuConstants';
import { useSelector } from 'react-redux';
import DietaryLegend from '../components/DietaryLegend';


const AlcoholSection = ({ sc, items, showDesc }) => {
    const { lang } = useLanguage()
    const { calculatePixels } = useREMCalculator()
    const filteredItems = items ? items.filter(i => i.subcategory === sc.dbKey).sort((a, b) => a.order - b.order) : []

    return filteredItems.length > 0 ? <div className='drink-menu__alc-section'>
        <h3 className='drink-menu__subcategory-label' >{sc.displayName}</h3>
        {filteredItems.sort((a, b) => a.order - b.order).map(item => {
            return <div key={item._id} className='alc-label'>
                <div className='alc-label__top'>
                    <p className='alc-label__name'><ReactFitty maxSize={calculatePixels(2.6)} >{`${item.nameEng}      `}</ReactFitty><ReactFitty maxSize={calculatePixels(2.2)} className='thai'>{item.nameThai}</ReactFitty></p>
                    <p className='alc-label__price'>{item.price}</p>
                </div>
                {showDesc && <p>{lang === 'en' ? item.descriptionEng : item.descriptionThai}</p>}
            </div>
        })}
    </div> : <></>
}
const DrinkMenu = ({ items }) => {
    const { txt } = useLanguage()
    const alcoholExists = items && items.filter(i => alcSubcategories.includes(i.subcategory)).length > 0

    return (
        <div className='drink-menu'>
            <div className='drink-menu__subhead'>
                <h2 className='drink-menu__subhead--txt-main'>Dessert</h2>
            </div>
            {menuCategories.drinks.subcategories.filter(s => s.dbKey === 'dessert').map((s) => {
                return <div key={s.dbKey} className='drink-menu__section'>
                    <div className='drink-menu__grid-section'>
                        {items && items.filter(i => i.subcategory === s.dbKey)
                            .sort((a, b) => a.order - b.order)
                            .map(item => {
                                const full = s.dbKey === 'mocktails'
                                return <AnimateIn offset={-100} offsetAmt={100} direction='up' key={item._id}><MenuItemCard noDescription={!full} item={item} /></AnimateIn>
                            })}
                    </div>
                </div>
            })}
            <div className='drink-menu__subhead'>
                <h2 className='drink-menu__subhead--txt-main'>{alcoholExists ? 'Non-alcohol' : 'Drinks'}</h2>
            </div>
            <div className='drink-menu__grid'>
                {menuCategories.drinks.subcategories.filter(s => !s.osake && s.dbKey !== 'dessert').sort((a, b) => a.order - b.order).map((s) => {
                    const subItems = items ? items.filter(i => i.subcategory === s.dbKey).sort((a, b) => a.order - b.order) : []
                    if (subItems.length > 0)
                        return <div key={s.dbKey} className='drink-menu__section'>
                            <h3 className='drink-menu__subcategory-label'>{s.displayName}</h3>
                            <div className='drink-menu__grid-section'>
                                {subItems.map(item => {
                                    const full = s.dbKey === 'mocktails'
                                    return <AnimateIn offset={-100} offsetAmt={100} direction='up' key={item._id}><MenuItemCard noDescription={!full} item={item} /></AnimateIn>
                                })}
                            </div>
                        </div>
                })}
            </div>
            {alcoholExists &&
                <>
                    <div className='drink-menu__subhead alc'>
                        <h2 className='drink-menu__subhead--txt-main'>Alcohol</h2>
                        <p className='drink-menu__subhead--txt-msg'>*{txt.MESSAGE_NO_ALCOHOL}</p>
                    </div>
                    <div className='drink-menu__alcohol'>
                        <div className='drink-menu__column'>
                            <AlcoholSection sc={drinkSubcategory(alcSubcategories[0])} items={items} showDesc={true} />
                            <AlcoholSection sc={drinkSubcategory(alcSubcategories[1])} items={items} />
                        </div>
                        <div className='drink-menu__column'>
                            <AlcoholSection sc={drinkSubcategory(alcSubcategories[2])} items={items} />
                            <AlcoholSection sc={drinkSubcategory(alcSubcategories[3])} items={items} />
                        </div>
                    </div>
                </>}
        </div>
    )

}


const MenuBody = () => {
    const { isLunch, isDinner, lastOrder } = useSelector(state => state.schedule)
    const { storeInfo } = useSelector(state => state.storeInfo)
    const [searchParams, setSearchParams] = useSearchParams();
    const { category } = useParams();
    const c = menuCategories[category]

    const subcategory = c.subcategories.find(s => s.dbKey === searchParams.get('s')) ?? c.subcategories[0]
    const isSet = c.dbKey === menuCategories.set_menu.dbKey
    const { loading, data: menuItems, performFetchRequest } = useFetchRequest()
    const [message, setMessage] = useState(null)
    const { servingTimeMessage } = useLanguage()

    useEffect(() => {
        performFetchRequest(`?category=${category}`, dir.MENU_ITEMS)
    }, [category])

    //*IF SET SHOW ERROR MESSAGE
    useEffect(() => {
        if (isSet && subcategory.dbKey === setMenuSubcategory.lunch.dbKey) {
            setMessage(servingTimeMessage(storeInfo.lunch_start, storeInfo.lunch_end))
        } else if (isSet && subcategory.dbKey === setMenuSubcategory.dinner.dbKey) {
            setMessage(servingTimeMessage(storeInfo.dinner_start, storeInfo.dinner_end))
        } else {
            setMessage(null)
        }
    }, [isLunch, isDinner, isSet, subcategory])

    const selectSubcategory = s => setSearchParams({ s: s.dbKey })


    return <section className='section-menu-body'>

        <div>
            {c && <div className='menu-body-header'>
                <h1 className='menu-body-header__txt'>{c.displayName}</h1>
                {c !== menuCategories.drinks && c.subcategories.length > 1 && <SubcategorySelector selected={subcategory} subcategories={c.subcategories} onSelect={selectSubcategory} />}
            </div>}
            <ErrorMessage error={message} />
            {loading && <Loader loading={loading} />}
            {c === menuCategories.drinks ?
                <DrinkMenu items={menuItems} /> :

                <div className={`menu-items-grid${isSet ? ' set' : ''}`}>
                    {menuItems && menuItems
                        .filter(item => item.subcategory === subcategory.dbKey)
                        .sort((a, b) => a.order - b.order)
                        .map((item, i) => {
                            return <AnimateIn offset={0} offsetAmt={100} key={item._id} direction='up'>
                                <MenuItemCard item={item} />
                            </AnimateIn>

                        })}
                </div>}
        </div>
        {c !== menuCategories.drinks && <DietaryLegend />}
    </section >;
};

export default MenuBody;
