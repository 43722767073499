
import { useDispatch } from 'react-redux'
import { ui } from '../constants/reduxConstants'

const useTogglePrivacyPolicy = () => {
    const dispatch = useDispatch()

    const togglePP = (e) => {
        if (e && typeof e.preventDefault === 'function') e.preventDefault()
        dispatch({ type: ui.TOGGLE_PP })
    }

    return togglePP

}

export default useTogglePrivacyPolicy