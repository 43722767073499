import React, { useState } from 'react'
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'
import useLanguage from '../../hooks/use-language'
import useMediaQuery from '../../hooks/useMediaQuery'

import CartRadio from './CartRadio'
import CustomRadioButton from '../UI/CustomRadioButton'

import { showError } from '../../store/actions/uiActions'

import { orderType, paymentMethod } from 'common/constants/orderConstants'
import { IMG_PATH } from '../../constants/conveniences'


const OrderTypeRadio = ({ type, setValue, step, pickupOnly }) => {
    const sp = useMediaQuery()
    const dispatch = useDispatch()
    const { txt, lang } = useLanguage()
    const { storeInfo } = useSelector(state => state.storeInfo)
    const closeTime = storeInfo ? moment(storeInfo['close_time'], 'HH:mm') : null;
    const minTime = moment(Date.now()).add(30, 'minutes')
    const [pickupTime, setPickupTime] = useState(minTime.format('HH:mm'))
    const [pickupLater, setPickupLater] = useState(false)

    const radioSize = sp ? 3 : 2.2

    const orderTypeHandler = (e, oType) => {
        if (e.target.checked) {

            if (oType.dbKey === 'delivery') {
                if (pickupOnly) {
                    dispatch(showError(txt.MESSAGE_PICKUP_ONLY))
                    return
                }

                setValue(paymentMethod.bank.dbKey, 'paymentType')
            }
            setValue(oType.dbKey, 'orderType')
        }
    }

    const pickupLaterHandler = (e, later) => {
        if (e.target.checked) {
            setPickupLater(later)
            setValue(later ? pickupTime : null, 'pickupTime')
        }
    }

    const setTime = time => {

        const newTime = moment(time, 'HH:mm')

        if (closeTime === null) {

            return
        }
        var pTime = closeTime.format('HH:mm')
        if (!newTime.isBefore(minTime) && !newTime.isAfter(closeTime)) {
            pTime = newTime.format('HH:mm')
        } else if (newTime.isBefore(minTime)) {
            pTime = minTime.format('HH:mm')
        }
        setPickupTime(pTime)
        setValue(pTime, 'pickupTime')
    }

    return (
        <CartRadio
            checked={[
                type === orderType.DELIVERY,
                type === orderType.PICKUP]}
            onChange={[
                (e) => orderTypeHandler(e, orderType.DELIVERY),
                (e) => orderTypeHandler(e, orderType.PICKUP)]}
            labels={[
                orderType.DELIVERY.displayName[lang],
                orderType.PICKUP.displayName[lang]]}
            selectedValue={step === 0 ? null : type.displayName[lang]}
            divider >

            <img className='cart__radio-secondary__img' src={IMG_PATH + 'cart/pickup_arrow.png'} alt="" />
            <div className='cart__radio-secondary__rdo'>
                <CustomRadioButton
                    size={radioSize}
                    disabled={type !== orderType.PICKUP}
                    checked={type === orderType.PICKUP && !pickupLater} onChange={(e) => pickupLaterHandler(e, false)}
                    label={txt.PICKUP_NOW} />

                <div className='cart__choose-time'>
                    <CustomRadioButton
                        size={radioSize}
                        disabled={type !== orderType.PICKUP}
                        checked={type === orderType.PICKUP && pickupLater}
                        onChange={(e) => pickupLaterHandler(e, true)}
                        label={txt.CHOOSE_TIME} />

                    <input value={pickupTime}
                        className={`cart__time-input time-input${sp ? '' : '--small'}`}
                        type="time"
                        onChange={(e) => setTime(e.target.value)}
                        min={minTime}
                        disabled={type !== orderType.PICKUP || !pickupLater} />
                </div>
            </div>

        </CartRadio>
    )
}

export default OrderTypeRadio