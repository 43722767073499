import { pp } from '../../constants/reduxConstants';

export const ppReducer = (
  state = { pp: JSON.parse(localStorage.getItem('pp')) ?? null },
  action
) => {
  switch (action.type) {
    case pp.SET_PP:
      localStorage.setItem('pp', JSON.stringify(true));
      return { pp: true };
    default:
      return state;
  }
};
