import { useSelector } from 'react-redux';

const dic = {
  ALERT_PW_LINK_SENT: {
    en: "A link to change your password has been sent to the following email address:",
    th: "ลิ้งค์รหัสผ่านชั่วคราวถูกส่งไปยังอีเมล์นี้"
  },
  REMOVE: { en: 'Remove', th: 'ลบออก' },
  LOGIN: { en: 'Login', th: 'เข้าระบบ' },
  PICKUP_NOW: {
    en: "In 30 min",
    th: "ภายใน 30 นาที"
  },
  CHOOSE_TIME: {
    en: "Choose a time",
    th: "เลือกเวลา"
  },
  ADDRESS_MUANG: { en: 'Mueang Khon Kaen', th: 'เมืองขอนแก่น' },
  ADDRESS_KHON_KAEN: { en: 'Khon Kaen', th: 'ขอนแก่น' },

  // * BUTTONS
  BUTTON_BACK_TO_MENU: {
    en: "Back to menu",
    th: "กลับสู่เมนู"
  },
  BUTTON_BACK: {
    en: "Back",
    th: "กลับ"
  },
  BUTTON_ORDER: {
    en: "Place order",
    th: "ส่งรายการสั่งซื้อ"
  },
  BUTTON_SIGNUP: {
    en: "Create an account",
    th: "สร้างบัญชี"
  },
  BUTTON_CONFIRM_SIGNUP: {
    en: "Sign up",
    th: "สมัครสมาชิก"
  },
  BUTTON_SIGNIN: {
    en: "Have an account?",
    th: "มีบัญชีหรือไม่?"
  },
  BUTTON_BACK_SIGNIN: {
    en: "Back to login",
    th: "กลับสู่หน้าเข้าสู่ระบบ"
  },
  BUTTON_GUEST: {
    en: "Continue as guest",
    th: "ดำเนินการต่อ"
  },
  BUTTON_SEND_PASSWORD: {
    en: "Send link",
    th: "ส่งลิ้งค์"
  },
  BUTTON_NEXT: { en: 'Next', th: 'ต่อไป' },
  BUTTON_LOGIN: { en: 'Login', th: 'เข้าระบบ' },
  BUTTON_SEND_PASSWORD: { en: 'Send Link', th: 'Send Link' },
  BUTTON_EDIT: { en: 'Edit', th: 'แก้ไข' },
  BUTTON_SAVE: { en: 'Save', th: 'บันทึก' },
  BUTTON_CANCEL: { en: 'Cancel', th: 'ยกเลิก' },


  // *PAGE DESCRIPTIONS
  DESCRIPTION_ABOUT_US: {
    en: "We are Khon Kaen's first authentic Indian restaurant and the only one in Isaan awarded Wellness Restaurant status by the Ministry of Public Health for our adherence to high quality, health, and hygiene",
    th: "ควินส์ ร้านอาหารอินเดียแห่งแรกในขอนแก่น และเป็นร้านอาหารอินเดียแห่งเดียวในภาคอีสานที่ได้รับการรับรองเป็น Wellness Restaurant จากกระทรวงสาธารณสุข"
  },
  DESCRIPTION_OUR_RESTAURANT: {
    en: "Our ambiance is characterized by vibrant Indian-style decor. We have two private dining rooms, a spacious garden, and a beautiful bar. We have a seating capacity of over 140 chairs.",
    th: "ควินส์ร้านอาหารอินเดียที่เต็มไปด้านบรรยากาศแบบเมืองอินเดีย มีกลิ่นอายของวัฒนธรรมอินเดียที่เป็นเอกลักษณ์และผ่อนคลาย ที่รองรับได้กว่า 140 ที่นั่ง ในแบบห้องส่วนตัว 2 ห้อง โซนสวนและบาร์เครื่องดื่ม"
  },
  DESCRIPTION_RESERVATION: {
    en: "To reserve a table, please fill in the form below.",
    th: "สำรองที่นั่งโปรดกรอกรายละเอียด"
  },
  DESCRIPTION_OWNERS: {
    en: "Quinn's is owned by Mr. Quinn Dominic and Dr. Jinnapas Dominic. Quinn was born in Sri Lanka but moved to the UK at a very young age. He has experience owning a restaurant in the UK, while his wife is a lecturer at Khon Kaen University, specializing in the hotel and tourism sector. Quinn moved to Thailand over 10 years ago, identified a niche for an Indian restaurant, and thus, Quinn's, the one and only Indian restaurant in the area, was born in 2018.",
    th: "ควินส์ บริหารงานโดยคุณควิน และ ดร.จินณพัษ โดมินิค โดยคุณควินเป็นชาวศรีลังกาที่ย้ายไปตั้งถิ่นฐานยังประเทศอังกฤษ และเป็นผู้ที่มีประสบการณ์ในการดำเนินธุรกิจร้านอาหารในอังกฤษ ส่วน ดร.จินณพัษ ก็เป็นอาจารย์ในมหาวิทยาลัยขอนแก่นที่มีความเชี่ยวชาญในสาขาการโรงแรมและการท่องเที่ยว คุณควินได้ย้ายมาอยู่ที่ประเทศไทยยาวนานกว่า 10 ปี และได้เล็งเห็นถึงโอกาสในการเปิดร้านอาหารอินเดียที่ยังไม่เคยมีในขอนแก่นมาก่อน และได้เปิดให้บริการตั้งแต่ปี 2018 เป็นต้นมา"
  },
  DESCRIPTION_STAFF: {
    en: "Our staff have been personally trained by Dr. Jinnapas to uphold the highest service standards",
    th: "ควินส์ฝึกอบรมให้ความรู้และมาตรฐานการบริการแก่พนักงาน มุ่งเน้นการบริการด้วยมาตรฐานสูงสุด"
  },
  DESCRIPTION_CHEF: {
    en: "Our chefs possess over a decade of expertise in Indian cuisine and have worked in top-class hotels and restaurants in India and Thailand. All of our chefs undergo rigorous training to ensure and maintain consistent quality and the highest standards.",
    th: "เชฟทุกคนจบด้านอาหารและจัดเลี้ยงโดยตรง และมีประสบการณ์ทำงานในโรงแรมและร้านอาหารชั้นนำในอินเดียและไทยกว่า 10 ปี และผ่านการฝึกอบรมอย่างต่อเนื่องเพื่อให้มั่นใจในคุณภาพและมาตรฐานสูงสุด"
  },
  DESCRIPTION_OUR_ROOTS: {
    en: "Quinn moved to Thailand over 10 years ago and noticed a dearth of international restaurants in Khon Kaen. He spotted a niche for an Indian restaurant. Despite advice against the idea due to the belief that Thai people do not like Indian food, Quinn trusted his intuition and pursued his dream, leading to the inception of Quinn's. Now, more than 90% of Quinn's customers are Thai people.",
    th: "คุณควินได้ย้ายมาที่ประเทศไทยมากกว่า 10 ปี และสังเกตเห็นว่าในขอนแก่นมีร้านอาหารนานาชาติเพียงไม่กี่แห่ง และไม่มีร้านอาหารอินเดียเลย ซึ่งเป็นโอกาสที่ดี แม้ว่าจะมีคำแนะนำที่ว่าคนไทยไม่ชอบอาหารอินเดีย คุณควินก็ยังได้ตามหาความฝันและตัดสินใจเปิดร้านควินส์ ตอนนี้กว่า 90% ของลูกค้าควินส์เป็นคนไทย"
  },
  DESCRIPTION_GARDEN: {
    en: "Quinn's garden boasts over 40 seating capacity with a tropical ambiance. Its diverse plants and verdant look create a relaxing atmosphere.",
    th: "บริเวณโซนสวนของร้านควินส์รองรับได้มากกว่า 40 ที่นั่ง ท่ามกลางบรรยากาศการตกแต่งแบบสวนในบ้านอินเดีย ให้ความรู้สึกผ่อนคลาย"
  },
  DESCRIPTION_INDOOR_DINING: {
    en: "Our main indoor area can accomodate over 60 guests and is decorated in an Indian style reflecting absolute calm and relaxation. It is the ideal room for private parties, meetings, conferences, etc",
    th: "ควินส์มีห้องรับรองส่วนตัว 2 ห้องที่รองรับได้มากกว่า 100 ที่นั่ง พร้อมทั้งสิ่งอำนวยความสะดวกในการจัดประชุม งานแต่ง งานเลี้ยงสังสรรค์ หรืองานฉลองต่างๆ ในบรรยากาศการตกแต่งอย่างหรูหรา และเหมาะแก่การเก็บภาพเป็นอย่างมาก"
  },
  DESCRIPTION_BAR: {
    en: "Boasting a chic design and a wide array of beverages, Quinn's offers an ideal setting for unwinding with loved ones.",
    th: "บาร์เครื่องดื่มสไตล์อินเดีย ให้บริการเครื่องดื่มหลากหลาย รวมถึงไวน์คุณภาพจากหลายประเทศ เหมาะแก่การพักผ่อนและสังสรรค์ของทุกท่าน"
  },
  DESCRIPTION_PRIVATE_ROOM: {
    en: "Adorned with traditional Indian decor, this space is a vibrant tapestry of colours that evokes a quintessential Indian theme. Beautiful tables and chairs make it an absolute delight to dine in. With a capacity of over 40 and provisions for full privacy, it is an ideal location for meetings, parties, and conferences.",
    th: "ควินส์มีห้องรับรองส่วนตัว 2 ห้องที่รองรับได้มากกว่า 100 ที่นั่ง พร้อมทั้งสิ่งอำนวยความสะดวกในการจัดประชุม งานแต่ง งานเลี้ยงสังสรรค์ หรืองานฉลองต่างๆ ในบรรยากาศการตกแต่งอย่างหรูหรา และเหมาะแก่การเก็บภาพเป็นอย่างมาก"
  },
  DESCRIPTION_CATERING: {
    en: "We cater authentic Indian cuisine. We provide catering for weddings, parties, anniversaries, etc., within the restaurant or outside.",
    th: "ควินส์์ให้บริการอาหารอินเดีย รวมถึงการบริการจัดเลี้ยงในงานแต่ง ปาร์ตี้ การเลี้ยงฉลอง ทั้งภายในและภายนอกร้านควินส์"
  },

  // *ERRORS
  ERROR_INVALID_TEL: {
    en: "Please enter a valid telephone number",
    th: "โปรดกรอกหมายเลขโทรศัพท์ที่ถูกต้อง"
  },
  ERROR_PASSWORD_MISMATCH: {
    en: "The passwords do not match!",
    th: "รหัสผ่านไม่ถูกต้อง"
  },
  ERROR_PASSWORD_INVALID: {
    en: "The password must be 8-20 characters.",
    th: "รหัสผ่านต้องมีความยาว 8-20 ตัวอักษร"
  },
  ERROR_USERNAME_EXISTS: {
    en: "This email or telephone number has already been registered!",
    th: "อีเมล์หรือหมายเลขโทรศัพท์นี้ได้ถูกลงทะเบียนแล้ว"
  },
  ERROR_RESET_PASSWORD_TIMEOUT: {
    en: "This link is expired. Please return to the login screen and try again.",
    th: "ลิ้งค์นี้หมดอายุ โปรดกลับสู่หน้าลงชื่อเข้าใช้งาน และลองใหม่อีกครั้ง"
  },
  ERROR_UPLOAD_BANKSLIP: {
    en: "Please upload an image of your bank transfer slip to continue.",
    th: "โปรดอัพโหลดรูปสลิปการโอนแล้วดำเนินการต่อ"
  },
  ERROR_SERVER: { en: `We were unable to connect with the order/reservation system. If you wish to place an order or make a reservation, please call us at 091-994-2246. Apologies for the inconvenience.`, th: `เราไม่สามารถเชื่อมต่อระบบคำสั่งซื้อ/สำรองที่นั่งได้ หากท่านประสงค์ที่จะสั่งอาหารหรือสำรองที่นั่ง โปรดติดต่อ 091-994-2246 ขออภัยในความไม่สะดวกมา ณ โอกาสนี้` },

  // * LINKS
  LINK_LOGIN_SIGNUP: {
    en: "Login/Sign up",
    th: "เข้าสู่ระบบ"
  },
  LINK_FORGOT_PASSWORD: {
    en: "Forgot your password? Click here",
    th: "ลืมรหัสผ่าน? กด"
  },


  //* LABELS
  LABEL_PAYMENT_METHOD: {
    en: "Payment",
    th: "วิธีการชำระเงิน"
  },
  LABEL_PROMOTIONS_OPT_IN: {
    en: "I want to receive information about news, promotions, and events via email",
    th: "ฉันต้องการรับข้อมูลเกี่ยวกับข่าวสาร, โปรโมชั่น, และกิจกรรมทางอีเมล"
  },
  LABEL_ORDER_TYPE: {
    en: "Order type",
    th: "ประเภทคำสั่งซื้อ"
  },
  LABEL_ORDER_INFORMATION: {
    en: "Order information",
    th: "รายละเอียดคำสั่งซื้อ"
  },
  LABEL_TOTAL: {
    en: "Total",
    th: "รวม"
  },
  LABEL_REGISTRATION: {
    en: "Registration Information",
    th: "ข้อมูลการลงทะเบียน"
  },
  LABEL_ORDER_HISTORY: {
    en: "Order History",
    th: "ประวัติคำสั่งซื้อ"
  },
  LABEL_NEW_ADDRESS: {
    en: "New Address",
    th: "ที่อยู่ใหม่"
  },
  LABEL_SAVE_ADDRESS: {
    en: "Save to my addresses",
    th: "บันทึกที่อยู่"
  },
  LABEL_FORGOT_PASSWORD: {
    en: "Forgot your password?",
    th: "ลืมรหัสผ่าน?"
  },
  LABEL_PICKUP_ONLY: {
    en: "Pickup only",
    th: "รับด้วยตนเองเท่านั้น"
  },
  LABEL_LOGIN: { en: 'Have an account?', th: 'เข้าระบบ' },
  LABEL_BANK: { en: 'Bank', th: 'ธนาคาร' },
  LABEL_BANK_ACCT: { en: 'Acct#', th: 'เลขบัญชี' },
  LABEL_BANK_NAME: { en: 'Name', th: 'ชื่อ' },
  LABEL_SOLD_OUT: { en: 'Unavailable', th: 'หมด' },
  LABEL_PICKUP_ONLY: { en: 'Pickup only', th: 'Pickup only' },


  MESSAGE_CASH_ONLY: {
    en: "Cash payment only available for pickup orders",
    th: "ชำระด้วยเงินสด เฉพาะกรณีรับด้วยตนเอง"
  },
  MESSAGE_NO_ALCOHOL: {
    en: "Alcohol cannot be ordered online",
    th: "เครื่องดื่มแอลกอฮอล์ไม่สามารถสั่งซื้อออนไลน์ได้"
  },
  MESSAGE_CLOSED: {
    en: "Sorry, we are currently closed! Please order again during normal business hours",
    th: "ขออภัย ขณะนี้ร้านปิด! โปรดสั่งอีกครั้งในเวลาทำการ"
  },
  MESSAGE_PASSWORD_UPDATED: {
    en: "Password updated successfully!",
    th: "รหัสผ่านได้ถูกอัพเดทแล้ว!"
  },
  MESSAGE_NO_ITEMS: {
    en: "There are currently no items in your cart.",
    th: "ขณะนี้ไม่มีรายการสั่งซื้อ"
  },
  MESSAGE_UPLOAD_QR: {
    en: "Please transfer payment to the account below and upload the tranfer slip.",
    th: "โปรดโอนชำระไปยังบัญชีข้างล่างนี้ และแนบสลิปการโอน"
  },
  MESSAGE_RESERVATION_SENT: {
    en: "Your reservation has been sent! You should recieve a confirmation email shortly.",
    th: "คำขอสำรองรองที่นั่งได้ถูกส่งแล้ว"
  },

  MESSAGE_PICKUP_ONLY: {
    en: "One or more items in your order is only available for pickup.",
    th: "บางรายการในคำสั่งซื้อ เป็นการรับด้วยตนเองเท่านั้น"
  },
  MESSAGE_LAST_ORDER: {
    en: "We have stopped accepting orders for the night. Please try again tomorrow.",
    th: "เราหยุดรับคำสั่งซื้อในคืนนี้ โปรดลองอีกครั้งพรุ่งนี้"
  },
  MESSAGE_LAST_ORDER_PROD: {
    en: "IMPORTANT: PLEASE DO NOT LEAVE OR REFRESH THIS PAGE!! As we have stopped accepting orders for the night, if you leave this page before completing your order, you will not be able to come back. Please note that the system will stop taking orders soon, so you must complete your order within 15 minutes!",
    th: "สำคัญมาก: อย่ารีเฟรชหน้านี้!! เนื่องจากเราหยุดรับคำสั่งซื้อในคืนนี้แล้ว หากคำสั่งซื้ออยู่ระหว่างดำเนินการ จะไม่สามารถย้อนกลับได้ ระบบกำลังจะปิดเร็วๆ นี้ โปรดยืนยันคำสั่งซื้อภายใน 15 นาที"
  },
  MESSAGE_SOLD_OUT_CART: {
    en: "One or more items in your cart are not available at this time. Please remove them before continuing.",
    th: "บางรายการในคำสั่งซื้อ ไม่สามารถให้บริการได้ โปรดยกเลิกรายการนั้นและดำเนินการต่อ"
  },
  MESSAGE_ENTER_NEW_PASSWORD: {
    en: "Please enter a new password for ",
    th: "โปรดกรอกรหัสผ่านใหม่สำหรับ "
  },
  //TODO
  MESSAGE_ORDER_SUCCESS: { en: "Your order has been placed! You will be updated by email when it is ready!", th: "Your order has been placed! You will be updated by email when it is ready!" },

  // * PLACEHOLDERS
  PLACEHOLDER_USERNAME: {
    en: "Email or Tel",
    th: "อีเมล์ หรือ โทร"
  },
  PLACEHOLDER_PASSWORD: {
    en: "Password",
    th: "รหัสผ่าน"
  },
  PLACEHOLDER_NEW_PASSWORD: {
    en: "New password",
    th: "รหัสผ่านใหม่"
  },
  PLACEHOLDER_CURRENT_PASSWORD: {
    en: "Current password",
    th: "รหัสผ่านปัจจุบัน"
  },
  PLACEHOLDER_REENTER_PASSWORD: {
    en: "Re-enter password",
    th: "ใส่รหัสผ่านอีกครั้ง"
  },
  PLACEHOLDER_EMAIL: {
    en: "Email",
    th: "อีเมล์"
  },
  PLACEHOLDER_TEL: {
    en: "Tel",
    th: "โทร"
  },
  PLACEHOLDER_ADDRESS_DIRECTIONS: {
    en: "Notes/Directions/Google Maps link",
    th: "ข้อมูล/เส้นทาง/ลิงค์ของ Google Maps"
  },
  PLACEHOLDER_NOGUESTS: {
    en: "No. Guests",
    th: "จำนวนแขก"
  },
  PLACEHOLDER_OTHER_INFO: {
    en: "Other information (preferred seating, etc.)",
    th: "ข้อมูลอื่นๆ (AC room, garden, etc.)"
  },
  PLACEHOLDER_NAME: { en: 'Name', th: 'ชื่อ นามสกุล' },
  PLACEHOLDER_ADDRESS_NAME: { en: 'Building/Village', th: 'ชื่อตึก/หมู่บ้าน' },
  PLACEHOLDER_ADDRESS_MOO: { en: 'Moo', th: 'หมุ่' },
  PLACEHOLDER_ADDRESS_TAMBON: { en: 'Tambon', th: 'ตำบล' },
  PLACEHOLDER_ADDRESS_AMPHUR: { en: 'Amphur', th: 'อำเภอ' },
  PLACEHOLDER_ADDRESS_STREET: { en: 'Street Address', th: 'หมายเลขบ้าน ถนน' },

  // * WARNINGS
  WARNING_CART_BACK: {
    en: "Are you sure you want to go back? You will have to re-start your order process from the beginning",
    th: "คุณแน่ใจหรือไม่ว่าต้องการย้อนกลับ? คุณต้องเริ่มดำเนินการใหม่อีกครั้ง"
  },
  WARNING_CART_BACK_LAST_ORDER: {
    en: "WARNING! Because it is past the last order time, you will not be able to complete your order if you leave this page! Are you sure you want to discard your order?",
    th: "คำเตือน! เนื่องจากเป็นช่วงเวลาหยุดรับคำสั่งซื้อ คุณจะไม่สามารถยืนยันคำสั่งซื้อได้หากปิดหน้านี้! คุณต้องการยกเลิกคำสั่งซื้อหรือไม่?"
  },
  CONFIRM_DELETE_ADDRESS: {
    en: "Are you sure you want to delete this address? It cannot be undone.",
    th: "คุณแน่ใจหรือไม่ที่จะลบที่อยู่นี้? จะไม่สามารถเรียกคืนได้"
  },
};

const useLanguage = () => {
  const { language: lang } = useSelector((state) => state.ui);


  const txt = {
    ALERT_PW_LINK_SENT: dic.ALERT_PW_LINK_SENT[lang],
    REMOVE: dic.REMOVE[lang],
    LOGIN: dic.LOGIN[lang],
    PICKUP_NOW: dic.PICKUP_NOW[lang],
    CHOOSE_TIME: dic.CHOOSE_TIME[lang],
    ADDRESS_MUANG: dic.ADDRESS_MUANG[lang],
    ADDRESS_KHON_KAEN: dic.ADDRESS_KHON_KAEN[lang],
    BUTTON_BACK_TO_MENU: dic.BUTTON_BACK_TO_MENU[lang],
    BUTTON_BACK: dic.BUTTON_BACK[lang],
    BUTTON_NEXT: dic.BUTTON_NEXT[lang],
    BUTTON_ORDER: dic.BUTTON_ORDER[lang],
    BUTTON_GUEST: dic.BUTTON_GUEST[lang],
    BUTTON_SIGNUP: dic.BUTTON_SIGNUP[lang],
    BUTTON_CONFIRM_SIGNUP: dic.BUTTON_CONFIRM_SIGNUP[lang],
    BUTTON_SIGNIN: dic.BUTTON_SIGNIN[lang],
    BUTTON_BACK_SIGNIN: dic.BUTTON_BACK_SIGNIN[lang],
    BUTTON_LOGIN: dic.BUTTON_LOGIN[lang],
    BUTTON_SEND_PASSWORD: dic.BUTTON_SEND_PASSWORD[lang],
    BUTTON_EDIT: dic.BUTTON_EDIT[lang],
    BUTTON_SAVE: dic.BUTTON_SAVE[lang],
    BUTTON_CANCEL: dic.BUTTON_CANCEL[lang],
    CONFIRM_DELETE_ADDRESS: dic.CONFIRM_DELETE_ADDRESS[lang],
    DESCRIPTION_ABOUT_US: dic.DESCRIPTION_ABOUT_US[lang],
    DESCRIPTION_OUR_RESTAURANT: dic.DESCRIPTION_OUR_RESTAURANT[lang],
    DESCRIPTION_RESERVATION: dic.DESCRIPTION_RESERVATION[lang],
    DESCRIPTION_OWNERS: dic.DESCRIPTION_OWNERS[lang],
    DESCRIPTION_STAFF: dic.DESCRIPTION_STAFF[lang],
    DESCRIPTION_CHEF: dic.DESCRIPTION_CHEF[lang],
    DESCRIPTION_OUR_ROOTS: dic.DESCRIPTION_OUR_ROOTS[lang],
    DESCRIPTION_GARDEN: dic.DESCRIPTION_GARDEN[lang],
    DESCRIPTION_INDOOR_DINING: dic.DESCRIPTION_INDOOR_DINING[lang],
    DESCRIPTION_PRIVATE_ROOM: dic.DESCRIPTION_PRIVATE_ROOM[lang],
    DESCRIPTION_BAR: dic.DESCRIPTION_BAR[lang],
    DESCRIPTION_CATERING: dic.DESCRIPTION_CATERING[lang],
    ERROR_INVALID_TEL: dic.ERROR_INVALID_TEL[lang],
    ERROR_PASSWORD_MISMATCH: dic.ERROR_PASSWORD_MISMATCH[lang],
    ERROR_PASSWORD_INVALID: dic.ERROR_PASSWORD_INVALID[lang],
    ERROR_RESET_PASSWORD_TIMEOUT: dic.ERROR_RESET_PASSWORD_TIMEOUT[lang],
    ERROR_USERNAME_EXISTS: dic.ERROR_USERNAME_EXISTS[lang],
    ERROR_UPLOAD_BANKSLIP: dic.ERROR_UPLOAD_BANKSLIP[lang],
    ERROR_SERVER: dic.ERROR_SERVER[lang],
    LINK_LOGIN_SIGNUP: dic.LINK_LOGIN_SIGNUP[lang],
    LINK_FORGOT_PASSWORD: dic.LINK_FORGOT_PASSWORD[lang],
    LABEL_BANK: dic.LABEL_BANK[lang],
    LABEL_BANK_ACCT: dic.LABEL_BANK_ACCT[lang],
    LABEL_PROMOTIONS_OPT_IN: dic.LABEL_PROMOTIONS_OPT_IN[lang],
    LABEL_BANK_NAME: dic.LABEL_BANK_NAME[lang],
    LABEL_PAYMENT_METHOD: dic.LABEL_PAYMENT_METHOD[lang],
    LABEL_ORDER_TYPE: dic.LABEL_ORDER_TYPE[lang],
    LABEL_ORDER_INFORMATION: dic.LABEL_ORDER_INFORMATION[lang],
    LABEL_TOTAL: dic.LABEL_TOTAL[lang],
    LABEL_LOGIN: dic.LABEL_LOGIN[lang],
    LABEL_REGISTRATION: dic.LABEL_REGISTRATION[lang],
    LABEL_ORDER_HISTORY: dic.LABEL_ORDER_HISTORY[lang],
    LABEL_NEW_ADDRESS: dic.LABEL_NEW_ADDRESS[lang],
    LABEL_SAVE_ADDRESS: dic.LABEL_SAVE_ADDRESS[lang],
    LABEL_FORGOT_PASSWORD: dic.LABEL_FORGOT_PASSWORD[lang],
    LABEL_SOLD_OUT: dic.LABEL_SOLD_OUT[lang],
    LABEL_PICKUP_ONLY: dic.LABEL_PICKUP_ONLY[lang],
    MESSAGE_CASH_ONLY: dic.MESSAGE_CASH_ONLY[lang],
    MESSAGE_NO_ALCOHOL: dic.MESSAGE_NO_ALCOHOL[lang],
    MESSAGE_CLOSED: dic.MESSAGE_CLOSED[lang],
    MESSAGE_PASSWORD_UPDATED: dic.MESSAGE_PASSWORD_UPDATED[lang],
    MESSAGE_NO_ITEMS: dic.MESSAGE_NO_ITEMS[lang],
    MESSAGE_UPLOAD_QR: dic.MESSAGE_UPLOAD_QR[lang],
    MESSAGE_RESERVATION_SENT: dic.MESSAGE_RESERVATION_SENT[lang],
    MESSAGE_PICKUP_ONLY: dic.MESSAGE_PICKUP_ONLY[lang],
    MESSAGE_SOLD_OUT_CART: dic.MESSAGE_SOLD_OUT_CART[lang],
    MESSAGE_LAST_ORDER: dic.MESSAGE_LAST_ORDER[lang],
    MESSAGE_LAST_ORDER_PROD: dic.MESSAGE_LAST_ORDER_PROD[lang],
    MESSAGE_ORDER_SUCCESS: dic.MESSAGE_ORDER_SUCCESS[lang],
    MESSAGE_ENTER_NEW_PASSWORD: dic.MESSAGE_ENTER_NEW_PASSWORD[lang],
    PLACEHOLDER_USERNAME: dic.PLACEHOLDER_USERNAME[lang],
    PLACEHOLDER_PASSWORD: dic.PLACEHOLDER_PASSWORD[lang],
    PLACEHOLDER_REENTER_PASSWORD: dic.PLACEHOLDER_REENTER_PASSWORD[lang],
    PLACEHOLDER_NEW_PASSWORD: dic.PLACEHOLDER_NEW_PASSWORD[lang],
    PLACEHOLDER_CURRENT_PASSWORD: dic.PLACEHOLDER_CURRENT_PASSWORD[lang],
    PLACEHOLDER_NAME: dic.PLACEHOLDER_NAME[lang],
    PLACEHOLDER_EMAIL: dic.PLACEHOLDER_EMAIL[lang],
    PLACEHOLDER_TEL: dic.PLACEHOLDER_TEL[lang],
    PLACEHOLDER_ADDRESS_AMPHUR: dic.PLACEHOLDER_ADDRESS_AMPHUR[lang],
    PLACEHOLDER_ADDRESS_MOO: dic.PLACEHOLDER_ADDRESS_MOO[lang],
    PLACEHOLDER_ADDRESS_DIRECTIONS: dic.PLACEHOLDER_ADDRESS_DIRECTIONS[lang],
    PLACEHOLDER_ADDRESS_AMPHUR: dic.PLACEHOLDER_ADDRESS_AMPHUR[lang],
    PLACEHOLDER_ADDRESS_NAME: dic.PLACEHOLDER_ADDRESS_NAME[lang],
    PLACEHOLDER_ADDRESS_TAMBON: dic.PLACEHOLDER_ADDRESS_TAMBON[lang],
    PLACEHOLDER_ADDRESS_STREET: dic.PLACEHOLDER_ADDRESS_STREET[lang],
    PLACEHOLDER_NOGUESTS: dic.PLACEHOLDER_NOGUESTS[lang],
    PLACEHOLDER_OTHER_INFO: dic.PLACEHOLDER_OTHER_INFO[lang],
    WARNING_CART_BACK: dic.WARNING_CART_BACK[lang],
    WARNING_CART_BACK_LAST_ORDER: dic.WARNING_CART_BACK_LAST_ORDER[lang],

  };

  const servingTimeMessage = (startTime, endTime, short) => {
    if (lang === 'th') return short ? `มีบริการระหว่างเวลา ${startTime} - ${endTime} น. เท่านั้น` : `โปรดทราบ: รายการอาหารนี้ มีบริการระหว่างเวลา ${startTime} - ${endTime} น. เท่านั้น`
    return short ? `Only available between ${startTime} and ${endTime}.` : `NOTE: These menu items are only available between ${startTime} and ${endTime}.`
  }

  return { txt, lang, servingTimeMessage };
};

export default useLanguage;
