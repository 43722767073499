export const handleFail = (dispatch, error, dispatchType) => {
  dispatch({
    type: dispatchType,
    payload:
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
  });
};

export const setConfig = (jsonHeader, getState) => {
  var userInfo;

  if (getState) {
    const { userLogin } = getState();
    userInfo = userLogin.userInfo;
  }

  return {
    headers: {
      ...(jsonHeader && { 'Content-Type': 'application/json' }),
      ...(userInfo && { Authorization: `Bearer ${userInfo.token}` }),
    },
  };
};
