import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalContainer from '../UI/ModalContainer'

import { ui } from '../../constants/reduxConstants';

import { storeConsts as str } from '../../constants/storeInfoConstants'


const PrivacyPolicy = () => {
    const dispatch = useDispatch()
    const { ppShowing } = useSelector(state => state.ui)
    const { storeInfo } = useSelector(state => state.storeInfo)

    return (
        <ModalContainer showing={ppShowing} onClose={() => dispatch({ type: ui.TOGGLE_PP })}>
            <div className='privacy-policy'>
                <div className='privacy-policy__head'>
                    <h1>Privacy Policy for Quinn's One & Only</h1>
                    <p>At Quinn's One & Only, accessible from {window.location.hostname}, we prioritize your privacy. This Privacy Policy describes the types of information we collect, how we record it, and how we use it.</p>
                </div>

                <div className='privacy-policy__body'>
                    <div className='privacy-policy__block'>
                        <h2>How We Collect Personal Data</h2>
                        <p>We collect your personal data when you create an account on our website, visit our restaurant or make reservations, participate in activities held by us, or interact with us in any other way. Online usage information is collected when you visit our website, which includes information about your Internet service provider, operating system, browser type, domain name, IP address, your access times, and the date and time of those requests.</p>
                    </div>

                    <div className='privacy-policy__block'>
                        <h2>What Personal Data We Collect</h2>
                        <p>We collect and process the following personal data: your full name, address, email, and telephone numbers. These data are used only to facilitate orders and reservations in-store.</p>
                    </div>

                    <div className='privacy-policy__block'>
                        <h2>Our Use of Cookies</h2>
                        <p>Like many websites, we use cookies to improve your experience on our site by remembering your cart contents and login status. These cookies do not track your activities for advertising or marketing purposes.</p>
                    </div>

                    <div className='privacy-policy__block'>
                        <h2>Your Rights</h2>
                        <p>As a user, you have several rights including the right to request access to, correction of, deletion of, object to our use and processing of, limit our use and processing of, and request portability of your personal data.</p>
                    </div>

                    <div className='privacy-policy__block'>
                        <h2>Security of Your Personal Data</h2>
                        <p>We are committed to ensuring the security of your personal data. We have implemented technical and organizational measures to secure your data from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
                    </div>
                    <div className='privacy-policy__block'>
                        <h2>Third-Party Privacy Policies</h2>
                        <p>Quinn's One & Only's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    </div>
                    <div className='privacy-policy__block'>
                        <h2>Our Contact Details</h2>
                        <p>If you have any questions or concerns about our privacy policy, please contact us at: Quinn's One & Only, {storeInfo[str.address_eng.dbKey]}, Email: {storeInfo.email}, Phone: {storeInfo.tel}</p>
                    </div>

                    <div className='privacy-policy__block'>
                        <h2>Consent</h2>
                        <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}

export default PrivacyPolicy;
