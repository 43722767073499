import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ResponsiveImg from '../UI/ResponsiveImg';
import HamburgerButton from "./HamburgerButton";
import { ui } from '../../constants/reduxConstants';
import useContainerDimensions from '../../hooks/use-dimensions';
import { toggleLogin } from "../../store/actions/loginScreenActions";


const NavBarMobile = () => {
    const { userInfo } = useSelector(state => state.userLogin)
    const { hamShowing } = useSelector(state => state.ui)
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { height } = useContainerDimensions(ref)

    const toggleHam = () => {
        dispatch({ type: ui.TOGGLE_HMENU })
    }

    useEffect(() => {

        dispatch({ type: ui.SET_NAV_HEIGHT, payload: height })
    }, [])

    const handleProfile = () => {
        dispatch({ type: ui.CLOSE_HMENU })
        if (!userInfo) {
            dispatch(toggleLogin())
        }

    }

    return (
        <div ref={ref} className='m-nav'>
            <div className='m-nav__ham'>
                <HamburgerButton open={hamShowing} onClick={toggleHam} />
            </div>

            <Link to='/?scr=true' className='m-nav__logo-wrapper'>
                <ResponsiveImg src="logo/quinns_logo" className='m-nav__logo' outerStyle={{ height: '100%' }} />
                {/* <img className='m-nav__logo' src={IMG_PATH + 'navigation/nav-logo.png'} alt="" /> */}
            </Link>
            <Link className='m-nav__profile' onClick={handleProfile} to={userInfo ? 'profile/' : '#'}><span className="material-icons m-nav__profile--icn">account_circle</span></Link>

        </div>
    )
}

export default NavBarMobile