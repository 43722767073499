import React, { useRef } from 'react'
import useDynamicScrollbar from '../../hooks/useDynamicScrollbar'

const ScrollableDiv = ({ className, children }) => {
    const ref = useRef(null)
    useDynamicScrollbar(ref)
    return (
        <div ref={ref} className={`scrollable-div${className ? ' ' + className : ''}`}>{children}</div>
    )
}

export default ScrollableDiv