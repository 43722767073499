import axios from "axios";
import { useCallback, useState } from "react"



const useUpdateRequest = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState(null);




    const performUpdateRequest = useCallback(async (object, dir, token, completionHandler) => {

        try {
            setError(null)
            setLoading(true)
            setSuccess(false)
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            }

            const { data } = await axios.put(
                `/api/${dir}${object._id ? object._id : ''}`,
                object,
                config
            );

            setLoading(false)
            setData(data)
            setSuccess(true)
            completionHandler && completionHandler(data)

        } catch (error) {
            var err = error.response ? error.response.data ? error.response.data : error.response : error

            setLoading(false)
            setError(err)
        }

    })

    const resetUpdateRequest = useCallback(() => {
        setError(null)
        setLoading(false)
        setSuccess(false)
    })

    return { loading, error, success, data, performUpdateRequest, resetUpdateRequest }

}

export default useUpdateRequest