import React from 'react'

const DietaryIcon = ({ dietaryOption, className }) => {
    const imgURL = process.env.PUBLIC_URL + '/assets/images/menu/'
    return (
        <img className={className} src={imgURL + dietaryOption.key + '_icon.png'} alt={dietaryOption.displayName.en} />
    )

}

export default DietaryIcon