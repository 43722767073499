import { useLocation } from "react-router-dom";

const useBasePathComponent = () => {
    const location = useLocation();

    var pathName = location.pathname;

    const components = pathName.split('/');

    if (components.length > 1) {
        pathName = components[1];
    }

    return pathName
}

export default useBasePathComponent;