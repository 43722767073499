import React from 'react'
import { useState } from 'react'

const AmountPicker = ({ item, onSet, btnSize = 3, fontSize, disabled }) => {
    const [num, setNum] = useState(item.amount ?? 1)


    const changeHandler = (amt) => {
        onSet(num + amt)
        setNum(pVal => pVal + amt)
    }

    const btnStyle = { height: `${btnSize}rem`, width: `${btnSize}rem` }
    const iconStyle = { fontSize: `${btnSize * .4}rem` }
    const amtStyle = { fontSize: `${fontSize ?? btnSize * .93}rem` }

    return (

        <div className='counter'>
            <button className='btn-amt left' style={btnStyle} disabled={!item.inStock || disabled} onClick={() => { if (num > 1) { changeHandler(-1) } }}> <i className='fas fa-minus' style={iconStyle} /> </button>
            <h1 className='disp-amt' style={amtStyle}>{num}</h1>
            <button className='btn-amt right' style={btnStyle} disabled={!item.inStock || disabled} onClick={() => { if (num < 20) { changeHandler(1) } }}><i className='fas fa-plus' style={iconStyle} /> </button>
        </div>


    )
}

export default AmountPicker