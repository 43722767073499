
import useMediaQuery from "../../../hooks/useMediaQuery";
import useLanguage from "../../../hooks/use-language";

import TeaserText from "../../typography/TeaserText";
import ParallaxImage from "../../UI/ParallaxImage";

const LOREM = 'Lorem ipsum dolor sit. Soluta tempora a perspiciatis dolores eos distinctio illum? Numquam voluptatem vitae laboriosam officia voluptates odit molestiae?';

const Restaurant = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return <div className='section-restaurant'>
        <div className='restaurant-image-wrapper'>
            <ParallaxImage bkgAttachment='local' multiplier={0.05} bkgSize={sp ? '140%' : '100%'} rightOffset={sp ? 50 : 100} bottomOffset={sp ? -20 : 20} imgName='home/inside-1.jpg' />
        </div>
        <div className='restaurant-txt'>
            <TeaserText title={<>Our<br />Restaurant</>} text={txt.DESCRIPTION_OUR_RESTAURANT} path='restaurant/' />
        </div>
    </div>
}

export default Restaurant;