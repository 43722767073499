import React from 'react'
import useFormValidator from '../../hooks/use-validator'

const ValidatorInput = ({ value, type, placeholder, onChange, required }) => {
    const { validateTel } = useFormValidator(value, type)

    const changeHandler = (e) => {
        if (type === 'tel' && !validateTel(e.target.value)) {
            return
        }

        onChange(e.target.value)
    }

    return (
        <input value={value} type={type} className='form__field' placeholder={placeholder} onChange={onChange} required={required} />
    )
}

export default ValidatorInput