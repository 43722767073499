import { ui } from '../../constants/reduxConstants';

export const setLanguage = (lng) => async (dispatch, getState) => {
  dispatch({
    type: ui.LANGUAGE_SET,
    payload: lng,
  });

  localStorage.setItem('language', lng);
};

export const showError = (message) => async (dispatch, getState) => {
  dispatch({
    type: ui.SET_ALERT,
    payload: { title: 'Error!', message },
  });
};

export const showAlert = (title, message) => async (dispatch, getState) => {
  dispatch({
    type: ui.SET_ALERT,
    payload: { title, message },
  });
};
