import tambons from '../data/tambons.json'

const useTambon = (lang) => {

    const nameKey = lang === 'th' ? "name_th" : "name_en"

    const tambonList = tambons.map((t) => {
        return lang === 'th' ? t[nameKey] : t[nameKey]
    })

    const getTambonID = (name) => {
        const filtered = tambons.filter(t => t["name_th"] === name || t["name_en"] === name)
        if (filtered.length > 0) {
            return filtered[0].id
        }
    }

    const getTambonName = (id) => {
        const filtered = tambons.filter(t => t["id"] === id)
        if (filtered.length > 0) {
            return filtered[0][nameKey]
        }
    }


    return { tambonList, getTambonID, getTambonName };

}

export default useTambon;

