import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import AnimateIn from '../../UI/AnimateIn'
import { IMG_PATH } from '../../../constants/conveniences'
import DescText from '../../typography/DescText'



const Owner = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return (
        <section className='section-owner'>
            <div className="owner__header">
                <AnimateIn offsetAmt={sp ? 100 : 300}>
                    <h2 className='about-title'>Owners</h2>
                </AnimateIn>
            </div>
            <div className='section-owner__image'>
                {/* <div className='owner__img' /> */}
                <img className='owner__img' src={IMG_PATH + '/about/owners.jpg'} alt="Quinn & Cake" />

            </div>
            <div className='section-owner__text'>
                <AnimateIn delayOnImmediate={300} offsetAmt={sp ? 50 : 300} >
                    <h3 className='owner__subhead'>{'Quinn & Cake'}</h3>
                </AnimateIn>
                <AnimateIn direction={sp ? 'up' : 'left'} offsetAmt={sp ? 100 : 300} delayOnImmediate={600} >
                    <DescText className='owner__intro'>{txt.DESCRIPTION_OWNERS}</DescText>
                </AnimateIn>
            </div >

        </section >
    )
}

export default Owner