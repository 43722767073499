import axios from "axios";
import { useCallback, useState } from "react"

const useCreateRequest = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState(null);

    const performCreateRequest = useCallback(async (object, dir, token) => {

        try {
            setError(null)
            setLoading(true)
            setSuccess(false)
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            }


            const { data } = await axios.post(
                `/api/${dir}`,
                object,
                config
            );

            setData(data)
            setSuccess(true)
            setLoading(false)

        } catch (error) {
            console.log(`got error`);
            const err = error.response ? error.response.data ? error.response.data : error.response : error
            setLoading(false)

            setError(err)
        }

    })

    const clear = () => {
        setData(null)
        setSuccess(null)
        setLoading(null)
        setError(null)
    }



    return { loading, error, success, data, performCreateRequest, clear }

}

export default useCreateRequest