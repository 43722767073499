
export const FIELD_FORMAT = {
    zip: {
        pattern: new RegExp('^[0-9]+$'),
        minChar: 7,
        stripChar: '-',
        format: '###-####'
    },
    tel: {
        pattern: new RegExp('^[0-9]+$'),
        minChar: 10,
        stripChar: '-',
        format: '###-###-####'
    }
}

const useFieldFormatter = (type) => {

    const formatDisplay = (value) => {
        if (!type || !type.format) return value
        var iterator = 0;
        const format = type.format
        var output = ''
        for (var i = 0; i < format.length; i++) {
            if (!value[iterator]) break
            if (format[i] === '#') {
                output += value[iterator]
                iterator++
            } else {
                output += format[i]
            }
        }
        return output
    }

    const stripOutput = (input) => {
        if (!type) return input;
        if (!type.stripChar) return input

        if (input.replaceAll) {
            return input.replaceAll(type.stripChar ?? '', '')
        }

        return input.split(type.stripChar).join("");

    }

    const inputAllowed = (input) => {

        if (input === '' || !type) return true

        if (!input.match(type.pattern) || (type.maxChar && input.length > type.maxChar)) {
            return false
        }

        return true
    }

    return { inputAllowed, formatDisplay, stripOutput }
}

export default useFieldFormatter