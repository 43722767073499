import React from 'react'
import DietaryIcon from './DietaryIcon'
import useLanguage from '../../../hooks/use-language'
import { dietaryOption } from 'common/constants/menuConstants'

const DietaryLegend = () => {
    const { lang } = useLanguage()
    return (
        <div className='dietary-legend'>
            {Object.entries(dietaryOption).map(([key, option]) => (
                <div className='dietary-legend__item' key={key}>
                    <DietaryIcon dietaryOption={option} className='dietary-legend__symbol' />
                    <p className='dietary-legend__label'>= {option.displayName[lang]}</p>
                </div>
            ))}
        </div>
    )
}

export default DietaryLegend