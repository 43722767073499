const orderType = {
  DELIVERY: {
    dbKey: 'delivery',
    displayName: { en: 'Delivery', th: 'Delivery' },
  },
  PICKUP: { dbKey: 'pickup', displayName: { en: 'Pickup', th: 'Pickup' } },
  EAT_IN: { dbKey: 'eat_in', displayName: { en: 'Eat-In', th: 'Eat-In' } },
};

const getOrderTypeConst = (type) => {
  switch (type) {
    case orderType.DELIVERY.dbKey:
      return orderType.DELIVERY;
    case orderType.PICKUP.dbKey:
      return orderType.PICKUP;
    case orderType.EAT_IN.dbKey:
      return orderType.EAT_IN;
    default:
      return orderType.PICKUP;
  }
};

const orderStatus = {
  ORDERED: {
    dbKey: 'ordered',
    displayName: 'Ordered',
    color: '#fd7e14',
    emailTxt: {
      subject: 'We have received your order!/เราได้รับคำสั่งซื้อของท่านแล้ว',
      header:
        "Your order with Quinn's One & Only has been placed คำสั่งซื้อของท่านที่ร้านควินส์ได้รับการยืนยันแล้ว",
      message:
        "We will let you know when it's in the kitchen. เราจะแจ้งให้ท่านทราบเมื่อรายการถูกส่งไปยังครัว",
    },
  },
  KITCHEN: {
    dbKey: 'kitchen',
    displayName: { en: 'In the Kitchen', th: 'In the Kitchen' },
    color: '#3498db',
    emailTxt: {
      subject: 'Your order is in the kitchen!/คำสั่งซื้อถูกส่งไปยังครัวแล้ว',
      header:
        'Not much longer now! We are currently preparing your order. รออีกไม่นาน! เรากำลังปรุงอาหารของท่าน',
      message:
        "We will let you know when it's ready. เราจะแจ้งให้ท่านทราบเมื่ออาหารเสร็จแล้ว",
    },
  },
};

const deliveryStatus = {
  ...orderStatus,
  DELIVERING: {
    dbKey: 'delivering',
    displayName: { en: 'Out for Delivery', th: 'Out for Delivery' },
    color: '#6610f2',
    emailTxt: {
      subject:
        'Your order is out for delivery!/อาหารของท่านอยู่ระหว่างการจัดส่ง!',
      header: 'Your order is on the way. อาหารของท่านอยู่ระหว่างเดินทาง',
      message:
        "Get ready! Your order with Quinn's One & Only is out for delivery. พร้อมหรือยัง! อาหารของท่านอยู่ระหว่างการจัดส่ง",
    },
  },
};

const pickupStatus = {
  ...orderStatus,
  READY: {
    dbKey: 'ready',
    displayName: 'Ready for Pickup',
    color: '#6610f2',
    emailTxt: {
      subject: 'Your order is ready!/อาหารของท่านเสร็จแล้ว',
      header:
        "Your order at Quinn's One & Only is ready for pickup. อาหารของท่านเสร็จแล้ว อยู่ระหว่างการรอรับ",
      message:
        'Your food is waiting for you at the restaurant! อาหารของท่านกำลังรอท่านอยู่ที่ร้าน',
    },
  },
};
const finishStatus = {
  CANCELED: {
    dbKey: 'canceled',
    displayName: { en: 'Canceled', th: 'Canceled' },
    color: '#e74c3c',
    emailTxt: {
      subject: 'Your order was canceled!/คำสั่งซื้อของท่านถูกยกเลิกแล้ว',
      header:
        "Your order at Quinn's One & Only has been canceled. คำสั่งซื้อของท่านที่ร้านควินส์ได้รับการยกเลิกแล้ว",
      message:
        'If this is a mistake, please contact us. หากพบข้อผิดพลาด โปรดติดต่อเรา',
    },
  },
  COMPLETED: {
    dbKey: 'completed',
    displayName: { en: 'Completed', th: 'Completed' },
    color: '#20c997',
    emailTxt: {
      subject:
        'Your order has been delivered!/ออร์เดอร์ของท่านได้ถูกจัดส่งแล้ว!',
      header:
        "Your order from Quinn's One & Only has been delivered. ออร์เดอร์ของท่านจาก Quinn's One & Only ได้ถูกจัดส่งแล้ว",
      message: 'Enjoy your meal! ขอให้เป็นมื้อที่อร่อย!',
    },
  },
};

const getStatusConst = (status) => {
  switch (status) {
    case orderStatus.KITCHEN.dbKey:
      return orderStatus.KITCHEN;
    case orderStatus.ORDERED.dbKey:
      return orderStatus.ORDERED;
    case deliveryStatus.DELIVERING.dbKey:
      return deliveryStatus.DELIVERING;
    case pickupStatus.READY.dbKey:
      return pickupStatus.READY;
    case finishStatus.COMPLETED.dbKey:
      return finishStatus.COMPLETED;
    case finishStatus.CANCELED.dbKey:
      return finishStatus.CANCELED;
    default:
      return orderStatus.KITCHEN;
  }
};

const paymentMethod = {
  bank: {
    dbKey: 'bank',
    displayName: { en: 'Bank Transfer', th: 'Bank Transfer' },
  },
  restaurant: {
    dbKey: 'restaurant',
    displayName: { en: 'Pay at restaurant', th: 'Pay at restaurant' },
  },
};

module.exports = {
  orderType,
  getOrderTypeConst,
  orderStatus,
  deliveryStatus,
  pickupStatus,
  finishStatus,
  getStatusConst,
  paymentMethod,
};
