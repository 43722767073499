
import { errorType } from 'common/errors'
import useLanguage from './use-language'


const useErrorHandler = () => {
    const { lang } = useLanguage();
    const getErrorMessage = (error) => {
        if (error && error.type && errorType[error.type]) {
            const msg = errorType[error.type].message
            const displayMsg = msg[lang] ?? msg['en']
            if (displayMsg) return displayMsg
        }
        if (error) {
            return error.message ?? error
        } else {
            return null
        }

    }

    return { getErrorMessage }
}

export default useErrorHandler