import React, { useEffect, useState } from 'react'
import useLanguage from '../../hooks/use-language'
import useErrorHandler from '../../hooks/use-error-handler'

const ErrorMessage = ({ error, size }) => {
    const { lang, txt } = useLanguage()
    var [err, setErr] = useState(error)

    useEffect(() => {
        setErr(error)
    }, [error])

    if (err) {
        err = err.message ?? err
        err = err[lang] ?? err
    }

    if (err && err.includes('duplicate key')) {
        err = 'Error! ' + txt.ERROR_USERNAME_EXISTS
    }

    return <>
        {err &&
            <div className="error-msg__wrapper">
                <p className="error-msg" style={{ ...(size && { fontSize: `${size}rem` }) }} > {err}</p>
            </div >}
    </>

}

export default ErrorMessage
