import React from 'react'
import { useSelector } from 'react-redux'
import useLanguage from '../../hooks/use-language'
import useFormatter from '../../hooks/useFormatter'

import AddressSection from './AddressSection'
import CartSection from './CartSection'
import ConditionalEditField from '../forms/ConditionalEditField'


const OrderInfoSection = ({ setValue, order, step, saveAddress, setSaveAddress }) => {
    const { userInfo } = useSelector(state => state.userLogin)
    const { formatAddress, formatPhone } = useFormatter()
    const { txt } = useLanguage();

    return (
        <CartSection addClass='order-info-section' vertical title={txt.LABEL_ORDER_INFORMATION}>

            <ConditionalEditField
                isEditing={!userInfo && step === 1}
                label='Name'
                onChange={(v) => setValue(v, 'customerName')}
                hideLabelOnEdit
                value={order.customerName} required type='name' />

            {order.orderType === 'delivery' && (step === 1 ?
                <AddressSection
                    currentAddress={order.address}
                    setAddress={(v) => setValue(v, 'address')}
                    saveAddress={saveAddress}
                    setSaveAddress={setSaveAddress} /> :
                <ConditionalEditField
                    isEditing={false}
                    label='Address'
                    onChange={() => { }}
                    value={formatAddress(order.address)}
                    type='text' />
            )}
            <ConditionalEditField
                isEditing={!userInfo && step === 1}
                label='Tel'
                onChange={(v) => setValue(v, 'tel')}
                value={formatPhone(order.tel)}
                hideLabelOnEdit
                required
                type='tel' />
            <ConditionalEditField
                isEditing={!userInfo && step === 1}
                label='Email'
                hideLabelOnEdit
                onChange={(v) => setValue(v, 'email')}
                value={order.email}
                required
                type='email' />
        </CartSection>
    )
}

export default OrderInfoSection