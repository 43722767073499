
import useMediaQuery from "../../hooks/useMediaQuery"
import useLanguage from "../../hooks/use-language"

export const DeleteButton = ({ onClick, shrink }) => {
    const { txt } = useLanguage()
    return <ResponsiveButton type='remove' icon='delete' text={txt.REMOVE} onClick={onClick} shrink={shrink} />
}

export const EditButton = ({ onClick, shrink }) => {
    const { txt } = useLanguage()
    return <ResponsiveButton type='edit' icon='edit' text={txt.BUTTON_EDIT} onClick={onClick} shrink={shrink} />
}

const ResponsiveButton = ({ type, icon, text, onClick, shrink }) => {
    const sp = useMediaQuery()
    return <button className={`ui-btn ${type}${sp && shrink ? ' square' : ''}`} onClick={onClick}>{sp && shrink ? <i className={`icon-${icon}`} /> : text}</button>
}
