import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import AnimateIn from '../../UI/AnimateIn'
import DescText from '../../typography/DescText'

import { IMG_PATH } from '../../../constants/conveniences'

const BarSection = () => {

    const sp = useMediaQuery()
    const { txt } = useLanguage()
    return (
        <section className='bar-section'>
            <img className='bar__img--1' src={IMG_PATH + `restaurant/${sp ? 'bar-mobile.jpg' : 'bar-1.png'}`} alt="" />
            <div className='bar__txt'>
                <AnimateIn offsetAmt={200}>
                    <h2 className='txt-subhead'>Garden Bar</h2>
                </AnimateIn>
                <AnimateIn offsetAmt={300}>
                    <DescText>{txt.DESCRIPTION_BAR}</DescText>
                </AnimateIn>
            </div>
            <div className='bar__img-wpr--2'>
                <img className='bar__img--2 snapshot' src={IMG_PATH + 'restaurant/ctail.jpg'} />
            </div>
        </section>
    )
}

export default BarSection