import { str } from '../../constants/reduxConstants';
import axios from 'axios';
import { handleFail } from '../convenience';

export const getStoreInfo = () => async (dispatch, useState) => {
  try {
    dispatch({ type: str.FETCH_REQUEST });

    const { data } = await axios.get('/api/store/');

    dispatch({ type: str.FETCH_SUCCESS, payload: data });
  } catch (error) {
    handleFail(dispatch, error, str.FETCH_FAIL);
  }
};
