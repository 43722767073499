
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useLanguage from '../../hooks/use-language'

import BlockButton from '../buttons/BlockButton'
import CartSection from './CartSection'
import CartItemsSection from './CartItemsSection'
import OrderTypeRadio from './OrderTypeRadio'
import PaymentRadio from './PaymentRadio'
import OrderInfoSection from './OrderInfoSection'
import useOrder from '../../hooks/useOrder'
import { paymentMethod } from 'common/constants/orderConstants'
import Loader from '../UI/Loader'

import useMediaQuery from '../../hooks/useMediaQuery'


const Cart = () => {
    const [saveAddress, setSaveAddress] = useState(true)
    const { txt } = useLanguage()
    const sp = useMediaQuery()

    const { storeInfo } = useSelector(state => state.storeInfo)
    const { lastOrder } = useSelector(state => state.schedule)
    const { cartItems } = useSelector(state => state.cart)

    const { order, onSubmit, backBtn, step, soldOut, type, setValue, bankSlipUploadHandler, loading } = useOrder(saveAddress)

    const paymentType = paymentMethod[order.paymentType]
    const pickupOnly = cartItems.filter(i => !i.availableForDelivery).length > 0;

    return (<>

        <div className={`cart${step !== 0 ? ' dis' : ''}`}>

            <h1 className='cart__title'>Order</h1>
            {loading ? <Loader loading={loading} /> : <div className='cart__content'>

                <CartItemsSection editing={step === 0} soldOut={soldOut} />

                {storeInfo.open && (!lastOrder || step > 0) ? <>
                    {cartItems.length > 0 && <>

                        <CartSection title={txt.LABEL_ORDER_TYPE}>
                            <OrderTypeRadio type={type} setValue={setValue} step={step} pickupOnly={pickupOnly} />
                        </CartSection>

                        <form onSubmit={onSubmit}>
                            {step > 0 &&
                                <OrderInfoSection
                                    setValue={setValue}
                                    order={order}
                                    step={step}
                                    saveAddress={saveAddress}
                                    setSaveAddress={setSaveAddress}
                                />
                            }

                            {step > 1 &&
                                <CartSection title={txt.LABEL_PAYMENT_METHOD} footnote={txt.MESSAGE_CASH_ONLY}>
                                    <PaymentRadio
                                        step={step}
                                        oType={type}
                                        paymentType={paymentType}
                                        setValue={setValue}
                                        bankSlipUploadHandler={bankSlipUploadHandler}
                                    />
                                </CartSection>
                            }
                            {lastOrder && step > 1 && <div className='cart__closed'>
                                <h2>{txt.MESSAGE_LAST_ORDER_PROD}</h2>
                            </div>}
                            <div className='cart__buttons'>
                                {backBtn}
                                <BlockButton disabled={loading} width={`${sp ? '100%' : '40rem'}`} type='submit' title={step < 2 ? txt.BUTTON_NEXT : txt.BUTTON_ORDER} arrow='right' />
                            </div>

                        </form>
                    </>}
                </>
                    : <><div className='cart__closed btm'>
                        <h2>{!storeInfo.open ? txt.MESSAGE_CLOSED : txt.MESSAGE_LAST_ORDER}</h2>
                    </div>
                        {backBtn}
                    </>
                }
                {cartItems.length < 1 && storeInfo.open && !lastOrder && backBtn}
            </div>}
        </div>
    </>
    )
}

export default Cart



