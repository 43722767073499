import React, { useRef, useEffect, useState } from "react";
import useHasAppeared from "../../../../hooks/use-hasAppeared";
import useFetchRequest from "../../../../hooks/api/useFetchRequest"
import useMediaQuery from "../../../../hooks/useMediaQuery"
import { useDispatch, useSelector } from 'react-redux'

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import EventCarouselSP from "./EventCarouselSP";
import CloseBtn from "../../../UI/CloseButton";

import dir from "common/constants/directoryConstants";
import { ui } from "../../../../constants/reduxConstants";
import { IMG_PATH, UPLOAD_IMG_PATH } from "../../../../constants/conveniences";


const Events = () => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const hasAppeared = useHasAppeared(ref, 300);
    const { data, performFetchRequest, error } = useFetchRequest()
    const { imgShowing: expanded } = useSelector(state => state.ui)
    const [didExpand, setDidExpand] = useState(false)
    const sp = useMediaQuery()
    const [selected, setSelected] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [localImages, setLocalImages] = useState(false)
    const [zoomedImage, setZoomedImage] = useState(null)

    const [images, setImages] = useState([])

    useEffect(() => {
        if (!data && !error) {
            performFetchRequest('', dir.PROMOTIONS)
        } else if (data) {
            setLocalImages(false)
            setImages(data)

        } else if (error) {
            setLocalImages(true)
            setImages(Array.from({ length: 5 }, (_, i) => ({ path: `${IMG_PATH}promotion_placeholder/${i + 1}.jpg` })))
        }

    }, [data, error])


    useEffect(() => {

        if (expanded && selected) {
            setTimeout(() => {

                selected.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }, 1000)

        }

    }, [performFetchRequest, expanded, selected])



    const expand = (e, i) => {
        if (expanded) return;
        setSelected(e.target)
        setSelectedIndex(i)
        setDidExpand(true)
        dispatch({ type: ui.TOGGLE_IMAGE_CAROUSEL })
    }

    const collapse = () => {

        if (!expanded) return;

        setSelected(null)
        dispatch({ type: ui.TOGGLE_IMAGE_CAROUSEL })
    }




    return <div className={`events-wrapper${expanded && !sp ? ' expanded' : ''}`}>
        <div ref={ref} className={`event-stack${expanded && !sp ? ' expanded' : ''}`}>

            <div className={`event-stack__back ${hasAppeared && 'visible'} ${expanded && !sp ? 'expanded' : ''}`} onClick={collapse} >
                {expanded && <CloseBtn state={'showing'} />}
            </div>
            <div className={`event-stack__imgs ${expanded && !sp && 'expanded'}`}>
                {images && images.slice().reverse().map((e, i) => {

                    const trans = 100 * (images.length - 1 - i)
                    const num = i % 4
                    // const revIndex = images.length - (i + 1)
                    const revIndex = i
                    const baseClass = 'event-stack__item';
                    const delay = (revIndex + 1) * 0.3
                    const img = <img className='event-stack__img' onClick={(e) => expand(e, i)} src={`${localImages ? '' : UPLOAD_IMG_PATH}${e.path}`} alt="" />
                    return (
                        <div
                            onClick={() => { if (expanded) setZoomedImage(img) }}
                            key={i}
                            className={`${baseClass} ${baseClass}-${num} ${hasAppeared && 'visible'} ${expanded && !sp && 'expanded'}`}
                            style={{ transitionDelay: `${(expanded || didExpand) ? 0 : delay}s`, visibility: `${hasAppeared ? 'visible' : 'hidden'}`, ...(expanded && !sp && { transform: `translate(${trans}%, -50%)`, left: 0 }) }} >
                            {expanded ? <Zoom >{img}</Zoom> : img}

                        </div>
                    )
                })}
                {/* {zoomedImage && <Zoom onClick={() => { setZoomedImage(null) }}>{zoomedImage}</Zoom>} */}
                {images && sp &&
                    <EventCarouselSP showing={expanded} images={images} local={localImages} selectedIndex={selectedIndex} onClose={collapse} />
                }
            </div>

        </div>
    </div>
}


export default Events;