

const useErrorFormatter = () => {

    const formatError = (error) => {
        var message;
        var type;
        if (error) {
            message = error.replace(/#.*#/, '')
            type = error.match('#(.*)#');
        }

        return { message, type }
    }

    return formatError

}

export default useErrorFormatter;