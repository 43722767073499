import React from 'react';
import NavFooter from "./NavFooter";
import { Link, useLocation } from "react-router-dom";
import ResponsiveImg from "../UI/ResponsiveImg";
import useLanguage from "../../hooks/use-language";
import { useDispatch, useSelector } from "react-redux";
import { toggleLogin } from "../../store/actions/loginScreenActions";
import { logout } from "../../store/actions/userActions";

const NavBar = () => {
    const dispatch = useDispatch();
    const { txt, lang } = useLanguage();
    const { userInfo } = useSelector(state => state.userLogin);
    const location = useLocation();

    const loginHandler = () => {
        if (!userInfo) {
            dispatch(toggleLogin());
        }
    };

    const logoutHandler = () => {
        if (userInfo) {
            dispatch(logout());
        }
    };

    const handleLinkClick = (event, to) => {
        if (location.pathname === to) {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className='nav-bar'>
            <div className='nav-top'>
                <Link to='/?scr=true'>
                    <ResponsiveImg src="logo/quinns_logo" className='nav-top__logo' outerStyle={{ width: '60%' }} />
                </Link>
                <div className='nav-links'>
                    <Link className='nav-links__link' to="/" href="#" onClick={(e) => handleLinkClick(e, '/')}>Home</Link>
                    <Link className='nav-links__link' to="/about/" onClick={(e) => handleLinkClick(e, '/about/')}>About us</Link>
                    <Link className='nav-links__link' to="/restaurant/" href="#" onClick={(e) => handleLinkClick(e, '/restaurant/')}>Our restaurant</Link>
                    <Link className='nav-links__link' to="/menu/" href="#" onClick={(e) => handleLinkClick(e, '/menu/')}>{'Menu & Order'}</Link>
                    <Link className='nav-links__link' to="/?sec=res" href="#" >Reservation</Link>
                    {userInfo ? (
                        <Link
                            className='nav-links__link'
                            to={userInfo ? '/profile/' : '#'}
                        >
                            <span className="material-icons leading-icon">account_circle</span>
                            {userInfo ? 'My page' : txt.LINK_LOGIN_SIGNUP}
                        </Link>
                    ) : (
                        <Link
                            className='nav-links__link'
                            onClick={loginHandler}
                            to={userInfo ? '/profile/' : '#'}
                        >
                            <span className="material-icons leading-icon">account_circle</span>
                            {txt.LINK_LOGIN_SIGNUP}
                        </Link>
                    )}
                    {userInfo && (
                        <Link onClick={logoutHandler} className='nav-links__link' to="/" href="#" >Logout</Link>
                    )}
                </div>
            </div>
            <NavFooter />
        </div>
    );
};

export default NavBar;