import React from 'react'

const CartSection = ({ title, footnote, children, vertical, addClass }) => {
    return (

        <div className={`mini-section${addClass ? ' ' + addClass : ''}`}>

            <div className='mini-section__header'>
                <h2>{title}</h2>

                {footnote && <h2 className='footnote'>*{footnote}</h2>}

            </div>
            <div className={`mini-section__body${vertical ? ' vertical' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default CartSection