

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useLanguage from '../../hooks/use-language'
import useMediaQuery from '../../hooks/useMediaQuery'

import AmountPicker from '../menu/components/AmountPicker'
import { DeleteButton } from '../buttons/ResponsiveButtons'

import { changeItemAmt, removeFromCart } from '../../store/actions/cartActions'
import { editNotes } from '../../store/actions/cartActions'
import { setMenuSubcategory } from 'common/constants/menuConstants'

const CartItem = ({ item, editing, soldOut }) => {

    const { txt, servingTimeMessage } = useLanguage()

    const dispatch = useDispatch()
    const sp = useMediaQuery()
    const { storeInfo } = useSelector(state => state.storeInfo)

    const [price, setPrice] = useState(item.price * item.amount)
    const [timeMessage, setTimeMessage] = useState(null)

    useEffect(() => {

        if (item && item.subcategory) {
            console.log('checking lunch');
            if (item.subcategory === setMenuSubcategory.lunch.dbKey) {
                console.log('is lunch');
                setTimeMessage(servingTimeMessage(storeInfo.lunch_start, storeInfo.lunch_end, true))
            } else if (item.subcategory === setMenuSubcategory.dinner.dbKey) {
                setTimeMessage(servingTimeMessage(storeInfo.dinner_start, storeInfo.dinner_end, true))
            } else {
                console.log(`${item.subcategory}`)
                setTimeMessage(null)
            }
        }
    }, [item])

    const handleAmtChange = (amt) => {

        dispatch(changeItemAmt(item, amt))
        setPrice(item.price * amt)
    }

    const handleRemove = () => {
        dispatch(removeFromCart(item._id))
    }

    const disAmt = <h1 className='disp-amt' style={{ fontSize: '3rem' }}>✖️{item.amount ?? 1}</h1>
    const pickupOnly = <span className='cart-item__pk-only'>({txt.LABEL_PICKUP_ONLY})</span>
    const timeLimit = <span className='cart-item__pk-only'>{timeMessage ? `(${timeMessage})` : ""}</span>
    const removeButton = <DeleteButton onClick={handleRemove} />
    const priceDisplay = <h2 className={`cart-item__price${soldOut ? ' sold-out' : ''}`} style={{ ...(!editing && { textAlign: 'right' }) }}>{price}B</h2>

    return <div className={`cart-item${soldOut ? ' sold-out' : ''}`}>
        <img className={`cart-item__img${soldOut ? ' sold-out' : ''}`} src={item.img} alt="" />
        <div className={`cart-item__body${editing ? '' : ' display'}`}>
            <div className='cart-item__details' style={{ ...(!editing && { width: '100%' }) }}>
                <div className={`cart-item__header${editing ? '' : ' display'}${soldOut ? ' sold-out' : ''}`} >
                    <div className={`cart-item__title${editing ? '' : ' display'}`} >
                        <h2 className={`cart-item__txt en${editing ? '' : ' display'}`}>{item.nameEng}{!item.availableForDelivery && !sp && pickupOnly} {timeLimit}</h2>
                        <h2 className={`cart-item__txt th${editing ? '' : ' display'}`}>{item.nameThai}</h2>
                        {sp && !editing && !item.availableForDelivery && pickupOnly}

                    </div>

                    {sp && <div className='cart-item__btm-sp'>
                        {editing && !item.availableForDelivery && pickupOnly}
                        <div className='cart-item__amt-sp'>
                            {!editing && disAmt}
                            {priceDisplay}
                        </div>
                    </div>}

                </div>
                {soldOut ? <h2 className="cart-item__so-txt">{txt.LABEL_SOLD_OUT}</h2> : editing ? <textarea value={item.notes} onChange={(e) => dispatch(editNotes(item, e.target.value))} className='cart-item__notes' name="notes" placeholder='NOTES' id="notes"></textarea> :
                    item.notes && item.notes !== '' && <p className='cart-item__notes' >{item.notes && `NOTE: ${item.notes}`}</p>
                }
            </div>
            {
                (editing || !sp) && <div className='cart-item__total'>

                    <div className={`cart-item__amt${soldOut ? ' sold-out' : ''}`} style={{ ...(!editing && { textAlign: 'right' }) }}>
                        {editing ? <AmountPicker item={item} btnSize={sp ? 6 : 3} onSet={handleAmtChange} disabled={soldOut} /> : !sp && disAmt}

                    </div>
                    {editing && sp && removeButton}
                    {!sp && priceDisplay}
                </div>
            }

            {editing && !sp && removeButton}
        </div >
    </div >

}

export default CartItem;