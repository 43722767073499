

import useMediaQuery from "../../../hooks/useMediaQuery";
import Carousel from "../../UI/Carousel";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper';
import Loader from "../../UI/Loader";
import { IMG_PATH } from "../../../constants/conveniences";
import 'swiper/swiper-bundle.css';



const Hero = () => {
    const sp = useMediaQuery()
    const images = ['pc_top_img1.jpg', 'pc_top_img2.jpg', 'pc_top_img3.jpg', 'pc_top_img4.jpg', 'pc_top_img5.jpg']

    const spImages = [...Array(5)].map((_, i) => `${IMG_PATH}carousel/${sp ? 'sp' : 'pc'}_top_img${i + 1}.jpg`)

    return <section className='section-hero'>

        {sp ? (<><Swiper
            modules={[Pagination, Navigation, Autoplay]}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,

            }}
            pagination={{
                type: 'bullets',
                clickable: true,
            }}
            loop={true}
            spaceBetween={0}
            slidesPerView={1}
        >
            {spImages.map((src, i) => {
                return <SwiperSlide key={i} className='sp-carousel__slide'>
                    <img src={src} alt="" />
                </SwiperSlide>
            })}

        </Swiper><Loader loading={true} /></>) : <Carousel images={images} />}
    </section>
}
export default Hero;