
import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language';

import ParallaxImage from "../../UI/ParallaxImage";
import TeaserText from '../../typography/TeaserText';


const LOREM = 'Lorem ipsum dolor sit. Soluta tempora a perspiciatis dolores eos distinctio illum? Numquam voluptatem vitae laboriosam officia voluptates odit molestiae?';

const About = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return <div className='section-about'>

        <div className='about-image-wrapper'>
            <ParallaxImage bkgAttachment='local' multiplier={0.03} bkgSize={sp ? '130%' : '100%'} rightOffset={35} bottomOffset={sp ? 25 : 35} imgName='home/spices.jpg' />
        </div>
        <div className="about-content">
            <TeaserText title='About Us' text={txt.DESCRIPTION_ABOUT_US} right={true} path='about/' />
        </div>
    </div>
}

export default About;