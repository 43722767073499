import React, { useEffect, useRef } from "react"
import ReactDOM from 'react-dom';
import useMousePosition from '../../hooks/useMousePosition'
import classes from './Portal.module.css'

export const Portal = (props) => {
    const el = useRef(document.createElement('div'));
    useEffect(() => {
        const portal = document.getElementById('portal');
        portal.appendChild(el.current);

        return () => {
            portal.removeChild(el.current);
        };

    }, [props.children]);

    return ReactDOM.createPortal(props.children, el.current);
}

export const PortalDiv = ({ children }) => <div id='portal'>{children}</div>;

export const CenterPortal = ({ children }) => <div id='portal' className={`${classes.centered}`}>{children}</div>


export const MouseCatcher = ({ children }) => {
    const position = useMousePosition();

    return (
        <div id='portal' className={`${classes.chaser}`} style={{ left: `${position.x}px`, top: `${position.y}px` }}>{children}</div>
    )
}

