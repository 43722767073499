import React from 'react'
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react'

const useDetectTop = (ref, topOffset) => {

    const [offset, setOffset] = useState(null);
    const [top, setTop] = useState(false)
    const [scrolledOffset, setScrolledOffset] = useState(0)

    const scrollHandler = useCallback(() => {

        if (!ref.current) return

        setTop(window.scrollY > offset - topOffset)
        setScrolledOffset(window.scrollY - (offset - topOffset))

        // console.log(`scroll = ${window.scrollY} offset =${scrolledOffset}`)


    })

    useEffect(() => {

        if (ref.current) {
            if (offset === null) {

                setOffset(ref.current.offsetTop)
            }

            window.addEventListener('scroll', scrollHandler)
        }

        return () => window.removeEventListener('scroll', scrollHandler)
    }, [ref.current, scrollHandler, offset])

    const resetOffset = () => {
        setOffset(ref.current.offsetTop)
    }

    const setTopManually = (top = true) => {
        setTop(true)
    }

    const resetInitialOffset = (val) => {

        setOffset(val ?? ref.current.offsetTop)
    }

    return { top, scrolledOffset, resetOffset, setTopManually, resetInitialOffset };
}

export default useDetectTop