import { ClipLoader } from 'react-spinners'

import React from 'react';



const Loader = ({ loading, size = 50, marginTop = '8rem', height = '100%', width = '100%', color = 'white', centerOver }) => {
    return <div style={{ position: centerOver ? 'absolute' : 'relative', height: height, width: width, textAlign: 'center', marginTop: marginTop }}>

        <ClipLoader color={color} loading={loading} size={size} />


    </div>;
};

export default Loader;
