import { lgn } from '../../constants/reduxConstants';

export const loginScreenReducer = (
  state = { showing: false, showGuest: false, onLogin: () => {} },
  action
) => {
  switch (action.type) {
    case lgn.TOGGLE:
      const showing = state.showing;
      return {
        ...action.payload,
        showing: !showing,
      };
    default:
      return state;
  }
};
