import { useSelector } from 'react-redux'
import useLanguage from '../../../hooks/use-language'

import { storeConsts as str } from '../../../constants/storeInfoConstants'
import { MAP_HTML } from '../../../constants/conveniences'

const Location = () => {
    const { lang } = useLanguage()
    const { storeInfo } = useSelector(state => state.storeInfo)


    return <div className="section-location">
        {storeInfo && <>
            <h1 className='location-title'>location</h1>
            <img className='location-img' src={process.env.PUBLIC_URL + '/assets/images/home/location-img-cut.jpg'} alt="" />
            <div className='location-text'>
                <p className='location-text__address'>{storeInfo[lang === 'en' ? str.address_eng.dbKey : str.address_thai.dbKey]}</p>
                <p className='location-text__catch'>*{storeInfo[lang === 'en' ? str.directions_eng.dbKey : str.directions_thai.dbKey]}</p>
            </div>
            <div className='map-wrapper' dangerouslySetInnerHTML={MAP_HTML}></div>
        </>}
    </div>
}

export default Location;