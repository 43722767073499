// import useTime from "./useTime"
// import moment from "moment"
// import { useState } from "react"
// import { useEffect } from "react"
// import { useDispatch, useSelector } from "react-redux"
// import { schd } from "../constants/reduxConstants"



// export const useScheduleInfo = () => {

//     const { storeInfo } = useSelector(state => state.storeInfo)

//     const [scheduleInfo, setScheduleInfo] = useState({
//         lastOrderTime: null,
//         lunchStart: null,
//         lunchEnd: null,
//         dinnerStart: null,
//         dinnerEnd: null
//     });


//     useEffect(() => {
//         if (storeInfo) {
//             setScheduleInfo({
//                 lastOrderTime: storeInfo['last_order_site'] ? moment(storeInfo['last_order_site'], 'HH:mm') : null,
//                 lunchStart: storeInfo['lunch_start'] ? moment(storeInfo['lunch_start'], 'HH:mm') : null,
//                 lunchEnd: storeInfo['lunch_end'] ? moment(storeInfo['lunch_end'], 'HH:mm') : null,
//                 dinnerStart: storeInfo['dinner_start'] ? moment(storeInfo['dinner_start'], 'HH:mm') : null,
//                 dinnerEnd: storeInfo['dinner_end'] ? moment(storeInfo['dinner_end'], 'HH:mm') : null
//             });
//         }
//     }, [storeInfo])


//     const checkIsLunch = (time) => {
//         return scheduleInfo.lunchStart && scheduleInfo.lunchEnd ? time >= scheduleInfo.lunchStart.format("HH:mm") && time <= scheduleInfo.lunchEnd.format("HH:mm") : false
//     }

//     const checkIsDinner = (time) => {
//         return scheduleInfo.dinnerStart && scheduleInfo.dinnerEnd ? time >= scheduleInfo.dinnerStart.format("HH:mm") && time <= scheduleInfo.dinnerEnd.format("HH:mm") : false
//     }


//     return { ...scheduleInfo, checkIsLunch, checkIsDinner }

// }

// const useSchedule = () => {

//     const dispatch = useDispatch()
//     const { time } = useTime(10000);

//     const [lastOrder, setLastOrder] = useState(false)
//     const [isLunch, setIsLunch] = useState(false)
//     const [isDinner, setIsDinner] = useState(false)
//     const {
//         lastOrderTime,
//         lunchStart,
//         lunchEnd,
//         dinnerStart,
//         dinnerEnd,
//         checkIsDinner,
//         checkIsLunch
//     } = useScheduleInfo()




//     useEffect(() => {
//         if (time) {

//             const currentTime = time.format("HH:mm");
//             setLastOrder(lastOrderTime ? currentTime >= lastOrderTime.format("HH:mm") : false);
//             setIsLunch(checkIsLunch(currentTime));
//             setIsDinner(checkIsDinner(currentTime));
//         }
//     }, [time, lastOrderTime, lunchStart, lunchEnd, dinnerStart, dinnerEnd]);


//     useEffect(() => {
//         dispatch({ type: schd.SET_SCHEDULE, payload: { isLunch, isDinner, lastOrder } })
//     }, [isLunch, isDinner, lastOrder])

// }

// export default useSchedule

import useTime from "./useTime"
import moment from "moment"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { schd } from "../constants/reduxConstants"

export const useScheduleInfo = () => {
    const { storeInfo } = useSelector(state => state.storeInfo)

    const [scheduleInfo, setScheduleInfo] = useState({
        lastOrderTime: null,
        lunchStart: null,
        lunchEnd: null,
        dinnerStart: null,
        dinnerEnd: null,
        openTime: null
    });

    useEffect(() => {
        if (storeInfo) {
            setScheduleInfo({
                lastOrderTime: storeInfo['last_order_site'] ? moment(storeInfo['last_order_site'], 'HH:mm') : null,
                lunchStart: storeInfo['lunch_start'] ? moment(storeInfo['lunch_start'], 'HH:mm') : null,
                lunchEnd: storeInfo['lunch_end'] ? moment(storeInfo['lunch_end'], 'HH:mm') : null,
                dinnerStart: storeInfo['dinner_start'] ? moment(storeInfo['dinner_start'], 'HH:mm') : null,
                dinnerEnd: storeInfo['dinner_end'] ? moment(storeInfo['dinner_end'], 'HH:mm') : null,
                openTime: storeInfo['open_time'] ? moment(storeInfo['open_time'], 'HH:mm') : null
            });
        }
    }, [storeInfo]);

    const checkIsLunch = (time) => {
        return scheduleInfo.lunchStart && scheduleInfo.lunchEnd
            ? time >= scheduleInfo.lunchStart.format("HH:mm") && time <= scheduleInfo.lunchEnd.format("HH:mm")
            : false;
    }

    const checkIsDinner = (time) => {
        return scheduleInfo.dinnerStart && scheduleInfo.dinnerEnd
            ? time >= scheduleInfo.dinnerStart.format("HH:mm") && time <= scheduleInfo.dinnerEnd.format("HH:mm")
            : false;
    }

    const checkIsLastOrder = (time) => {
        if (!scheduleInfo.lastOrderTime || !scheduleInfo.openTime) return false;

        const lastOrderMoment = moment(scheduleInfo.lastOrderTime, 'HH:mm');
        const openMoment = moment(scheduleInfo.openTime, 'HH:mm');
        const currentMoment = moment(time, 'HH:mm');

        if (lastOrderMoment.isAfter(openMoment)) {
            return currentMoment.isSameOrAfter(lastOrderMoment) || currentMoment.isBefore(openMoment);
        } else {
            return currentMoment.isBetween(lastOrderMoment, openMoment, null, '[)');

        }
    }

    return { ...scheduleInfo, checkIsLunch, checkIsDinner, checkIsLastOrder }
}

const useSchedule = () => {
    const dispatch = useDispatch();
    const { time } = useTime(10000);

    const [lastOrder, setLastOrder] = useState(false);
    const [isLunch, setIsLunch] = useState(false);
    const [isDinner, setIsDinner] = useState(false);
    const {
        lastOrderTime,
        lunchStart,
        lunchEnd,
        dinnerStart,
        dinnerEnd,
        checkIsDinner,
        checkIsLunch,
        checkIsLastOrder
    } = useScheduleInfo();

    useEffect(() => {
        if (time) {
            const currentTime = time.format("HH:mm");
            setLastOrder(checkIsLastOrder(currentTime));
            setIsLunch(checkIsLunch(currentTime));
            setIsDinner(checkIsDinner(currentTime));
        }
    }, [time, lastOrderTime, lunchStart, lunchEnd, dinnerStart, dinnerEnd]);

    useEffect(() => {
        dispatch({ type: schd.SET_SCHEDULE, payload: { isLunch, isDinner, lastOrder } });
    }, [isLunch, isDinner, lastOrder, dispatch]);
}

export default useSchedule;