import useContainerDimensions from "../../hooks/use-dimensions";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const MenuGridItem = ({ category }) => {
    const ref = useRef(null)
    const dimensions = useContainerDimensions(ref);
    const navigate = useNavigate();


    const clickHandler = () => {
        navigate(`/menu/${category.dbKey}?col=true`)
    }

    return <div ref={ref} onClick={clickHandler} className='menu-grid-item' style={{ height: `${dimensions.width}px` }}>

        <img className='menu-grid-item__img' src={process.env.PUBLIC_URL + 'assets/images/menu/' + category.dbKey + '.jpg'} alt={category.displayName} />
        <div className='menu-grid-item__overlay'>
            <h1 className='menu-grid-item__txt'>{category.displayName}</h1>
        </div>
    </div>
}

export default MenuGridItem;