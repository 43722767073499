import React, { useState } from 'react';
import { useEffect } from 'react';


const SubcategorySelector = ({ subcategories, onSelect, selected }) => {

    const [transition, setTransition] = useState('none')

    useEffect(() => {

        setTransition('none')


    }, [subcategories])

    const selectHandler = (subcategory) => {
        setTransition('all .5s')
        onSelect(subcategory)
    }
    return (<div className='menu-body-header__btn-row'>
        {subcategories.map(s => {
            return <button style={{ transition: transition }} onClick={() => selectHandler(s)} className={`menu-body-header__btn${selected === s ? ' selected' : ''}`} key={s.displayName} disabled={selected === s}>{s.displayName}</button>
        })}
    </div>)
}

export default SubcategorySelector;