import React, { useCallback, useEffect, useState } from 'react'

/**
 * Custom hook for converting REM units to pixels and vice versa based on the current base font size.
 * This hook listens to window resize events to adjust the base font size dynamically.
 *
 * @returns {object} Object containing the base font size, and functions to convert values between pixels and REM.
 */
const useREMCalculator = () => {
    /**
    * Retrieves the base font size from the root HTML element.
    * @returns {number} The current base font size of the document.
    */
    const getBaseFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize)
    const [baseFontSize, setBaseFontSize] = useState(getBaseFontSize())



    useEffect(() => {
        const handleResize = () => setBaseFontSize(getBaseFontSize())

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    /**
     * Converts REM units to pixels.
     * @param {number} rem The number of REM units to convert.
     * @returns {number} The equivalent pixel value.
     */
    const calculatePixels = useCallback((rem) => rem * baseFontSize, [baseFontSize])

    /**
     * Converts pixels to REM units.
     * @param {number} pixels The number of pixels to convert.
     * @returns {number} The equivalent REM value.
     */
    const calculateREM = useCallback((pixels) => pixels / baseFontSize, [baseFontSize])

    return { baseFontSize, calculatePixels, calculateREM }
}

export default useREMCalculator
