import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import LanguageSelector from './LanguageSelector'
import { Link, useLocation } from 'react-router-dom'
import { ui } from '../../constants/reduxConstants'
import { Transition } from 'react-transition-group'
import smoothscroll from 'smoothscroll-polyfill';



// Initialize polyfill
smoothscroll.polyfill();
const NavMenuMobile = ({ showing }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const closeMenu = () => {
        dispatch({ type: ui.CLOSE_HMENU })
    }

    const handleLinkClick = (event, to) => {
        console.log(`clicking link`);
        if (location.pathname === to) {
            event.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        closeMenu()
    };


    return (
        <Transition in={showing} timeout={200}>
            {(state) => {

                const exit = state === 'exiting' || state === 'exited'
                const enter = state === 'entering' || state === 'entered'
                return (<>
                    <div className='m-nav-menu__bkg' onClick={closeMenu} style={{ ...(state === 'exited' && { visibility: 'hidden' }) }}></div>
                    <div className='m-nav-menu' style={{ ...(state === 'exited' && { visibility: 'hidden' }), opacity: exit ? 0 : 1, transform: `translateY(${enter ? '0' : '-100%'})` }}>
                        <Link className='m-nav-menu__link' to="/" onClick={(e) => handleLinkClick(e, '/')}>Home</Link>
                        <Link className='m-nav-menu__link' to="about/" onClick={(e) => handleLinkClick(e, '/about/')}>About us</Link>
                        <Link className='m-nav-menu__link' to="restaurant/" href="#" onClick={(e) => handleLinkClick(e, '/restaurant/')}>Our restaurant</Link>
                        <Link className='m-nav-menu__link' to="menu/" href="#" onClick={(e) => handleLinkClick(e, '/menu/')}>{'Menu & Order'}</Link>
                        <Link className='m-nav-menu__link' to="/?sec=res" href="#" onClick={closeMenu}>Reservation</Link>
                        <div className='m-nav-menu__link'>
                            <LanguageSelector />
                        </div>
                    </div>
                </>)
            }

            }

        </Transition>
    )
}

export default NavMenuMobile