import React from 'react'
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll'
import { Transition } from 'react-transition-group'
import CloseBtn from './CloseButton'


const ModalContainer = ({ showing, onClose, onClosed = () => { }, children, height = 'auto', width = 'auto', addClass, animationDuration = 300, barItems }) => {
    useDisableBodyScroll(showing)
    return (
        <Transition unmountOnExit mountOnEnter in={showing} timeout={{ enter: animationDuration, exit: animationDuration }}>
            {(state) => {

                if (state === 'exiting') {
                    setTimeout(() => {
                        onClosed()
                    }, animationDuration)

                }

                return <div className={`modal-container ${state}${addClass ? ` ${addClass}` : ''}`} >

                    <div className='modal-bkg' onClick={onClose} />
                    <div className={`modal-card`} style={{ height: height, width: width, ...(!onClose && { paddingTop: 0 }) }}>
                        {onClose && <CloseBtn onClick={onClose} state={state} />}
                        {barItems && <div className='modal-card__bar-items'>
                            {barItems}
                        </div>}
                        {children}

                    </div>
                </div>

            }}
        </Transition>
    )
}

export default ModalContainer