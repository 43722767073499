import { useEffect, useRef, useState } from 'react';
import useFetchRequest from '../../../hooks/api/useFetchRequest';
import useLanguage from '../../../hooks/use-language';

import AddToCart from './AddToCart';
import DietaryIcon from './DietaryIcon';
import { Portal } from '../../UI/Portal';
import Loader from '../../UI/Loader';
import ScrollableDiv from '../../util/ScrollableDiv'
import { ReactFitty } from "react-fitty";
import useREMCalculator from '../../../hooks/useREMCalculator';
import { menuCategories, dietaryOption } from 'common/constants/menuConstants';
import dir from '../../../constants/directoryConstants';
import SafeImg from '../../UI/SafeImg';

import { IMG_PATH, UPLOAD_IMG_PATH } from '../../../constants/conveniences'
import useMediaQuery from '../../../hooks/useMediaQuery'





const MenuItemStats = ({ item }) => {


    const imgURL = process.env.PUBLIC_URL + '/assets/images/menu/'
    return <div className='menu-card__stats'>
        {(item.veggie || item.vegan) && <DietaryIcon dietaryOption={item.vegan ? dietaryOption.vegan : dietaryOption.veggie} className='menu-card__symbol' />}
        {item.seafood && <DietaryIcon dietaryOption={dietaryOption.seafood} className='menu-card__symbol' />}
        {item.meat && <DietaryIcon dietaryOption={dietaryOption.meat} className='menu-card__symbol' />}
        <div className='menu-card__spicy'>
            {Array.from({ length: Number(item.spiciness) }).map(i =>
                <img key={Math.random()} src={imgURL + 'chili.png'} alt="" />
            )}

        </div>
    </div>
}

const MenuItemDescription = ({ id }) => {

    const { loading, error, data: item, performFetchRequest } = useFetchRequest()
    useEffect(() => {
        performFetchRequest(id, dir.MENU_ITEMS)
    }, [id])


    return <div className='menu-desc-helper'  >
        {loading ? <Loader /> : item && <MenuItemCard mini item={item} />}

    </div>
}


const MenuItemCard = ({ item, mini, noDescription }) => {
    const titleContainerRef = useRef(null)
    const { calculatePixels } = useREMCalculator()
    const sp = useMediaQuery();
    const isSet = item.category === menuCategories.set_menu.dbKey
    const notDrink = item.category !== menuCategories.drinks.dbKey
    const isFood = !isSet && notDrink
    const [hover, setHover] = useState(null)
    const { lang } = useLanguage()



    return <>
        {hover && <Portal>
            <MenuItemDescription id={hover} />
        </Portal>}
        <div className={`menu-card${mini ? ' mini' : ''}${noDescription ? ' no-desc' : ''}${isFood ? ' food' : ''}${isSet ? ' set' : ''}`}>
            <div className={`menu-card__head${isFood ? ' food' : ''}${isSet ? ' set' : ''}`}>
                <div style={{ position: 'relative' }}>
                    <SafeImg placeholder={IMG_PATH + `menu/menu_placeholder${isSet ? '_set' : ''}.jpg`} src={UPLOAD_IMG_PATH + item.img} alt={item.nameEng} className={`menu-card__img${isFood ? ' food' : ''}`} />
                    {!item.availableForDelivery && <img className='menu-card__pk-icn' src={IMG_PATH + `/menu/${item.dineinOnly ? 'dinein' : 'pickup'}_icon.png`} />}
                </div>
                <div ref={titleContainerRef} className={`menu-card__title-section${isFood ? ' food' : ''}${isSet ? ' set' : ''}`}>
                    <div className='menu-card__title-txt'>
                        <h3 className='menu-card__title--eng'>{(sp && notDrink) ? item.nameEng : <ReactFitty maxSize={calculatePixels(sp ? 3.5 : 2.6)}>{item.nameEng}</ReactFitty>}</h3>
                        <h3 className='menu-card__title--thai'>{sp ? item.nameThai : <ReactFitty maxSize={calculatePixels(sp ? 2.8 : 2)}>{item.nameThai}</ReactFitty>}</h3>
                    </div>
                    <div>
                        {!mini && <div className='menu-card__detail-row'>

                            {isSet ? <div></div> : <MenuItemStats item={item} />}
                            <span className='menu-card__price'>{item.price}B</span>

                        </div>}
                        {!noDescription && sp && <div className='menu-card__divider'></div>}
                    </div>
                </div>
            </div>


            <div className={`menu-card__body${isSet ? ' set' : ''}${mini ? ' mini' : ''}${noDescription ? ' no-desc' : ''}`}>
                <ScrollableDiv className={`menu-card__txt-section${mini ? ' mini' : ''}`}>

                    {isSet && <ul>{item.items.map(i =>
                        <li key={i._id} onMouseOver={() => setHover(i._itemID)} onMouseOut={() => setHover(null)} className='menu-card__set-item' style={{ cursor: 'default' }}>
                            <span className='menu-card__set-item-txt trigger'>・{`${lang === 'th' ? i.nameThai : i.nameEng}${i.amt > 1 ? ` (x${i.amt})` : ''}`}</span>

                            <span><MenuItemStats item={i} /></span>
                        </li>
                    )}
                    </ul>}
                    {!noDescription && <div className={`menu-card__desc${mini ? ' mini' : ''}`}>
                        <p className='menu-card__desc--eng'>{item.descriptionEng}</p>
                        <p className='menu-card__desc--thai'>{item.descriptionThai}</p>
                    </div>}
                </ScrollableDiv>
                {!item.availableOnline && !sp && <div className='menu-card__no-online'> <p>Not available online</p></div>}
                {(!mini && (sp || item.availableOnline)) && <AddToCart item={item} drink={!notDrink} sp={sp} />}

            </div>
        </div>
    </>

}

export default MenuItemCard;