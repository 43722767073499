
import useMediaQuery from "../../hooks/useMediaQuery";
import AnimateIn from "../UI/AnimateIn";
import useLanguage from "../../hooks/use-language";
import { Link } from "react-router-dom";



const TeaserText = props => {

    const sp = useMediaQuery()
    const { lang } = useLanguage()

    const right = props.right ?? false
    const align = props.right ? { marginLeft: sp ? '1.5rem' : '15rem' } : { marginRight: sp ? 0 : '15rem' };
    const anDirect = right ? 'left' : 'right';

    return <div className='teaser-text'>
        <AnimateIn direction={anDirect} offset={0}>
            <h1 className='txt-header teaser-text__title'>{props.title}</h1>
        </AnimateIn>

        <div className={`teaser-text__text`} style={align}>
            <AnimateIn direction={anDirect}>
                <p className={`teaser-text__text ${lang}`}>{props.text}</p>
            </AnimateIn>
            <AnimateIn direction={anDirect}>
                <Link to={props.path}><img className='teaser-text__more' src={process.env.PUBLIC_URL + '/assets/images/components/more.png'} alt="" /></Link>
            </AnimateIn>
        </div>

    </div>
}

export default TeaserText;