import MenuGridItem from "../menu/MenuGridItem";
import useContainerDimensions from "../../hooks/use-dimensions";
import { useRef } from "react";
import { allmenuCategories } from 'common/constants/menuConstants'
import AnimateIn from '../UI/AnimateIn'
import useMediaQuery from "../../hooks/useMediaQuery";

const MenuGrid = () => {
    const ref = useRef();
    const dimensions = useContainerDimensions(ref);
    const sp = useMediaQuery();
    return <div className='section-menu'>
        <div className='menu-top-grid'>
            <div >
                <AnimateIn direction={sp ? 'up' : 'right'} offsetAmt={100}>
                    <div ref={ref} className='menu-grid-item' style={{ height: `${dimensions.width}px` }}>
                        <h1 className='menu-grid-title'>{'Menu & Order'}</h1>
                    </div>
                </AnimateIn>
            </div>

            {allmenuCategories.map((c, i) => {
                const even = i % 2 === 0
                const dir = sp ? (even ? 'left' : 'right') : 'up'
                const del = sp ? even ? 250 : 0 : i > 2 ? (i - 3) * 250 : i * 250
                return <AnimateIn key={c.dbKey} direction={'up'} offsetAmt={100} delay={del}>
                    <div ><MenuGridItem category={c} /></div>
                </AnimateIn>
            }
            )}

        </div>

    </div>


}

export default MenuGrid;