import React from 'react'
import useLanguage from '../../hooks/use-language'

const DescText = ({ className, children }) => {
    const { lang } = useLanguage()
    return (
        <p className={`txt-desc ${lang}${className ? ' ' + className : ''}`}>{children}</p>
    )
}

export default DescText