import React from 'react'
import { useDispatch } from 'react-redux';
import useLanguage from '../../hooks/use-language';
import { setLanguage } from '../../store/actions/uiActions';


const LanguageSelector = ({ align = 'left' }) => {

    const dispatch = useDispatch();
    const { lang } = useLanguage();

    const setLanguageHandler = lang => {
        dispatch(setLanguage(lang));
    }
    return (
        <div className='language-selector' style={{ textAlign: align }}>
            <span className="material-icons leading-icon language-selector__icon ">language</span>
            <span onClick={(e) => setLanguageHandler('th')} disabled={lang === 'th'} className={`language-selector__lang${lang === 'th' ? ' disabled' : ''}`}>TH</span>
            <span>{' | '}</span>
            <span onClick={(e) => setLanguageHandler('en')} disabled={lang === 'en'} className={`language-selector__lang${lang === 'en' ? ' disabled' : ''}`}>EN</span>
        </div>
    )
}

export default LanguageSelector