import { useEffect } from "react";
import useBasePathComponent from "../../hooks/useBasePathComponent";
import { useSearchParams } from "react-router-dom";

const ScrollToTop = () => {

    const pathName = useBasePathComponent()
    const [params, setParams] = useSearchParams();



    useEffect(() => {

        if (!params.get('col')) {
            window.scrollTo(0, 0)

        }


    }, [pathName]);

    return null
}

export default ScrollToTop;