

import { useRef, } from "react";

import classes from "./ParallaxImage.module.css";
import useParallax from "../../hooks/use-parallax";


const ParallaxImage = props => {
    const innerRef = useRef(null);

    const roundToNearestHalf = (num) => Math.round(num * 2) / 2;
    const bkPosition = useParallax(innerRef, props.bottomOffset ?? 50, props.multiplier ?? 0.01, props.limit ?? null)

    const rightOffset = `${props.rightOffset ?? 0}%`;
    const bottomOffset = `${props.round ? roundToNearestHalf(bkPosition) : bkPosition}%`;




    return <div className={classes.parallax} style={{ backgroundImage: `url('../assets/images/${props.imgName}')`, backgroundAttachment: `${props.bkgAttachment ?? 'fixed'}`, backgroundSize: props.bkgSize ?? 'cover', backgroundPositionY: bottomOffset, backgroundPositionX: rightOffset }} ref={innerRef}></div>


}

export default ParallaxImage;


