import useMediaQuery from "../../hooks/useMediaQuery";
import useTogglePrivacyPolicy from "../../hooks/useTogglePrivacyPolicy";
import NavFooter from "../Navigation/NavFooter";
import { ui } from "../../constants/reduxConstants";


const Footer = () => {

    const sp = useMediaQuery()
    const togglePP = useTogglePrivacyPolicy()

    return <footer className="section-footer">
        {sp ? <NavFooter /> :

            <p>{`All Rights Reserved © ${new Date().getFullYear()}  `}<a href="#" onClick={togglePP}>Privacy Policy</a></p>

        }
    </footer>
}

export default Footer;