import axios from 'axios';
import { usr } from '../../constants/reduxConstants';
import { setConfig, handleFail } from '../convenience';

export const userLogin = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: usr.LOGIN_REQUEST });

    const config = setConfig(true);

    const { data } = await axios.post(
      '/api/customers/login',
      { username, password },
      config
    );
    dispatch({ type: usr.LOGIN_SUCCESS, payload: data });
  } catch (error) {
    handleFail(dispatch, error, usr.LOGIN_FAIL);
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: usr.LOGOUT });

  localStorage.removeItem('userInfo');
};

export const getUserDetails = (id) => async (dispatch, useState) => {
  try {
    dispatch({ type: usr.DETAILS_REQUEST });

    const config = setConfig(true, useState);

    const { data } = await axios.get(`/api/admin/users/${id}`, config);
    dispatch({ type: usr.DETAILS_SUCCESS, payload: data });
  } catch (error) {
    handleFail(dispatch, error, usr.DETAILS_FAIL);
  }
};

export const updateUser = (updatedData) => async (dispatch, useState) => {
  try {
    dispatch({ type: usr.UPDATE_REQUEST });

    const config = setConfig(true, useState);

    const { data } = await axios.put(
      `/api/customers/profile`,
      updatedData,
      config
    );
    dispatch({ type: usr.UPDATE_SUCCESS });
    dispatch({ type: usr.LOGIN_SUCCESS, payload: data });
  } catch (error) {
    handleFail(dispatch, error, usr.UPDATE_FAIL);
  }
};

export const updateUserLocal = (updatedData) => async (dispatch, useState) => {
  dispatch({ type: usr.UPDATE_LOCAL, payload: updatedData });
};

export const deleteUser = (id) => async (dispatch, useState) => {
  try {
    dispatch({ type: usr.DELETE_REQUEST });

    const config = setConfig(false, useState);

    await axios.delete(`/api/customers/${id}`, config);
    dispatch({ type: usr.DELETE_SUCCESS });
  } catch (error) {
    handleFail(dispatch, error, usr.DELETE_FAIL);
  }
};

export const registerUser = (user) => async (dispatch, useState) => {
  try {
    dispatch({ type: usr.REGISTER_REQUEST });

    const config = setConfig(true, useState);

    const { data } = await axios.post(`/api/customers`, user, config);
    dispatch({ type: usr.REGISTER_SUCCESS });
    // dispatch({ type: usr.DETAILS_SUCCESS, payload: data });
  } catch (error) {
    handleFail(dispatch, error, usr.REGISTER_FAIL);
  }
};
