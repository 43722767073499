import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'

import ReservationForm from '../../forms/ReservationForm'
import ModalContainer from '../../UI/ModalContainer'

const ReserveModal = ({ showing, onClose }) => {

    const sp = useMediaQuery()

    return (
        <ModalContainer showing={showing} onClose={onClose} addClass={'reservation'}>
            <div className={`reservation-modal${sp ? '' : ' pc'}`}>
                <ReservationForm />
            </div>
        </ModalContainer>

    )
}

export default ReserveModal