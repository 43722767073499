import { useState, useEffect } from "react";
import useOnScreen from "./use-on-screen";

const useScrollObserver = (ref, log) => {
    const isVisible = useOnScreen(ref, log);
    const [windowScroll, setWindowScroll] = useState(window.scrollY);
    const [startingPosition, setStartingPosition] = useState(null);

    useEffect(() => {

        const scrollHandler = () => {
            setWindowScroll(window.scrollY);
        }

        if (isVisible) {
            if (log) { console.log('its visible') }
            setStartingPosition(window.scrollY);

            window.addEventListener('scroll', scrollHandler)


        } else {
            if (log) { console.log('setting start null') }
            setStartingPosition(null);
            setWindowScroll(window.scrollY);
            window.removeEventListener('scroll', scrollHandler)
        }

        return () => { window.removeEventListener('scroll', scrollHandler) }

    }, [isVisible])

    return {
        windowScroll,
        startingPosition,
        isVisible
    }


}

export default useScrollObserver;