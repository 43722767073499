import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import AnimateIn from '../../UI/AnimateIn'
import DescText from '../../typography/DescText'

import { IMG_PATH } from '../../../constants/conveniences'


const Collage = () => {
    const imgPath = IMG_PATH + 'restaurant/'
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    const title = (<AnimateIn direction='right' offsetAmt={200}>
        <h2 className='txt-subhead'>Indoor Dining</h2>
    </AnimateIn>)
    return (
        <section className='collage'>
            {sp && title}
            <img className='collage__img collage__img--1' src={imgPath + 'indoor-2.png'} alt="" />
            <div className=' collage__img collage__img--2'>
                {!sp && title}
                <img src={imgPath + 'indoor-1.png'} alt="" />
            </div>
            <div className="collage__btm-wrapper">
                <div className='collage__img collage__img--3'>
                    <img src={imgPath + 'indoor-3.png'} alt="" />
                </div>
                <div className='txt-desc collage__txt'>
                    <AnimateIn direction='left' offsetAmt={200}>
                        <DescText >{txt.DESCRIPTION_INDOOR_DINING}</DescText>
                    </AnimateIn>

                </div>
            </div>


        </section>
    )
}

export default Collage