import { useEffect } from 'react'
import { useState } from 'react'
import useLanguage from './use-language'


const usePasswordValidator = (single, minChar = 8, maxChar = 20) => {
    const [error, setError] = useState(null)
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [passwordOK, setPasswordOK] = useState(false)
    const [formatError, setFormatError] = useState(false)

    const { txt } = useLanguage()

    useEffect(() => {
        if (!password || password === '' || password === undefined) {
            setPasswordOK(null)
            setError(null)
        } else if ((!single && (password !== confirm)) || password.length < minChar || password.length > maxChar) {

            setPasswordOK(false)
        } else {

            setPasswordOK(true)
            setError(null)
        }
    }, [password, confirm])

    const checkValidity = () => {
        if (passwordOK) {
            console.log('password OK');
            return true;
        }

        if (!single && (password !== confirm)) {
            console.log('confirm error');
            window.alert(txt.ERROR_PASSWORD_MISMATCH)
            // setError(txt.ERROR_PASSWORD_MISMATCH)
            return false
        }

        if (password.length < minChar || password.length > maxChar) {
            console.log('length error');
            setError(txt.ERROR_PASSWORD_INVALID)
            return false
        }

        console.log('other error');
    }

    const reset = () => {
        setError(null)
        setPassword('')
        setConfirm('')
        setPasswordOK(false)
        setFormatError(false)
    }

    const form = <>
        <input type="password" className='form__field password' required value={password} onChange={e => setPassword(e.target.value)} placeholder={single ? txt.PLACEHOLDER_PASSWORD : txt.PLACEHOLDER_NEW_PASSWORD} />
        {!single && <input type="password" className={`form__field password${password !== confirm ? ' invalid' : ''}`} required value={confirm} onChange={e => setConfirm(e.target.value)} placeholder={txt.PLACEHOLDER_REENTER_PASSWORD} />}
    </>

    return { pwOK: passwordOK, pwError: error, pwFormatError: formatError, pwForm: form, checkPW: checkValidity, password, reset }
}

export default usePasswordValidator