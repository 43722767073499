import classes from './CProgressIndicator.module.css';

const CProgressIndicator = props => {
    const { numSections, currentSection, progress } = props;

    const sectionLength = (100 / numSections);
    const fill = currentSection * sectionLength + (progress / 100) * sectionLength;

    // Use transform instead of height for animation
    const transformStyle = `scaleY(${fill / 100})`;

    return (
        <div className={classes['progress-bar']}>
            <div className={classes['progress-indicator-section']} style={{ height: '100%' }}>
                <div className={classes['progress-bar-section']} >
                    {/* Use transform for smoother animation */}
                    <div className={classes['progress-bar-section__inner']} style={{ transform: transformStyle }} />
                </div>
            </div>
        </div>
    );
};
export default CProgressIndicator;