import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Outlet, useSearchParams } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import useDetectTop from '../hooks/useDetectTop';
import useREM from '../hooks/useREM';
import useMediaQuery from '../hooks/useMediaQuery';
import Footer from '../components/layout/Footer';
import { MouseCatcher } from '../components/UI/Portal';

import SectionHeader from '../components/layout/SectionHeader';
import { MenuCategoryLink } from '../components/menu/layout/MenuCategorySection';

import { menuCategories } from 'common/constants/menuConstants';

// Initialize polyfill
smoothscroll.polyfill();

const MenuCategoryRow = ({ categories, disableAnimation, sp, shrink, bottom = false, onClick }) => {
    const hidden = bottom && shrink;

    return (
        <div className={`menu-category-row ${bottom ? 'bottom' : ''}${!bottom && shrink && sp ? 'scroll' : ''}`}
            style={{ ...(bottom && { marginTop: hidden ? 0 : '3rem' }), ...(hidden && { visibility: 'hidden' }) }}>
            {bottom && <div></div>}
            {categories.map((c, i) => (
                <MenuCategoryLink onClick={onClick} key={i} hideOnShrink={bottom} disableAnimation={disableAnimation} shrink={shrink} category={c} />
            ))}
        </div>
    );
}

const MenuScreen = () => {
    const navigate = useNavigate();
    const ref = useRef(null);
    const catRef = useRef(null);
    const bodyRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const sp = useMediaQuery();
    const [disableAnimation, setDisableAnimation] = useState(searchParams.get('col') === 'true');
    const [didEnter, setDidEnter] = useState(false);
    const categoriesTop = [menuCategories.appetizers, menuCategories.curry, menuCategories.main_other, menuCategories.rice_roti];
    const categoriesBottom = [menuCategories.set_menu, menuCategories.south_indian, menuCategories.drinks];
    const categories = [...categoriesTop, ...(sp ? [] : categoriesBottom)];

    const { navHeight } = useREM();

    const { top: fixed, scrolledOffset, resetOffset, resetInitialOffset } = useDetectTop(catRef, navHeight);
    const { category } = useParams();

    const handleCategoryChange = () => {
        const bRefOffset = bodyRef.current.offsetTop - navHeight - 100;
        if (window.scrollY > bRefOffset) {
            console.log(`offset is ${bRefOffset}`);
            setTimeout(() => {
                window.scrollTo({ top: bRefOffset, behavior: 'smooth' });
            }, 0);
            setDidEnter(true);
        }
    }

    useEffect(() => {
        if (!category) {
            navigate('/menu/appetizers');
        } else if (!didEnter && searchParams.get('col') === 'true') {
            window.scrollTo({ top: 0 });
            resetOffset();

            const offset = catRef.current.offsetTop - navHeight;
            setTimeout(() => {
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }, 0);

            setDidEnter(true);
            resetInitialOffset();
            searchParams.delete('col');
            setSearchParams(searchParams);
            setDisableAnimation(false);
        } else if (sp && fixed) {
            catRef.current.scrollLeft = 0;
        }
    }, [category, didEnter, fixed, sp, bodyRef.current]);

    return (
        <>
            <main className='page-menu' style={{ overflow: 'unset' }}>
                <div>
                    <SectionHeader
                        imgName='menu/menu-header.png'
                        title={`Menu & Order`}
                        rightOffset={sp ? 60 : 35}
                        bottomOffset={sp ? 20 : 35}
                        bkgSize={sp ? '190%' : '100%'}
                    />
                </div>
                <div ref={ref} style={{ height: 1 }}></div>

                <div className={`sticky-menu${scrolledOffset > 400 ? ' shrink' : ''}`} ref={catRef}>
                    <div className='menu-category-wrapper' style={{ ...(fixed && sp && { overflow: 'scroll' }) }}>
                        <MenuCategoryRow disableAnimation={disableAnimation} onClick={handleCategoryChange} sp={sp} categories={[...categories, ...(fixed && sp ? categoriesBottom : [])]} shrink={fixed} />
                        {sp && <MenuCategoryRow disableAnimation={disableAnimation} onClick={handleCategoryChange} bottom={true} categories={categoriesBottom} shrink={fixed} />}
                    </div>
                </div>

                <div ref={bodyRef} className='page-body'>
                    <Outlet />
                </div>

                <MouseCatcher />
                {sp && <Footer />}
            </main>
        </>
    );
};

export default MenuScreen;