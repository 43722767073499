import React from 'react'

const Selector = ({ items, onChange, value }) => {

    return (
        <select value={value ?? items[0]} name="selector" id="selector" onChange={(e) => onChange(e.target.value)}>
            {items.map((t) => {
                return <option key={t} value={t}>{t}</option>
            })}
        </select>
    )
}

export default Selector