import React from 'react'

import useLanguage from '../../hooks/use-language'
import { useDispatch, useSelector } from 'react-redux'

import { setCartNotes } from '../../store/actions/cartActions'

import CartItem from './CartItem'


const CartItemsSection = ({ editing, soldOut }) => {
    const dispatch = useDispatch()
    const { txt } = useLanguage()
    const { cartItems, notes } = useSelector(state => state.cart)
    const total = cartItems.filter(i => !soldOut.includes(i._id)).reduce((a, b) => a + (b.price * b.amount), 0)
    return <>
        {
            cartItems.length > 0 ?
                <div className='cart__items'>

                    <> {cartItems.map(i =>
                        <CartItem editing={editing} key={i._id} item={i} soldOut={soldOut.includes(i._id)} />
                    )}
                        <div className='cart__total-row'>
                            <h1 className='cart__total'><span className='cart__total--label'>{txt.LABEL_TOTAL}</span><span className='cart__total--price'>{total}B</span></h1>
                        </div>
                        <div className='cart__notes-section'>
                            {editing ? <textarea value={notes} className='cart__notes' name="notes" id="cnotes" placeholder='NOTES' onChange={(e) => dispatch(setCartNotes(e.target.value))} /> : <p style={{ height: 'auto', fontSize: '2.5rem' }} className='cart__notes' >{notes && notes !== '' && `NOTES: ${notes}`}</p>}
                        </div>
                    </>

                </div>
                : <div className='cart__no-items'>
                    <h1>{txt.MESSAGE_NO_ITEMS}</h1>
                </div>
        }
    </>
}

export default CartItemsSection