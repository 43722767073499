import React from 'react'
import ValidatorInput from './ValidatorInput'



const ConditionalEditField = ({ isEditing, label, onChange, value, required, type, hideLabelOnEdit }) => {

    return (
        <div className='conditional-field'>
            {(!isEditing || !hideLabelOnEdit) && <label className='conditional-field__label'>{`${label}:`}</label>}
            {isEditing ? <ValidatorInput value={value} type={type} className='form__field' placeholder={label} onChange={(e) => onChange(e.target.value)} required={required} /> : <p className='editable-field__value'>{value}</p>}
        </div>
    )
}

export default ConditionalEditField