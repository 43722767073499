import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userLoginReducer,
  userDetailsReducer,
  userUpdateReducer,
  userListReducer,
  userDeleteReducer,
  userRegisterReducer,
} from './reducers/userReducers';

import { ppReducer } from './reducers/ppReducerss';

import { DEFAULT_STORE_INFO } from '../constants/defaultStoreInfo';

import { scheduleReducer } from './reducers/scheduleReducers';

import { loginScreenReducer } from './reducers/loginScreenReducer';

import { storeInfoReducer } from './reducers/storeReducers';

import { cartReducer } from './reducers/cartReducers';
import { uiReducer } from './reducers/uiReducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userProfile: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userRegister: userRegisterReducer,
  storeInfo: storeInfoReducer,
  cart: cartReducer,
  ui: uiReducer,
  loginScreen: loginScreenReducer,
  schedule: scheduleReducer,
  pp: ppReducer,
});

const initialState = {
  cart: {
    cartItems: JSON.parse(localStorage.getItem('cartItems')) ?? [],
    showing: false,
    notes: localStorage.getItem('cartNotes') ?? '',
  },
  userLogin: { userInfo: JSON.parse(localStorage.getItem('userInfo')) ?? null },

  ui: {
    language: localStorage.getItem('language') ?? 'en',
    imgShowing: false,
    hamShowing: false,
    navHeight: 0,
    alert: null,
  },
  loginScreen: { showing: false, showGuest: false, onLogin: () => {} },
  schedule: { isLunch: false, isDinner: false, lastOrder: false },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
