import React from 'react'

import useLanguage from '../../../hooks/use-language'
import useMediaQuery from '../../../hooks/useMediaQuery'

import AnimateIn from '../../UI/AnimateIn'
import DescText from '../../typography/DescText'

import { IMG_PATH } from '../../../constants/conveniences'

const Staff = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    const desc = <AnimateIn direction={sp ? 'up' : 'right'} offsetAmt={sp ? 40 : 300}>
        <DescText className='staff__desc'>{txt.DESCRIPTION_STAFF}</DescText>
    </AnimateIn>
    return (
        <section className='section-staff'>
            <div className='staff__group'>
                <div className='staff__txt'>
                    <AnimateIn direction='right' offsetAmt={sp ? 40 : 200} delay={300}>
                        <h2 className='about-title staff__title'>Staff</h2>
                    </AnimateIn>
                    {!sp && desc}
                </div>
                {/* <div className='staff__img--1' /> */}
                <img className='staff__img--1' src={IMG_PATH + '/about/staff.png'} alt="our staff" />
                {sp && desc}
            </div>
            {!sp && <img className='staff__img--2' src={IMG_PATH + 'about/toss.jpg'} alt="" />
            }
        </section>
    )
}

export default Staff