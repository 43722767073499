import React from 'react'
import AnimateIn from '../../UI/AnimateIn'
import DescText from '../../typography/DescText'
const DoublePhotoSection = ({ imageOne, imageTwo, title, txt, delay }) => {
    const imgPath = process.env.PUBLIC_URL + '/assets/images/'
    return <section className='dps'>
        <AnimateIn direction='down' delay={delay ?? 0} immediate={delay > -1} offsetAmt={50} startingOpacity={0}>
            <h2 className='txt-subhead'>{title}</h2>
        </AnimateIn>
        <div className='dps__images'>

            <img className='dps__img' src={imgPath + imageOne} alt="" />
            <img className='dps__img' src={imgPath + imageTwo} alt="" />

        </div>
        <AnimateIn direction='up' offsetAmt={50} startingOpacity={0}>
            <DescText>{txt}</DescText>
        </AnimateIn>
    </ section>

}

export default DoublePhotoSection;