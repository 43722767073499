
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useParallax from "../../../../hooks/use-parallax";
import useFetchRequest from "../../../../hooks/api/useFetchRequest";
import useMediaQuery from "../../../../hooks/useMediaQuery";

import ParallaxImage from "../../../UI/ParallaxImage";
import Events from './Events'

import dir from 'common/constants/directoryConstants'




const News = () => {
    const newsRef = useRef(null)
    const newsOffset = useParallax(newsRef, -30, 0.05, 40);
    const { data: newsItems, performFetchRequest, error } = useFetchRequest()
    const { imgShowing } = useSelector(state => state.ui)
    const sp = useMediaQuery()


    useEffect(() => {
        if (!newsItems && !error) {
            performFetchRequest('', dir.NEWS)
        }
    }, [performFetchRequest, newsItems, error])

    return <section className='section-news' style={{ ...(imgShowing && !sp && { position: 'unset' }) }}>
        <div className='news-bkg'>
            <ParallaxImage round={sp} bkgAttachment='local' multiplier={sp ? 0.05 : 0.1} bkgSize={sp ? '200%' : '100%'} rightOffset={sp ? 58 : 85} bottomOffset={sp ? -40 : 0} imgName='home/news-img.jpg' />
        </div>


        <div className='news-content'>

            <Events />

            <div ref={newsRef} className='news-wrapper' style={{ ...(!sp && { transform: `translateY(${-newsOffset}%)` }) }}>
                <div className="news-info">
                    <h1 className="news-info__title txt-header">News</h1>
                    <ul className="news-info__items">
                        {newsItems && newsItems.map((e, i) => {

                            const arr = e.content.split('*<')

                            const createHTML = (str) => {
                                if (str.includes('{')) {
                                    const comp = str.split('{')
                                    return <a key={`${str}${i}`} href={`${comp[1]}`} target="_blank" rel="noopener noreferrer">{comp[0]}</a>
                                } else {

                                    return <span key={`${str}${i}`}>{str}</span>
                                }
                            }

                            var inner = []

                            if (arr.length > 1) {
                                arr.forEach(e => {
                                    const spl = e.split('}')

                                    spl.forEach(s => {

                                        inner.push(createHTML(s))
                                    })
                                })
                            } else {
                                inner = e.content
                            }


                            return <li key={i} className="news-info__item">{inner}</li>
                        })}
                    </ul>
                </div>

            </div>

        </div>


    </section>
}


export default News;

