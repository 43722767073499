import React from 'react'
import AnimateIn from '../UI/AnimateIn'
import ParallaxImage from '../UI/ParallaxImage';

export const SplashHeader = ({ title, subtitle, txt, lgTitle }) => {
    const anOffset = 60;
    return (
        <div className="splash-header">
            <AnimateIn direction={'up'} offsetAmt={anOffset}>
                <h1 className={`txt-header splash-header__title${lgTitle ? ' lg' : ''}`}>{title}</h1>

            </AnimateIn>
            <AnimateIn direction={'up'} offsetAmt={anOffset}>
                <h2 className='txt-header splash-header__subtitle'>{subtitle}</h2>
            </AnimateIn>
            {txt && <AnimateIn direction={'up'} offsetAmt={anOffset}>
                <p className="splash-header__txt">{txt}</p>
            </AnimateIn>}
        </div>
    )
}

const SplashSection = ({ title, subtitle, txt, imgName, btmWrapper, children, height, rightOffset = 35, bkgSize = '100%', bottomOffset = 35, lgTitle }) => {
    // const anOffset = 60;
    return (<section className='section-splash' style={{ height: `${height}rem` }}>
        <div className='section-splash__img-wrapper'>
            <ParallaxImage bkgAttachment='local' multiplier={0.03} bkgSize={bkgSize} rightOffset={rightOffset} bottomOffset={bottomOffset} imgName={imgName} />
        </div>
        <SplashHeader title={title} subtitle={subtitle} txt={txt} lgTitle={lgTitle} />
        <div className={btmWrapper}>
            <AnimateIn direction={'up'} offsetAmt={200} startingOpacity={0.5}>
                {children}
            </AnimateIn>

        </div>


    </section>



    )
}

export default SplashSection