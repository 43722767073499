import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Transition } from 'react-transition-group'
import CloseBtn from "../../../UI/CloseButton";
import { UPLOAD_IMG_PATH } from '../../../../constants/conveniences';

const EventCarouselSP = ({ images, showing, selectedIndex, onClose, local }) => {

    return (
        <Transition unmountOnExit mountOnEnter in={showing} timeout={300}>
            {(state) => {

                return <div className={`modal-container event-stack__carousel-sp ${state}`} >

                    <Swiper
                        modules={[Pagination, Navigation]}
                        pagination={{
                            type: 'bullets',
                            clickable: true,
                        }}
                        loop={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        initialSlide={selectedIndex}
                    >
                        {images.map((src, i) => {
                            return <SwiperSlide key={i} className='sp-carousel__slide'>
                                <img src={`${local ? '' : UPLOAD_IMG_PATH}${src.path}`} alt="" />
                            </SwiperSlide>
                        })}
                    </Swiper>
                    <CloseBtn onClick={onClose} state={state} />
                </div>

            }}
        </Transition>

    )
}

export default EventCarouselSP