import { str } from '../../constants/reduxConstants';
import { DEFAULT_STORE_INFO } from '../../constants/defaultStoreInfo';
export const storeInfoReducer = (
  state = { storeInfo: DEFAULT_STORE_INFO, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case str.FETCH_REQUEST:
      return { ...state, loading: true };
    case str.FETCH_SUCCESS:
      return { loading: false, storeInfo: action.payload };
    case str.FETCH_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
