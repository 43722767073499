import React from "react";
import useLanguage from "./use-language";
import useTambon from 'common/hooks/useTambon';

const useFormatter = () => {
    const { txt, lang } = useLanguage()
    const { getTambonName } = useTambon();
    const isEng = lang === 'en'

    const formatPhone = (text) => {
        let cleaned = ('' + text).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return text;
    };

    const formatAddress = ({
        street,
        village,
        tambon,
        placeName,
        amphur = 'Mueang Khon Kaen',
        province,
    }) => {

        return `${street} ${placeName}, ${village && (isEng ? 'M. ' : 'หมู่ ') + village
            + ', '}${getTambonName(tambon)}, ${amphur}, ${province}`;
    };

    return { formatAddress, formatPhone }


}




export default useFormatter;