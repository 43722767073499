import React from 'react'

import useLanguage from '../hooks/use-language'
import useMediaQuery from '../hooks/useMediaQuery'

import SectionHeader from '../components/layout/SectionHeader'
import CateringSection from '../components/layout/Restaurant/CateringSection'
import Collage from '../components/layout/Restaurant/Collage'
import DoublePhotoSection from '../components/layout/Restaurant/DoublePhotoSection'
import BarSection from '../components/layout/Restaurant/BarSection'
import LocationSection from '../components/layout/Restaurant/LocationSection'
import { PortalDiv } from '../components/UI/Portal'



const RestaurantScreen = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return (<>
        <main className='restaurant-screen' style={{ overflow: 'hidden' }}>
            <SectionHeader imgName='restaurant/restaurant-header.jpg' title='Our Restaurant' rightOffset={sp ? 80 : 35} bottomOffset={sp ? 10 : 35} bkgSize={sp ? '190%' : '100%'} />
            <div className='res-body page-body'>
                <DoublePhotoSection delay={300} title='Garden Dining' imageOne={'restaurant/garden-1.png'} imageTwo='restaurant/garden-2.png' txt={txt.DESCRIPTION_GARDEN} />
                <Collage />
                <BarSection />
                <DoublePhotoSection title='Private Room' imageOne={'restaurant/private-2.jpg'} imageTwo='restaurant/private-1.jpg' txt={txt.DESCRIPTION_PRIVATE_ROOM} />


            </div>
            <CateringSection />

            <LocationSection />
        </main>
        <PortalDiv />
    </>

    )
}



export default RestaurantScreen