import { crt } from '../../constants/reduxConstants';

export const cartReducer = (
  state = { cartItems: [], showing: false, orderNotes: '' },
  action
) => {
  switch (action.type) {
    case crt.ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x._id === item._id);

      if (existItem) {
        item.amount += existItem.amount;
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x._id === existItem._id ? item : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    case crt.REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x._id !== action.payload),
      };
    case crt.UPDATE_ITEM:
      const i = action.payload;

      return {
        ...state,
        cartItems: state.cartItems.map((x) => (x._id === i._id ? i : x)),
      };
    case crt.SET_NOTES:
      return {
        ...state,
        notes: action.payload,
      };

    case crt.RESET:
      return {
        ...state,
        notes: '',
        cartItems: [],
      };

    case crt.TOGGLE:
      const showing = state.showing;

      return {
        ...state,
        showing: !showing,
      };
    default:
      return state;
  }
};
