import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import ParallaxImage from '../UI/ParallaxImage'






const SectionHeader = ({ imgName, title, rightOffset = 35, bkgSize = '100%', bottomOffset = 35 }) => {

  return <section className='section-header'>

    <div className='section-header__image'>
      <ParallaxImage bkgAttachment='local' multiplier={0.03} bkgSize={bkgSize} rightOffset={rightOffset} bottomOffset={bottomOffset} imgName={imgName} />
    </div>
    <div className='section-header__txt-wpr'>
      <h1 className='txt-header section-header__txt'>{title}</h1>
    </div>

  </section>;
};

export default SectionHeader;
