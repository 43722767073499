import React, { useState } from 'react'
import Loader from './Loader'

const SafeImg = ({ src, alt, placeholder, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholder)
    const [isLoaded, setIsLoaded] = useState(false)

    const handleLoad = () => {
        setIsLoaded(true)
    }

    const handleError = () => {
        setImgSrc(placeholder)
    }

    return (
        <>
            {!isLoaded && <img src={placeholder} alt="placeholder" {...props} />}
            {/* <Loader centerOver={true} loading={!isLoaded} /> */}
            <img
                src={src}
                alt={alt}
                onLoad={handleLoad}
                onError={handleError}
                style={isLoaded ? {} : { display: 'none' }}
                {...props}
            />

        </>
    )
}


export default SafeImg