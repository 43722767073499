import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useLanguage from '../hooks/use-language'
import { toggleLogin } from '../store/actions/loginScreenActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useTambon from 'common/hooks/useTambon';
import useUpdateRequest from '../hooks/api/useUpdateRequest';

import { Link } from 'react-router-dom';
import ErrorMessage from '../components/typography/ErrorMessage';
import CustomCheckbox from '../components/UI/CustomCheckbox';

import EditableField, { EditableAddressForm, EditablePasswordForm } from '../components/forms/EditableField';
import { logout, updateUser } from '../store/actions/userActions';

import dir from '../constants/directoryConstants'
import { usr } from '../constants/reduxConstants';

import useErrorHandler from '../hooks/use-error-handler';


const ProfileScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { txt, lang } = useLanguage();
    const tambonList = useTambon(lang)
    const { userInfo } = useSelector(state => state.userLogin)
    const { loading: updateLoading, error: updateError, data: updateData, performUpdateRequest, resetUpdateRequest } = useUpdateRequest()
    const [editing, setEditing] = useState(null)
    const { getErrorMessage } = useErrorHandler()


    useEffect(() => {

        if (!userInfo) {
            dispatch(toggleLogin())
            navigate('/')
        }

    }, [userInfo])

    const setField = (value, key) => {

        update({ [key]: value })
    }

    const checkHandler = (checked) => {

        update({ receivesPromotions: checked })

    }

    const saveAddress = (value) => {

        var addresses = userInfo.addresses
        if (userInfo.addresses.filter(a => a._id === value._id).length > 0) {

            addresses = addresses.map(a => {
                if (a._id === value._id) {
                    return value
                }
                return a
            })
        } else {

            addresses = [...userInfo.addresses, value]
        }

        update({ addresses })
    }

    const removeAddress = (id) => {

        if (window.confirm(txt.CONFIRM_DELETE_ADDRESS)) {
            update({ addresses: userInfo.addresses.filter(a => a._id !== id) })

        }
    }

    const logoutHandler = () => {
        if (userInfo) {

            dispatch(logout())
        }
    }

    const update = (updatedData) => {
        resetUpdateRequest()

        performUpdateRequest(updatedData, dir.USER_PROFILE, userInfo.token, (data) => {

            dispatch({ type: usr.LOGIN_SUCCESS, payload: data })
        })
    }

    return (<main className='profile-screen'>
        {userInfo && <>
            <h1 className='profile-screen__header'>My Page</h1>
            <ErrorMessage error={getErrorMessage(updateError)} />
            {/* {updateError && <p className='profile-screen__error'>{updateError.includes('duplicate key') ? 'Error! ' + txt.ERROR_USERNAME_EXISTS : updateError}</p>} */}
            <div className='mini-section'>
                <div className='mini-section__header'>
                    <h2>{txt.LABEL_REGISTRATION}</h2>

                </div>
                <EditableField value={userInfo.name} label={txt.PLACEHOLDER_NAME} onSave={(v) => { setField(v, 'name') }} type='name' editing={editing} setEditing={(v) => setEditing(v)} />
                <EditableField value={userInfo.email} label={txt.PLACEHOLDER_EMAIL} onSave={(v) => { setField(v, 'email') }} type='email' editing={editing} setEditing={(v) => setEditing(v)} />


                <EditableField value={userInfo.tel} label={txt.PLACEHOLDER_TEL} onSave={(v) => { setField(v, 'tel') }} type='tel' editing={editing} setEditing={(v) => setEditing(v)} />
                <div className='editable-field addresses' style={{ height: 'auto', paddingRight: 0 }}>
                    <div className='address-label'>
                        <p className='editable-field__label' style={{ alignSelf: 'flex-start' }}>Delivery Addresses</p>
                    </div>



                    <div className='profile-addresses' style={{ textAlign: 'center' }}>
                        {userInfo.addresses && userInfo.addresses.map((a, i) => {
                            return <EditableAddressForm key={i} existingAddress={a} onSave={saveAddress} setEditing={(v) => setEditing(v)} identifier={i} editing={editing} onRemove={removeAddress} />
                        })}
                        {editing === 'create-address' ? <EditableAddressForm setEditing={(v) => setEditing(v)} identifier={'create-address'} editing={editing} onSave={saveAddress} />
                            : <div className='addresses__add-address'>
                                <button className='btn-add' disabled={editing === 'create-address'} onClick={() => setEditing('create-address')} style={{ margin: 'auto', textAlign: 'center' }}><i className='fas fa-plus' /></button>
                            </div>
                        }
                    </div>
                </div>
                <EditablePasswordForm editing={editing} setEditing={(v) => setEditing(v)} />
            </div>
            <div className="profile-screen__checkbox">
                <CustomCheckbox checked={userInfo.receivesPromotions} label={txt.LABEL_PROMOTIONS_OPT_IN} onChange={(e) => checkHandler(e.target.checked)} disabled={updateLoading} />
            </div>

            <div className='profile-logout'>
                <Link onClick={logoutHandler} to="/" href="#">Logout</Link>
            </div>

        </>}

    </main >

    )
}

export default ProfileScreen