import React from 'react'


const HamburgerButton = ({ open, onClick }) => {

    return (
        <div className='hamburger__wrapper' onClick={onClick}>
            <div className={`hamburger__content hamburger__content--1 ${open && 'open'}`}></div>
            <div className={`hamburger__content hamburger__content--2 ${open && 'open'}`}></div>
            <div className={`hamburger__content hamburger__content--3 ${open && 'open'}`}></div>

        </div>
    )
}

export default HamburgerButton