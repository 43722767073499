import { useCallback } from "react"
import { useState } from "react"
import { useEffect } from "react"

export const usePasswordValidator = (value, confirm) => {
    const [passwordsMatch, setPasswordsMatch] = useState(false)

    useEffect(() => {

        setPasswordsMatch(value && value !== '' && value === confirm)

    }, [value, confirm])

    return passwordsMatch

}


const useFormValidator = (value, type) => {

    const [isValid, setIsValid] = useState(false)

    const isNum = useCallback((v) => {

        if (/^\d+$/.test(v)) {
            return true
        } else {
            return false
        }
    })

    const isEmail = useCallback((v) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (value || regex.test(value) === false) {
            return false
        } else {
            return true
        }
    })

    useEffect(() => {

        switch (type) {
            case 'num':
                setIsValid(isNum(value))
            case 'tel':
                setIsValid(isNum(value) && value.length === 10)
            case 'email':
                setIsValid(isEmail(value))
            default:

                setIsValid(true)
        }

    }, [value, type])

    const validateTel = (val) => {
        return (isNum(val) && val.length < 11) || val === ''
    }

    return { isValid, isNum, validateTel }
}

export default useFormValidator;

