import { useState, useEffect } from "react";

const useCountdown = (length = 5000, onFinished = () => { }, interval = 100, repeat = true) => {

    const [numberRotations, setNumberRotations] = useState(0);

    useEffect(() => {
        let intervalTimer = setInterval(() => {
            setNumberRotations(prev => {
                if ((prev + 1) * interval >= length) {
                    onFinished();
                    return 0;  // reset to 0 when countdown ends
                } else {
                    return prev + 1;  // increment otherwise
                }
            })
        }, interval)

        return () => clearInterval(intervalTimer);
    }, [interval, length, onFinished])  // added interval, length and onFinished to dependency array

    return {
        count: numberRotations,
        percentage: ((numberRotations * interval) / length) * 100
    }

}

export default useCountdown;