import React, { useEffect } from 'react'
import Cart from '../components/cart/Cart'
import useMediaQuery from '../hooks/useMediaQuery'
import { crt } from '../constants/reduxConstants'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const CartScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showing } = useSelector(state => state.cart)
    const sp = useMediaQuery()


    useEffect(() => {
        if (!sp && !showing) {
            dispatch({ type: crt.TOGGLE })
            navigate('/')
        }
    }, [showing, sp])

    return (
        <main className='cart-screen'>
            <Cart />
        </main>
    )
}

export default CartScreen