
import { useEffect, useState } from "react";

const TopButton = () => {

    const [isShowing, setIsShowing] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 500) {

                setIsShowing(true)
            } else {
                setIsShowing(false)
            }
        });
    }, [])


    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return <button onClick={handleClick} className={`top-button ${!isShowing && 'hidden'}`}>
        <div className="top-button__label">top</div>
        <img className="top-button__img" src={process.env.PUBLIC_URL + '/assets/images/components/lotus.png'} alt="" />
    </button>

}

export default TopButton;