import React from 'react'

const SocialLink = ({ icon, url, fs }) => {
    return (
        <>
            {url && <a href={url} className='social-links__link' target="_blank" rel="noopener noreferrer">
                <i style={fs ? { fontSize: `${fs}rem` } : {}} className={`fab fa-${icon}`} />
            </a>}
        </>
    )
}

export default SocialLink