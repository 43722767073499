export const IMG_PATH = process.env.PUBLIC_URL + '/assets/images/';
export const UPLOAD_IMG_PATH =
  process.env.NODE_ENV === 'development' ? '' : window.location.origin;

export const MAP_HTML = {
  __html:
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.6375166785533!2d102.8307147506055!3d16.493882232242193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31228b7b9735f8e5%3A0x75005b837fc24466!2zUVVJTk4nUyBPbmUgJiBPbmx5IOC4o-C5ieC4suC4meC4reC4suC4q-C4suC4o-C4reC4tOC4meC5gOC4lOC4teC4oiDguILguK3guJnguYHguIHguYjguJk!5e0!3m2!1sen!2sth!4v1636647126349!5m2!1sen!2sth" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>',
};

export const MAP_HTML_2 = {
  __html:
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.6375166785533!2d102.8307147506055!3d16.493882232242193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31228b7b9735f8e5%3A0x75005b837fc24466!2zUVVJTk4nUyBPbmUgJiBPbmx5IOC4o-C5ieC4suC4meC4reC4suC4q-C4suC4o-C4reC4tOC4meC5gOC4lOC4teC4oiDguILguK3guJnguYHguIHguYjguJk!5e0!3m2!1sen!2sth!4v1636647126349!5m2!1sen!2sth" width="750" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>',
};
