
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

import useCreateRequest from '../../hooks/api/useCreateRequest'
import useLanguage from '../../hooks/use-language';

import { showAlert, showError } from '../../store/actions/uiActions';
import useFieldFormatter, { FIELD_FORMAT } from '../../hooks/useFieldFormatter';

import Loader from '../UI/Loader';

import dir from '../../constants/directoryConstants'




const ReservationForm = () => {
    const dispatch = useDispatch()
    const { loading, error, success, performCreateRequest } = useCreateRequest()
    const { userInfo } = useSelector(state => state.userLogin)
    const { txt } = useLanguage()
    const { inputAllowed, formatDisplay, stripOutput } = useFieldFormatter(FIELD_FORMAT.tel)

    const dummyReservation = {
        customerName: (userInfo && userInfo.name) ?? '',
        email: (userInfo && userInfo.email) ?? '',
        tel: (userInfo && userInfo.tel) ?? '',
        numGuests: '',
        date: new Date(),
        notes: '',
    }

    const [reservation, setReservation] = useState(dummyReservation)

    useEffect(() => {
        if (success) {
            setReservation(dummyReservation)
            dispatch(showAlert('Success!', txt.MESSAGE_RESERVATION_SENT))
        } else if (error) {
            dispatch(showError(error))
        }
    }, [success, error])

    const setTelInput = (e) => {
        const value = stripOutput(e.target.value)
        if (inputAllowed(value)) setValue(value, 'tel')
    }

    const day = moment(reservation.date).format('YYYY-MM-DD')
    const time = moment(reservation.date).format('HH:mm')

    const submitHandler = e => {

        e.preventDefault();

        performCreateRequest(reservation, dir.RESERVATIONS)

    }

    const setDate = value => {

        setReservation(pVal => {
            const components = value.split('-')
            var newDate = new Date(pVal.date)
            newDate.setFullYear(components[0])
            newDate.setMonth(components[1])
            newDate.setDate(components[2])
            return {
                ...pVal,
                date: newDate
            }
        })
    }

    const setTime = value => {

        setReservation(pVal => {
            const components = value.split(':')
            var currentDate = new Date(pVal.date)
            currentDate.setHours(components[0])
            currentDate.setMinutes(components[1])
            return {
                ...pVal,
                date: currentDate
            }
        })
    }

    const setValue = (value, key) => {
        setReservation(pVal => {
            return { ...pVal, [key]: value }
        })
    }

    return <div className="reservation-form">
        <div className='reservation-form__bkg'></div>
        <form onSubmit={submitHandler} className='form'>
            <div className='reservation-form__inputs'>

                <input className='form__field reservation-form__name' value={reservation.customerName} onChange={e => setValue(e.target.value, 'customerName')} type="text" placeholder={txt.PLACEHOLDER_NAME} required />
                <input className='form__field reservation-form__tel' value={formatDisplay(reservation.tel)} type="tel" onChange={setTelInput} placeholder={txt.PLACEHOLDER_TEL} required />
                <input className='form__field reservation-form__email' value={reservation.email} type="email" onChange={e => setValue(e.target.value, 'email')} placeholder={txt.PLACEHOLDER_EMAIL} required />
                <input className='form__field reservation-form__num' value={reservation.numGuests} type="number" step='1' min='0' onChange={e => setValue(e.target.value, 'numGuests')} placeholder={txt.PLACEHOLDER_NOGUESTS} required />
                <input className='form__field date-input' value={day.toString()} onChange={(e) => setDate(e.target.value)} type="date" required />
                <input className='form__field time-input' value={time.toString()} type="time" onChange={(e) => setTime(e.target.value)} required />
                <textarea className='form__field full' value={reservation.notes} placeholder={txt.PLACEHOLDER_OTHER_INFO} onChange={e => setValue(e.target.value, 'notes')} name="" id="" cols="30" rows="10"></textarea>
            </div>

            <button type='submit' className='submit-btn' disabled={loading}>{loading ? <Loader size={20} color='gray' marginTop={0} /> : 'Make a Booking'}</button>

        </form>
    </div>


}

export default ReservationForm;