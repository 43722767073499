import { schd } from '../../constants/reduxConstants';

export const scheduleReducer = (
  state = { isLunch: false, isDinner: false, lastOrder: false },
  action
) => {
  switch (action.type) {
    case schd.SET_SCHEDULE:
      return action.payload;
    default:
      return state;
  }
};
