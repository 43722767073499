const errorType = {
  alreadyRegistered: {
    type: 'alreadyRegistered',
    message: {
      en: 'This Email or Telephone number has already been registered!',
      th: 'อีเมล์หรือหมายเลขโทรศัพท์นี้ได้ถูกลงทะเบียนแล้ว',
    },

    status: 409,
  },
  alreadyRegisteredUN: {
    type: 'alreadyRegisteredUN',
    message: {
      en: 'A user with this name already exists!',
      th: 'บัญชีตามชื่อนี้ได้ถูกใช้งานแล้ว',
    },

    status: 409,
  },
  auth: {
    type: 'auth',
    message: {
      en: 'Unauthorized. Please log in and try again.',
      th: 'ไม่ได้รับอนุมัติ โปรดเข้าสู่ระบบและลองใหม่อีกครั้ง',
    },
    status: 401,
  },
  backupFail: {
    type: 'backupFail',
    message: {
      en: 'Failed to create a backup',
      th: 'Failed to create a backup',
    },
    status: 401,
  },
  closed: {
    type: 'closed',
    message: {
      en: 'We are currently unable to process this request. Please try again during normal business hours',
      th: 'เราไม่สามารถดำเนินการตามคำขอนี้ได้ โปรดลองใหม่ในเวลาทำการ',
    },
    status: 503,
  },
  lastOrder: {
    type: 'lastOrder',
    message: {
      en: 'We are currently unable to process this request, as we have stopped taking orders for the evening. Please try again during normal business hours',
      th: 'เราไม่สามารถดำเนินการตามคำขอนี้ได้ โปรดลองใหม่ในเวลาทำการ',
    },
    status: 503,
  },
  deleteSelf: {
    type: 'deleteSelf',
    message: {
      en: 'You may not delete your own account. This is for your own protection.',
      th: 'You may not delete your own account. This is for your own protection.',
    },
    status: 403,
  },
  deleteMaster: {
    type: 'deleteMaster',
    message: {
      en: 'You may not delete the master account. This is for your own protection.',
      th: 'You may not delete the master account. This is for your own protection.',
    },
    status: 403,
  },
  invalidCreate: {
    type: 'invalidCreate',
    message: {
      en: 'Could not save data. Some required fields may be missing',
      th: 'ไม่สามารถบันทึกข้อมูลได้ มีบางข้อมูลหายไป',
    },
    status: 400,
  },
  invalidPassword: {
    type: 'invalidPassword',
    message: {
      en: 'The password must be 8-20 characters.',
      th: 'รหัสผ่านต้องมีความยาว 8-20 ตัวอักษร',
    },
    status: 400,
  },
  noData: {
    type: 'noData',
    message: {
      en: 'Could not find the requested data.',
      th: 'ไม่สามารถค้นหาข้อมูลที่ต้องการได้',
    },
    status: 404,
  },
  noItem: {
    type: 'noItem',
    message: {
      en: 'No item found.',
      th: 'ไม่พบรายการค้นหา',
    },
    status: 404,
  },
  noUser: {
    type: 'noUser',
    message: {
      en: 'No user with this email or telephone number found.',
      th: 'ไม่พบบัญชีผู้ใช่ตามอีเมล์หรือหมายเลขโทรศัพท์นี้',
    },
    status: 404,
  },
  noUserID: {
    type: 'noUserID',
    message: {
      en: 'No user with this id found.',
      th: 'ไม่พบบัญชีผู้ใช้ตามรหัสนี้',
    },
    status: 404,
  },

  password: {
    type: 'password',
    message: {
      en: 'The password is incorrect.',
      th: 'รหัสผ่านไม่ถูกต้อง',
    },
    status: 401,
  },
  write: {
    type: 'write',
    message: {
      en: 'Could not save! Error writing data!',
      th: 'ไม่สามารถบันทึกได้! การเขียนข้อมูลผิดพลาด!',
    },
    status: 500,
  },
};

module.exports = { errorType };
