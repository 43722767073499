import React, { useEffect } from 'react'
import { useState } from 'react'
import useLanguage from '../../hooks/use-language'
import useTambon from 'common/hooks/useTambon'
import Selector from './Selector'
import useFormValidator from '../../hooks/use-validator'

const AddressForm = ({ newAddress, setNewAddress }) => {

    const { txt, lang } = useLanguage()
    const { tambonList, getTambonID, getTambonName } = useTambon(lang)
    const { isNum } = useFormValidator()

    const setProperty = (e, key) => {
        const value = e.target.value

        if (key !== 'village' || isNum(value) === true || value == '') {
            setNewAddress(pVal => ({ ...pVal, [key]: value }))
        }

    }



    const setTambon = (v) => {
        const id = getTambonID(v)

        if (id) {
            setNewAddress(pVal => ({ ...pVal, tambon: id }))
        }
    }

    return (
        <div className='address-form' >

            <input value={newAddress.street} type="text" className='form__field' placeholder={txt.PLACEHOLDER_ADDRESS_STREET} onChange={(v) => setProperty(v, 'street')} required />

            <div className='address-form__moo-sub'>
                <input value={newAddress.placeName} type="text" className='form__field' placeholder={txt.PLACEHOLDER_ADDRESS_NAME} onChange={(v) => setProperty(v, 'placeName')} />
                <input style={{ width: '10rem' }} value={newAddress.village} type="number" inputMode='numeric' pattern="[0-9]*" className='form__field form__field--village' placeholder={txt.PLACEHOLDER_ADDRESS_MOO} onChange={(v) => setProperty(v, 'village')} />
                <div className='address-form__tambon'>
                    {/* <label htmlFor="#">{txt.PLACEHOLDER_ADDRESS_TAMBON}:</label> */}
                    <Selector value={getTambonName(newAddress.tambon)} items={tambonList} onChange={(v) => setTambon(v)} />
                </div>
            </div>

            <input value={newAddress.directions} type="text" className='form__field' placeholder={txt.PLACEHOLDER_ADDRESS_DIRECTIONS} onChange={(v) => setProperty(v, 'directions')} />
        </div>


    )
}

export default AddressForm