import Hero from '../components/layout/home/Hero';
import News from '../components/layout/home/news/News';
import About from '../components/layout/home/About';
import Restaurant from '../components/layout/home/Restaurant';
import MenuGrid from '../components/layout/MenuGrid';
import Reservation from '../components/layout/home/Reservations';
import Location from '../components/layout/home/Location';

import smoothscroll from 'smoothscroll-polyfill';


import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';

import useREM from '../hooks/useREM';


function HomeScreen() {
  const { navHeight } = useREM()

  smoothscroll.polyfill();
  const resRef = useRef(null)

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {

    if (resRef && searchParams.get('scr')) {

      window.scrollTo({ top: 0, behavior: 'smooth' })
      setSearchParams({})
    } else if (resRef && searchParams.get('sec') == 'res') {

      setTimeout(() => {
        const offset = resRef.current.offsetTop - navHeight;
        if (resRef.current && resRef.current.offsetTop) {
          window.scrollTo({ top: offset, behavior: 'smooth' })
          setSearchParams({})
        }


      }, 10)

    }
  }, [searchParams, resRef])

  return (
    <div style={{ overflowX: "hidden" }}>
      <main>
        <Hero />
        <News />
        <About />
        <Restaurant />
        <MenuGrid />
        <div ref={resRef} >
          <Reservation />
        </div>

        <Location />

      </main>

    </div>
  );
}

export default HomeScreen;
