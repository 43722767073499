import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

const SignupContactRow = ({ setUserProp, newUser }) => {
    const sp = useMediaQuery()
    const { txt } = useLanguage();

    const telHandler = (e) => {
        const isNum = /^\d+$/.test(e.target.value)
        if ((isNum || e.target.value === '') && e.target.value.length < 11) {
            setUserProp(e, 'tel')
        }
    }

    const emailRow = <input className='form__field' value={newUser.email} type="email" onChange={e => setUserProp(e, 'email')} placeholder={txt.PLACEHOLDER_EMAIL} required />
    return (<>
        <div className={`signup-row`}>
            <input className='form__field tel-field' value={newUser.tel} type="tel" onChange={telHandler} placeholder={txt.PLACEHOLDER_TEL} required />
            {!sp && emailRow}
        </div>
        {sp && <div className={`signup-row`}>{emailRow}</div>}
    </>
    )
}

export default SignupContactRow