import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from './hooks/useMediaQuery';
import { getStoreInfo } from './store/actions/storeActions';
import useSchedule from './hooks/useSchedule';
import useLanguage from './hooks/use-language';
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  Navigate,
} from 'react-router-dom';


import HomeScreen from './screens/HomeScreen';
import MenuScreen from './screens/MenuScreen';
import MenuBody from './components/menu/layout/MenuBody';
import NavBar from './components/Navigation/NavBar';
import NavBarMobile from './components/Navigation/NavBarMobile';
import NavMenuMobile from './components/Navigation/NavMenuMobile';
import TopButton from './components/buttons/TopButton';
import CartButton from './components/cart/CartButton';
import ScrollToTop from './components/UI/ScrollToTop';
import Cart from './components/cart/Cart';
import ProfileScreen from './screens/ProfileScreen';
import RestaurantScreen from './screens/RestaurantScreen';
import AboutScreen from './screens/AboutScreen';
import Footer from './components/layout/Footer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LoginModal from './components/layout/login/Login';
import AlertModal from './components/UI/AlertModal';
import ErrorBoundary from './components/util/ErrorBoundary';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import PrivacyConsent from './components/privacy/PrivacyConsent';

import { showError, showAlert } from './store/actions/uiActions';
import './css/main.css';
import CartScreen from './screens/CartScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';



function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { txt, lang } = useLanguage();
  const [searchParams] = useSearchParams();
  const col = searchParams.get('col');
  const sec = searchParams.get('sec');
  const sp = useMediaQuery();
  var pathName = location.pathname;
  const { pp } = useSelector(state => state.pp)

  const { hamShowing } = useSelector((state) => state.ui);
  const { showing: cartShowing } = useSelector((state) => state.cart);
  const { error, storeInfo } = useSelector((state) => state.storeInfo);
  const components = pathName.split('/');
  useSchedule()
  if (components.length > 1) {
    pathName = components[1];
  }

  useEffect(() => {

    dispatch(getStoreInfo());
  }, [dispatch]);


  useEffect(() => {
    if (error) {
      dispatch(showError(txt.ERROR_SERVER))
    }
  }, [error, dispatch])

  useEffect(() => {
    if (storeInfo && storeInfo['service_note']) {
      dispatch(showAlert('Service Message', storeInfo['service_note']))
    }
  }, [storeInfo])

  const routes =

    <Routes>
      <Route path='/' element={<HomeScreen />} />
      <Route path='/profile' element={<ProfileScreen />} />
      <Route path='/restaurant' element={<RestaurantScreen />} />
      <Route path='/about' element={<AboutScreen />} />
      <Route path='/menu' element={<MenuScreen />}>
        <Route path=':category' element={<MenuBody />} />
      </Route>
      <Route path='/cart' element={<CartScreen />} />
      <Route path='/resetpassword/:token' element={<PasswordResetScreen />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>


  return (
    <ErrorBoundary>
      <div className={`main-div ${lang}`}>
        {sp ? <NavBarMobile /> : <NavBar />}
        <ScrollToTop />

        {col || sec ? (
          routes
        ) : (
          <TransitionGroup component={null}>
            <CSSTransition timeout={200} classNames='fade' key={pathName}>
              {routes}
            </CSSTransition>
          </TransitionGroup>
        )}
        {(!sp || !location.pathname.includes('/menu')) && <Footer />}

        <TopButton />
        {cartShowing && !sp && <Cart />}
        <CartButton />
        <LoginModal />
        <AlertModal />
        <NavMenuMobile showing={hamShowing} />
        <PrivacyPolicy />
        {!pp && <PrivacyConsent />}
      </div>
    </ErrorBoundary>
  );
}

export default App;
