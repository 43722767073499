import { useEffect, useState } from "react";

const useMousePosition = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const trackPosition = e => setPosition({ x: e.clientX, y: e.clientY })
        window.addEventListener("mousemove", trackPosition)

        return () => {
            window.removeEventListener("mousemove", trackPosition)
        }
    }, [])

    return position;

}

export default useMousePosition;