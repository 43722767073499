import React from 'react'
import classes from './CustomRadioButton.module.css'

const CustomRadioButton = ({ label, checked, onChange, size = 2.2, disabled = false }) => {

    return (
        <label className={`${classes.container}`} style={{ paddingLeft: `${size * 1.36}rem`, ...(disabled && { opacity: '0.3' }) }}>{label}
            <input type='radio' checked={checked} onChange={onChange} disabled={disabled} />
            <span className={classes.checkmark} style={{ height: `${size}rem`, width: `${size}rem` }}></span>
        </label>
    )
}

export default CustomRadioButton