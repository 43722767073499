import React from 'react'
import useFormValidator, { usePasswordValidator } from '../../hooks/use-validator';
import useLanguage from '../../hooks/use-language'
import { useEffect } from 'react';
import { useState } from 'react';
import useFormatter from '../../hooks/useFormatter';
import useUpdateRequest from '../../hooks/api/useUpdateRequest';
import useErrorHandler from '../../hooks/use-error-handler';

import AddressForm from './AddressForm';
import { EditButton, DeleteButton } from '../buttons/ResponsiveButtons';


import { ClipLoader } from 'react-spinners';
import dir from '../../constants/directoryConstants';
import { useSelector } from 'react-redux';
import useMediaQuery from '../../hooks/useMediaQuery'






export const EditableFieldButtons = ({ isEditing, onCancel, setEditing, disableSave, onRemove }) => {
    const { txt } = useLanguage()

    return (

        <div className='editable-field__buttons'>
            {isEditing ?
                <>
                    <button key={'cancel'} className='ui-btn remove' type="button" onClick={onCancel}>{txt.BUTTON_CANCEL}</button>
                    <button className='ui-btn save' type='submit' disabled={disableSave}  >{txt.BUTTON_SAVE}</button>
                </>
                : <>
                    {onRemove && <DeleteButton onClick={onRemove} />}
                    <EditButton onClick={setEditing} />
                </>}
        </div>
    )
}

export const EditableAddressForm = ({ label, existingAddress, onSave, editing, setEditing, identifier, onRemove }) => {

    const { formatAddress } = useFormatter();
    const isEditing = editing === identifier
    const [newAddress, setNewAddress] = useState(existingAddress ?? {
        street: '',
        tambon: '400101',
        placeName: '',
        village: '',
        directions: '',
    })


    const handleSubmit = (e) => {

        e.preventDefault()
        onSave(newAddress)
        setEditing(null)
    }

    const removeHandler = () => {
        if (newAddress._id) {
            onRemove(newAddress._id)
        }
    }

    const cancelHandler = () => {
        setEditing(null)
    }


    return (
        <form key={identifier} className='editable-field editable-field__address' style={{ height: 'auto', paddingLeft: 0 }} onSubmit={handleSubmit}>
            {isEditing ? <div className='addresses__form-wrapper'><AddressForm address={existingAddress} newAddress={newAddress} setNewAddress={setNewAddress} /></div> : existingAddress ? <div className='address-field'>
                <p className='address-field__label'>{`・Address ${identifier + 1}:`}</p>
                <p className='address-field__existing'>{formatAddress(existingAddress)}</p>
            </div> : <></>}
            <EditableFieldButtons isEditing={isEditing} onCancel={cancelHandler} setEditing={() => setEditing(identifier)} identifier={identifier} onRemove={removeHandler} />
        </form>
    )

}

export const EditablePasswordForm = ({ editing, setEditing }) => {
    const isEditing = editing === 'password'
    const { txt } = useLanguage()
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [passwordReenter, setPasswordReenter] = useState('')
    const passwordsMatch = usePasswordValidator(newPassword, passwordReenter)
    const [errorMessage, setErrorMessage] = useState(null)
    const { userInfo } = useSelector(state => state.userLogin)
    const { loading, error, success, performUpdateRequest, resetUpdateRequest } = useUpdateRequest()
    const sp = useMediaQuery();
    const { getErrorMessage } = useErrorHandler()

    useEffect(() => {

        if (error) {
            setErrorMessage(getErrorMessage(error))
        }
    }, [error])

    const saveHandler = (e) => {
        e.preventDefault()
        setErrorMessage(null)
        if (passwordsMatch) {
            resetUpdateRequest()
            performUpdateRequest({ currentPassword, newPassword }, dir.USER_PASSWORD, userInfo.token, () => {
                clearAndClose()
            })
        } else {
            window.alert(txt.ERROR_PASSWORD_INVALID)
            // setErrorMessage(txt.ERROR_PASSWORD_MISMATCH)
        }
    }

    const editHandler = () => {
        resetUpdateRequest()
        setEditing('password')
    }

    const clearAndClose = () => {
        setErrorMessage(null)
        setNewPassword('')
        setPasswordReenter('')
        setCurrentPassword('')
        setEditing(null)
    }



    const newPassInputs = <>
        <input value={newPassword} style={{ marginRight: '1rem' }} type='password' className='form__field' placeholder={txt.PLACEHOLDER_NEW_PASSWORD} onChange={(e) => setNewPassword(e.target.value)} disabled={loading} required />
        <input value={passwordReenter} type='password' className={`form__field${passwordsMatch ? '' : ' invalid'}`} placeholder={txt.PLACEHOLDER_REENTER_PASSWORD} onChange={(e) => setPasswordReenter(e.target.value)} disabled={loading} required />
    </>

    return (
        <form className={`editable-field editable-field__pwd${isEditing ? ' editing' : ''}`} onSubmit={saveHandler} style={{ height: 'auto' }}>
            <div style={{ width: '100%' }}>
                <div className={`editable-field${isEditing ? ' editing' : ''}`} style={{ padding: 0, borderBottom: 'none' }}>
                    <p className='editable-field__label' >{txt.PLACEHOLDER_PASSWORD}</p>

                    {isEditing ? <div className='reset-password-field__forms'>

                        <input value={currentPassword} type='password' className='form__field' placeholder={txt.PLACEHOLDER_CURRENT_PASSWORD} onChange={(e) => setCurrentPassword(e.target.value)} disabled={loading} required />
                        {sp ? <div>{newPassInputs}</div> : newPassInputs}



                    </div>

                        :
                        success ? <p className='editable-field__value'>{txt.MESSAGE_PASSWORD_UPDATED}</p> : <></>}
                    {loading === true ? <ClipLoader loading={loading} size={40} /> : <EditableFieldButtons isEditing={isEditing} onSave={saveHandler} onCancel={clearAndClose} setEditing={editHandler} identifier={'password'} disableSave={false} />}
                </div>
                {errorMessage && <p className='profile-screen__error'>{errorMessage}</p>}
            </div>
        </form>

    )
}




const EditableField = ({ label, value, onSave, type, editing, setEditing, children }) => {

    const { txt } = useLanguage()

    const isEditing = editing === label
    const [newValue, setNewValue] = useState(value)
    const { isValid, validateTel } = useFormValidator(newValue, type);
    const { formatPhone } = useFormatter();

    useEffect(() => {

        if (editing !== label) {
            setNewValue(value)
        }
    }, [value, editing])

    const validate = () => {

        if (newValue.length >= 5 && isValid) {
            return true
        }

        return false
    }

    const saveHandler = (e) => {

        e.preventDefault()
        if (validate()) {
            if (newValue !== value) {
                onSave(newValue)
            }

            setEditing(null)
        }
    }

    const changeHandler = (e) => {
        if (type === 'tel' && !validateTel(e.target.value)) {
            return
        }

        setNewValue(e.target.value)
    }

    const cancelHandler = () => {
        setNewValue(value)
        setEditing(null)
    }


    return (
        <>
            <form className={`editable-field${isEditing ? ' editing' : ''}`} onSubmit={saveHandler}>

                <div className='editable-field__nfo'>
                    <div className='editable-field__label'>
                        <p>{label}</p>
                    </div>

                    <div className={`editable-field__content${isEditing ? ' editing' : ''}`}>
                        {isEditing ? <input value={newValue} type={type} className='form__field' placeholder={label} onChange={changeHandler} /> : <p className='editable-field__value'>{type === 'tel' ? formatPhone(value) : value}</p>}
                        <EditableFieldButtons isEditing={isEditing} onSave={saveHandler} onCancel={cancelHandler} setEditing={() => setEditing(label)} identifier={label} disableSave={!isValid} />
                    </div>


                </div>


            </form>

        </>
    )
}

export default EditableField