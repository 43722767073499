export const drinkSubcategories = [
  { dbKey: 'dessert', displayName: 'Dessert', osake: false },
  { dbKey: 'soft_drinks', displayName: 'Soft Drinks', osake: false },
  { dbKey: 'coffee_tea', displayName: 'Coffee & Tea', osake: false },
  { dbKey: 'mocktails', displayName: 'Mocktails', osake: false },
  { dbKey: 'beer_wine', displayName: 'Beer & Wine', osake: true },
  { dbKey: 'ready_to_drink', displayName: 'Ready to Drink', osake: true },
  { dbKey: 'cocktails', displayName: 'Cocktails', osake: true },
  { dbKey: 'spirits', displayName: 'Spirits', osake: true },
];

export const drinkSubcategory = (dbKey) => {
  return drinkSubcategories.filter((c) => c.dbKey === dbKey)[0];
};

export const setMenuSubcategory = {
  anytime: { dbKey: 'anytime', displayName: 'Anytime' },
  lunch: { dbKey: 'lunch', displayName: 'Lunch' },
  dinner: { dbKey: 'dinner', displayName: 'Dinner' },
  party: { dbKey: 'party', displayName: 'Party' },
};
export const setMenuSubcategories = [
  setMenuSubcategory.anytime,
  setMenuSubcategory.lunch,
  setMenuSubcategory.dinner,
  setMenuSubcategory.party,
];

export const menuCategories = {
  appetizers: {
    dbKey: 'appetizers',
    displayName: 'Appetizers',
    subcategories: [{ dbKey: 'appetizers', displayName: 'Appetizers' }],
  },

  curry: {
    dbKey: 'curry',
    displayName: 'Curry',
    subcategories: [
      { dbKey: 'veggie', displayName: 'Veggie' },

      { dbKey: 'meat', displayName: 'Meat' },
      { dbKey: 'seafood', displayName: 'Seafood' },
    ],
  },
  main_other: {
    dbKey: 'main_other',
    displayName: 'Other Main',
    subcategories: [
      { dbKey: 'main', displayName: 'Main' },
      { dbKey: 'kids', displayName: 'Kids' },
    ],
  },

  rice_roti: {
    dbKey: 'rice_roti',
    displayName: 'Rice & Roti',
    subcategories: [
      { dbKey: 'rice', displayName: 'Rice' },
      { dbKey: 'roti', displayName: 'Roti' },
    ],
  },
  set_menu: {
    dbKey: 'set_menu',
    displayName: 'Set Menu',
    subcategories: setMenuSubcategories,
  },
  south_indian: {
    dbKey: 'south_indian',
    displayName: 'South Indian',
    subcategories: [{ dbKey: 'south_indian', displayName: 'South Indian' }],
    sm: true,
  },
  drinks: {
    dbKey: 'drinks',
    displayName: 'Drink/Sweet',
    subcategories: drinkSubcategories,
    sm: true,
  },
};

export const allmenuCategories = Object.entries(menuCategories).map(([k, v]) => v);

export const alcSubcategories = ['cocktails', 'spirits', 'beer_wine', 'ready_to_drink'];
export const nonFood = ['drinks', 'desserts'];

export const defItem = (isSet) => {
  return {
    nameEng: '',
    descriptionEng: '',
    nameThai: '',
    descriptionThai: '',
    img: '',
    price: '',
    category: isSet ? 'set_menu' : menuCategories.appetizers.dbKey,
    subcategory: isSet ? 'lunch' : menuCategories.appetizers.subcategories[0].dbKey,
    availableOnline: true,
    availableForDelivery: true,
    dineinOnly: false,
    ...(isSet && { items: [] }),
    ...(!isSet && { vegan: false, veggie: false, seafood: false, meat: false, spiciness: 0 }),
  };
};

export const dietaryOption = {
  meat: {
    key: 'meat',
    displayName: {
      en: 'Meat',
      th: 'เนื้อสัตว์',
    },
  },
  seafood: {
    key: 'seafood',
    displayName: {
      en: 'Seafood',
      th: 'ทะเล',
    },
  },
  vegan: {
    key: 'vegan',
    displayName: {
      en: 'Vegan',
      th: 'วีแกน',
    },
  },
  veggie: {
    key: 'veggie',
    displayName: {
      en: 'Vegetarian',
      th: 'มังสวิรัติ',
    },
  },
};
