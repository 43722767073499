import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone';


const useTime = (updateInterval = 1000, syncInterval = 300000) => {

    const [time, setTime] = useState(moment().tz("Asia/Bangkok"))
    const timeInterval = useRef();
    const syncIntervalId = useRef();

    useEffect(() => {
        fetchTime();

        // Sync with the server every 5 minutes
        syncIntervalId.current = setInterval(fetchTime, syncInterval);

        // Update time locally every second
        timeInterval.current = setInterval(() => {
            setTime(prev => {
                return prev.add(updateInterval, 'milliseconds');
            })
        }, updateInterval);

        return () => {
            clearInterval(timeInterval.current)
            clearInterval(syncIntervalId.current)
        }
    }, [])

    const fetchTime = async () => {
        const { data } = await axios.get(`https://worldtimeapi.org/api/timezone/Asia/Bangkok`);
        if (data && data.datetime) {
            const date = moment(data.datetime)
            setTime(date)
        }
    }

    return { time }
}

export default useTime