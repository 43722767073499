export const usr = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAIL: 'USER_LOGIN_FAIL',
  LOGOUT: 'USER_LOGOUT',
  DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
  DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
  DETAILS_FAIL: 'USER_DETAILS_FAIL',
  DETAILS_RESET: 'USER_DETAILS_RESET',
  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAIL: 'USER_UPDATE_FAIL',
  UPDATE_RESET: 'USER_UPDATE_RESET',
  LIST_REQUEST: 'USER_LIST_REQUEST',
  LIST_SUCCESS: 'USER_LIST_SUCCESS',
  LIST_FAIL: 'USER_LIST_FAIL',
  LIST_RESET: 'USER_LIST_RESET',
  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  DELETE_FAIL: 'USER_DELETE_FAIL',
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAIL: 'USER_REGISTER_FAIL',
  REGISTER_RESET: 'USER_REGISTER_RESET',
  UPDATE_LOCAL: 'USER_UPDATE_LOCAL',
};

export const str = {
  FETCH_REQUEST: 'FETCH_STORE_REQUEST',
  FETCH_SUCCESS: 'FETCH_STORE_SUCCESS',
  FETCH_FAIL: 'FETCH_STORE_FAIL',
};

export const crt = {
  ADD_ITEM: 'CART_ADD_ITEM',
  REMOVE_ITEM: 'CART_REMOVE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  RESET: 'CART_RESET',
  TOGGLE: 'CART_TOGGLE',
  SET_NOTES: 'CART_SET_NOTES',
};

export const ui = {
  LANGUAGE_SET: 'LANGUAGE_SET',
  TOGGLE_IMAGE_CAROUSEL: 'TOGGLE_IMAGE_CAROUSEL',
  TOGGLE_HMENU: 'TOGGLE_HAMBURGER_MENU',
  TOGGLE_PP: 'TOGGLE_PP',
  CLOSE_HMENU: 'CLOSE_HAMBURGER_MENU',
  SET_NAV_HEIGHT: 'SET_NAV_HEIGHT',
  SET_ALERT: 'SET_ALERT',
};

export const lgn = {
  TOGGLE: 'LOGIN_SCREEN_TOGGLE',
};

export const schd = {
  SET_SCHEDULE: 'SET_SCHEDULE',
};

export const pp = {
  SET_PP: 'SET_PP',
};
