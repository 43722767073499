export const formatPhone = (text) => {
  let cleaned = ('' + text).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return text;
};

export const formatAddress = ({
  street,
  village,
  tambon,
  amphur,
  province,
}) => {
  return `${street}, ${
    village && 'M. ' + village
  }, ${tambon}, ${amphur}, ${province}`;
};

export const formatGoogleMapsLink = ({
  street,
  village,
  tambon,
  amphur,
  province,
  coordinates,
  placeName,
}) => {
  if (coordinates && coordinates.length === 2) {
    return `https://www.google.com/maps/search/?api=1&query=${coordinates[0]},${coordinates[1]}`;
  } else if (placeName) {
    return `https://www.google.com/maps/search/?api=1&query=${placeName} ${province}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${street} ${tambon} ${amphur} ${province}`;
};
