import React from 'react'


const BlockButton = ({ title, arrow, onClick, height = '8.2rem', width = '40rem', fontSize = 3, disabled, type = 'button' }) => {
    return (
        <button type={type} className='block-button' disabled={disabled} onClick={onClick} style={{ height: `${height}`, width: `${width}`, fontSize: `${fontSize}rem` }}>
            {arrow === 'left' && <i className='icon-arrow_left chevron chevron__left' />}
            {title}
            {arrow === 'right' && <i className='icon-arrow_right chevron chevron__right' />}
        </button>
    )
}


export default BlockButton