import { crt } from '../../constants/reduxConstants';

export const addToCart = (item, qty) => async (dispatch, getState) => {
  dispatch({
    type: crt.ADD_ITEM,
    payload: { ...item, amount: qty },
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: crt.REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const changeItemAmt = (item, qty) => async (dispatch, getState) => {
  dispatch({
    type: crt.UPDATE_ITEM,
    payload: { ...item, amount: qty },
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const editNotes = (item, notes) => async (dispatch, getState) => {
  dispatch({
    type: crt.UPDATE_ITEM,
    payload: { ...item, notes },
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const setCartNotes = (notes) => async (dispatch, getState) => {
  dispatch({
    type: crt.SET_NOTES,
    payload: notes,
  });

  localStorage.setItem('cartNotes', getState().cart.notes);
};

export const resetCart = () => async (dispatch, getState) => {
  dispatch({
    type: crt.RESET,
  });

  localStorage.setItem('cartItems', '[]');
  localStorage.setItem('cartNotes', '');
};
