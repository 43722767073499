import useOnScreen from "./use-on-screen";
import { useState, useEffect } from "react";

const useHasAppeared = (ref, offset = 0) => {
    const onScreen = useOnScreen(ref);
    const [intersectPoint, setIntersectPoint] = useState(null)
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {

        const scrollHandler = () => {
            if (window.pageYOffset >= intersectPoint + offset) {

                setIsFinished(true)
                window.removeEventListener('scroll', scrollHandler)
            }
        }
        if (onScreen) {
            if (offset === 0) {
                setIsFinished(true);
            } else {
                setIntersectPoint(window.pageYOffset)
                window.addEventListener('scroll', scrollHandler)
            }
        }
        return () => { window.removeEventListener('scroll', scrollHandler) }
    }, [onScreen])

    return isFinished;
}


export default useHasAppeared;