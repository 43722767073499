import { useDispatch, useSelector } from "react-redux";
import { crt, ui } from "../../constants/reduxConstants";
import { getStoreInfo } from "../../store/actions/storeActions";
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll";
import { Transition } from "react-transition-group";
import { useEffect } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


const CartButton = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const { cartItems, showing } = useSelector(state => state.cart)

    const sp = useMediaQuery();
    useDisableBodyScroll(showing)

    useEffect(() => {
        if (showing) {
            dispatch(getStoreInfo())
        }
    }, [showing])

    const handleClick = () => {
        dispatch({ type: ui.CLOSE_HMENU })
        if (sp) {
            const redirect = searchParams.get('redirect')
            const locationPath = location.pathname
            if (locationPath === '/cart' || locationPath === '/cart/') {
                navigate(redirect ?? '/')
            } else {
                navigate(`/cart?redirect=${location.pathname}`)
            }

        } else {
            dispatch({ type: crt.TOGGLE })
        }


    }

    const badge = <div className='cart-button__badge'><p>{cartItems.length}</p></div>
    const btn = <button className='cart-button' onClick={handleClick}>
        {/* <div className="cart-button__bkg" /> */}
        <Transition mountOnEnter unmountOnExit in={showing} timeout={500}>
            {state => {
                return !sp && <div className={`close-button${state === 'exiting' || state === 'exited' ? ' hidden' : state === 'entering' ? ' showing' : ''}`} ></div>
            }}
        </Transition>
        {!sp && <Transition mountOnEnter unmountOnExit in={!showing} timeout={500}>
            {state => {
                return <div className="cart-button__img" style={{ transition: state === 'exiting' ? 'none' : 'all 0.5s ease-in', opacity: state === 'entered' ? 1 : 0 }}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/cart/cart_icon.png'} alt="cart" />
                    {badge}
                </div>
            }}

        </Transition>}
        {sp && <><div className="cart-button__img" >
            <img src={process.env.PUBLIC_URL + '/assets/images/cart/cart_icon.png'} alt="cart" />
        </div>
            {badge}
        </>}
    </button>

    return <>
        <div className={`cart-backdrop${showing && !sp ? ' expanded' : ''}`} ></div>
        {sp && <div className={`cart-backdrop`} style={{ zIndex: 9999999, ...(showing && { backgroundColor: 'transparent' }) }} >
            {btn}
        </div>}

        {!sp && btn}

    </>
}

export default CartButton;