import React, { useState } from 'react'

import { useNavigate } from 'react-router'
import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import SplashSection from '../SplashSection'
import ReserveModal from './ReserveModal'
import DescText from '../../typography/DescText'




const CateringSection = () => {
    const navigate = useNavigate()
    const [showingForm, setShowingForm] = useState(false)
    const sp = useMediaQuery();
    const { txt } = useLanguage()

    return (
        <section className='catering'>
            <ReserveModal showing={showingForm} onClose={() => setShowingForm(false)} />

            <SplashSection
                title='Events/Catering'
                subtitle={`Quinn's will make your big day woderful`}
                imgName='restaurant/catering.jpg'
                height={85}
                btmWrapper='catering__content'
                rightOffset={sp ? 45 : 35}
                bottomOffset={sp ? 0 : 35}
                bkgSize={sp ? '190%' : '100%'}
            >
                <div className='booking-card'>
                    <DescText className='booking-card__txt'>{txt.DESCRIPTION_CATERING}</DescText>
                    <button onClick={() => setShowingForm(true)} className='booking-card__btn'>BOOK NOW</button>
                </div>
            </SplashSection>
        </section>
    )
}

export default CateringSection