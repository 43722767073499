import { useState, useEffect } from "react";

export default function useOnScreen(ref, log) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (log) { console.log('setting observer') }
      setIntersecting(entry.isIntersecting)
    }
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}