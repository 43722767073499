import React from 'react'
import { useDispatch } from 'react-redux'
import useTogglePrivacyPolicy from '../../hooks/useTogglePrivacyPolicy'
import { pp } from '../../constants/reduxConstants'


const PrivacyConsent = () => {
  const togglePP = useTogglePrivacyPolicy()
  const dispatch = useDispatch()

  const accept = (e) => {
    e.preventDefault()
    dispatch({ type: pp.SET_PP })
  }

  return (
    <div className='privacy-consent'>
      <p>This website uses cookies to improve user experience. Please click accept to continue using this site. For details, please see our <a onClick={togglePP}>privacy policy</a>.</p>
      <a className='privacy-consent__accept' onClick={accept} ><span>accept/ยอมรับ</span><span>✖︎</span></a>
    </div>
  )
}

export default PrivacyConsent
