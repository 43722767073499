import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useFormatter from '../../hooks/useFormatter'
import useLanguage from '../../hooks/use-language'
import CustomRadioButton from '../UI/CustomRadioButton'
import CustomCheckbox from '../UI/CustomCheckbox'
import AddressForm from '../forms/AddressForm'
import { useEffect } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'



const AddressSection = ({ currentAddress, setAddress, saveAddress, setSaveAddress }) => {
    const { txt } = useLanguage()
    const { userInfo } = useSelector(state => state.userLogin)
    const current = userInfo && userInfo.addresses.findIndex(a => a._id === currentAddress._id)
    const [selected, setSelected] = useState(current > -1 ? current : userInfo && userInfo.addresses.length > 0 ? 0 : null)
    const { formatAddress } = useFormatter()
    const sp = useMediaQuery()
    const [newAddress, setNewAddress] = useState({
        street: '',
        tambon: '400101',
        placeName: '',
        village: '',
        directions: '',
        amphur: 'Mueang Khon Kaen',
        province: 'Khon Kaen'
    })

    useEffect(() => {
        addressChangeHandler()

    }, [newAddress, selected])

    const addressChangeHandler = () => {
        if (selected !== null) {
            setAddress(userInfo.addresses[selected])
        } else {
            setAddress(newAddress)
        }
    }

    const radioSize = sp ? 3 : 2.2

    return (
        <div className='address-section'>
            <h2>Delivery Address</h2>
            {userInfo && userInfo.addresses.map((a, i) => {
                return <CustomRadioButton size={radioSize} key={i} checked={selected === i} onChange={() => setSelected(i)} label={formatAddress(a)} />
            })}
            {userInfo && <CustomRadioButton size={radioSize} checked={selected === null} onChange={() => setSelected(null)} label={txt.LABEL_NEW_ADDRESS} />}
            {selected === null && <>
                <AddressForm newAddress={newAddress} setNewAddress={setNewAddress} />
                {userInfo && <CustomCheckbox label={txt.LABEL_SAVE_ADDRESS} checked={saveAddress} onChange={(e) => { setSaveAddress(e.target.checked) }} />}
            </>}
        </div>
    )
}

export default AddressSection