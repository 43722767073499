import { usr } from '../../constants/reduxConstants';

export const userLoginReducer = (state = null, action) => {
  switch (action.type) {
    case usr.LOGIN_REQUEST:
      return { loading: true };
    case usr.LOGIN_SUCCESS:
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      return { loading: false, userInfo: action.payload };
    case usr.LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case usr.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case usr.DETAILS_REQUEST:
      return { ...state, loading: true };
    case usr.DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case usr.DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case usr.DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case usr.UPDATE_REQUEST:
      return { loading: true };
    case usr.UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case usr.UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case usr.UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case usr.LIST_REQUEST:
      return { ...state, loading: true };
    case usr.LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case usr.LIST_FAIL:
      return { loading: false, error: action.payload };
    case usr.DETAILS_RESET:
      return { user: { users: [] } };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case usr.DELETE_REQUEST:
      return { loading: true };
    case usr.DELETE_SUCCESS:
      return { loading: false, success: true };
    case usr.DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case usr.REGISTER_REQUEST:
      return { loading: true };
    case usr.REGISTER_SUCCESS:
      return { loading: false, success: true };
    case usr.REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case usr.REGISTER_RESET:
      return {};
    default:
      return state;
  }
};
