import React from 'react'
import { useSelector } from 'react-redux'
import useFormatter from '../../../hooks/useFormatter'
import useLanguage from '../../../hooks/use-language'
import AnimateIn from '../../UI/AnimateIn'
import { IMG_PATH, MAP_HTML } from '../../../constants/conveniences'



const LocationSection = () => {

    const { formatAddress, formatPhone } = useFormatter()
    const { storeInfo } = useSelector(state => state.storeInfo)
    const { txt, lang } = useLanguage();
    return (
        <section className='section-restaurant-location'>
            <div className='rlocation'>
                <AnimateIn direction='right'>
                    <h2 className='rlocation__header'>Location</h2>
                </AnimateIn>
                <AnimateIn direction='right'>
                    <h3 className='rlocation__subhead--1'>{`Quinn's One & Only`}</h3>
                </AnimateIn>

                {storeInfo && <AnimateIn direction='right'>
                    <p className={`rlocation__address ${lang}`}>{storeInfo[lang === 'en' ? 'address_eng' : 'address_thai']}</p>
                </AnimateIn>}

                <div className='rlocation__imgs'>
                    <AnimateIn direction='right'>
                        <h3 className='rlocation__subhead--2'>Parking lot</h3>
                    </AnimateIn>
                    <img className='rlocation__map' src={IMG_PATH + 'restaurant/map.jpg'} alt="" />
                    <img className='rlocation__img' src={IMG_PATH + 'restaurant/parking.jpg'} alt="" />

                </div>
            </div>
            <div className='restaurant-map'>
                <div style={{ height: '100%' }} dangerouslySetInnerHTML={MAP_HTML} />
            </div>


        </section>
    )
}

export default LocationSection