import React from 'react'
import SectionHeader from '../components/layout/SectionHeader'
import Owner from '../components/layout/about/Owner'
import Staff from '../components/layout/about/Staff'
import Chef from '../components/layout/about/Chef'
import ImagePatchwork from '../components/layout/about/ImagePatchwork'
import Roots from '../components/layout/about/Roots'
import useMediaQuery from '../hooks/useMediaQuery'


const AboutScreen = () => {
    const sp = useMediaQuery();
    return (
        <main style={{ overflowX: 'hidden' }}>
            <SectionHeader imgName='about/roast.jpg' title='About Us' rightOffset={sp ? 100 : 35} bottomOffset={sp ? 30 : 35} bkgSize={sp ? '140%' : '100%'} />
            <div className='about-body page-body'>
                <div className='about-body__bkg'></div>
                <Owner />
                {
                    sp ? <><Staff />
                        <Chef /></> : <ImagePatchwork />
                }


            </div>
            <Roots />
        </main>
    )
}

export default AboutScreen