import React from 'react'
// import classes from './CustomCheckbox.module.css'

const CustomCheckbox = ({ label, checked, onChange, className, required, disabled = false }) => {

    return (
        <div className="checkbox">
            <input name={label} id={label} type="checkbox" checked={checked} onChange={onChange} required={required} disabled={disabled} />
            <label className={`${className ? ` ${className}` : ''}`} htmlFor={label}>{label}</label>
        </div>
    )
}

export default CustomCheckbox