const dir = {
  ADMIN_USERS: 'admin/users/',
  CUSTOMER_USERS: 'customers/',
  MENU_ITEMS: 'menu/items/',
  MENU_SOLD_OUT: 'menu/items/sold-out/',
  ORDERS: 'orders/',
  ORDERS_LISTEN: 'orders/listen/',
  NEWS: 'news/',
  STORE: 'store/',
  RESERVATIONS: 'reservations/',
  PROMOTIONS: 'promotions/',
  RESOURCES: 'resources/',
  USER_PROFILE: 'customers/profile',
  USER_PASSWORD: 'customers/password',
  USER_RESET_PASSWORD: 'customers/reset-password',
  USER_CHECK: 'customers/check',
  USER_RESET_PASSWORD_CHECK: 'customers/reset-password/check',
};

export default dir;
