import axios from "axios";
import { useCallback, useState } from "react";


const useFormUploadRequest = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState(null);


    const performFormUploadRequest = useCallback(async (object, files, dir, token) => {

        try {
            setError(null)
            setLoading(true)
            setSuccess(false)

            const formData = new FormData();
            const oKeys = Object.keys(object);
            oKeys.forEach((key) => {
                var val = object[key]

                if (val instanceof Object) {
                    val = JSON.stringify(val);
                }
                formData.append(key, val)
            }
            )

            const fKeys = Object.keys(files)

            fKeys.forEach((key) => {
                formData.append(key, files[key])
            })

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            }

            const { data } = await axios.post('/api/' + dir, formData, config)
            console.log("returning data")
            setData(data)
            setSuccess(true)
            setLoading(false)

        } catch (error) {


            setLoading(false)
            setError(error.response.data)
        }

    })

    const clear = () => {
        setError(null)
        setLoading(false)
        setData(null)
        setSuccess(false)
    }

    return { loading, error, success, data, clear, performFormUploadRequest }

}

export default useFormUploadRequest