import { ui } from '../../constants/reduxConstants';

export const uiReducer = (
  state = {
    language: 'en',
    loginShowing: false,
    imgShowing: false,
    hamShowing: false,
    ppShowing: false,
    navHeight: 0,
    alert: null,
  },
  action
) => {
  switch (action.type) {
    case ui.LANGUAGE_SET:
      return {
        ...state,
        language: action.payload,
      };
    case ui.TOGGLE_IMAGE_CAROUSEL:
      const showing = !state.imgShowing;
      return {
        ...state,
        imgShowing: showing,
      };
    case ui.TOGGLE_HMENU:
      const hShowing = !state.hamShowing;
      return {
        ...state,
        hamShowing: hShowing,
      };
    case ui.TOGGLE_PP:
      const pShowing = !state.ppShowing;
      return {
        ...state,
        ppShowing: pShowing,
      };
    case ui.CLOSE_HMENU:
      return {
        ...state,
        hamShowing: false,
      };
    case ui.SET_NAV_HEIGHT:
      return {
        ...state,
        navHeight: action.payload,
      };
    case ui.SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };

    default:
      return state;
  }
};
