import React from 'react'

import useLanguage from '../../../hooks/use-language'
import useCreateRequest from '../../../hooks/api/useCreateRequest';
import { useEffect } from 'react';
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import usePasswordValidator from '../../../hooks/use-password-validator';
import useErrorHandler from '../../../hooks/use-error-handler';

import BlockButton from '../../buttons/BlockButton';
import CustomCheckbox from '../../UI/CustomCheckbox';
import ErrorMessage from '../../typography/ErrorMessage';
import Loader from '../../UI/Loader';
import ModalContainer from '../../UI/ModalContainer';
import SignupContactRow from './SignupContactRow';
import { showAlert } from '../../../store/actions/uiActions';


import { toggleLogin } from '../../../store/actions/loginScreenActions';
import { userLogin } from '../../../store/actions/userActions';

import dir from '../../../constants/directoryConstants'
import { usr } from '../../../constants/reduxConstants';
import useMediaQuery from '../../../hooks/useMediaQuery';


const LoginModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const sp = useMediaQuery()
    const { txt } = useLanguage()

    const { loading: loadingSignup, error: errorSignup, data, performCreateRequest, clear } = useCreateRequest();
    const { loading: loadingPasswordReq, error: errorPasswordReq, data: dataPasswordReq, performCreateRequest: performPasswordReq, clear: clearPasswordReq } = useCreateRequest();
    const { getErrorMessage } = useErrorHandler()

    const { userInfo, error: errorLogin, loading: loadingLogin } = useSelector(state => state.userLogin)
    const { showing: loginShowing, showGuest, onLogin } = useSelector(state => state.loginScreen)
    const loading = loadingSignup || loadingPasswordReq || loadingLogin
    const [errorMessage, setErrorMessage] = useState(null)

    const [username, setUsername] = useState('')
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        tel: '',
        receivesPromotions: true,
    })

    const [isSignup, setIsSignup] = useState(false)
    const [isForgotPassword, setIsForgotPassword] = useState(false)

    const { pwError, pwForm, checkPW, password, reset: resetPasswordFields } = usePasswordValidator(!isSignup);

    useEffect(() => {
        if (data) {
            dispatch({ type: usr.LOGIN_SUCCESS, payload: data })
            closeAndClear()
            navigate('/profile')
        } else if (userInfo && userInfo._id) {
            onLogin()
            closeAndClear()
        }

        if (errorPasswordReq) {
            setErrorMessage(getErrorMessage(errorPasswordReq))
        } else if (errorSignup) {
            setErrorMessage(getErrorMessage(errorSignup))
        } else if (errorLogin) {
            setErrorMessage(getErrorMessage(errorLogin))
        }

        if (dataPasswordReq) {
            clearPasswordReq();
            alert(txt.ALERT_PW_LINK_SENT + ' ' + dataPasswordReq.email)
            closeAndClear()
        }
    }, [data, errorSignup, userInfo, errorLogin, dataPasswordReq, errorPasswordReq])

    useEffect(() => {
        if (pwError) setErrorMessage(pwError)
    }, [pwError])

    const setUserProp = (e, key) => {
        setNewUser(pVal => {
            return {
                ...pVal,
                [key]: e.target.value
            }
        })
    }

    //*HANDLE SUBMISSION

    const submitHandler = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }

        if (isForgotPassword) {
            performPasswordReq(
                { username }, dir.USER_RESET_PASSWORD
            )
            return
        }
        if (isSignup && validateSignupForm()) {
            performCreateRequest({ ...newUser, password }, dir.CUSTOMER_USERS)
        } else if (!isSignup) {

            dispatch(userLogin(username, password))
        }
    }

    const validateSignupForm = () => {

        if (!checkPW()) {
            window.alert(txt.ERROR_PASSWORD_INVALID)
            // setErrorMessage(pwError ?? "password error")
            return false;
        }

        if (newUser.tel.length !== 10) {
            window.alert((txt.ERROR_INVALID_TEL))
            // setErrorMessage(txt.ERROR_INVALID_TEL)
            return false
        }

        setErrorMessage(null)
        return true
    }

    const closeAndClear = () => {
        dispatch(toggleLogin(showGuest))
        clear()
        setTimeout(() => {
            resetForm()
        }, 1000)
    }

    const guestLoginHandler = () => {
        onLogin()
        closeAndClear()
    }


    //*TOGGLE/RESET FORM
    const toggleSignup = (e) => {
        setErrorMessage(null)
        if (isForgotPassword) {
            setIsForgotPassword(false)
            return
        }
        setIsSignup(pVal => !pVal)
    }


    const resetForm = () => {
        setUsername('')
        setIsSignup(false)
        setIsForgotPassword(false)
        setNewUser({ name: '', tel: '', email: '' })
        resetPasswordFields()
        setErrorMessage(null)
    }

    //* CARD DIMENSIONS:

    const topButtonHeight = sp ? '10rem' : '6.5rem'
    const guestHeight = showGuest ? sp ? 14 : 7 : 0
    const expandedHeight = isSignup ? sp ? 16 : 7 : 0
    const contractedHeight = isForgotPassword ? -10 : 0
    const expandedWidth = isSignup ? 18 : 0

    return (
        <ModalContainer showing={!userInfo && loginShowing} onClose={closeAndClear}  >
            <div
                className={`login-card${showGuest ? ' guest' : ''}${isSignup ? ' expanded' : ''}`}
                style={{
                    height: `${(sp ? 76 : 51) + guestHeight + expandedHeight + contractedHeight}rem`,
                    width: sp ? 'calc(100vw - 11rem)' : `${40 + expandedWidth}rem`
                }}
            >

                <h1 className='login-card__header'>{isSignup ? txt.BUTTON_SIGNUP : isForgotPassword ? txt.LABEL_FORGOT_PASSWORD : txt.LABEL_LOGIN}</h1>
                <form onSubmit={submitHandler}>
                    {isSignup ? <>
                        <input className='form__field'
                            value={newUser.name}
                            type="name"
                            onChange={e => setUserProp(e, 'name')}
                            placeholder={txt.PLACEHOLDER_NAME}
                            required />
                        <SignupContactRow setUserProp={setUserProp} newUser={newUser} />
                        <div className='signup-row'>
                            {pwForm}
                        </div>
                        <CustomCheckbox label={txt.LABEL_PROMOTIONS_OPT_IN} checked={newUser.receivesPromotions} onChange={e => setNewUser(pVal => ({ ...pVal, receivesPromotions: e.target.checked }))} />
                    </> :
                        <>
                            <input className='form__field' value={username} type="username" onChange={e => setUsername(e.target.value)} placeholder={txt.PLACEHOLDER_USERNAME} required />
                            {!isForgotPassword &&
                                <>
                                    {pwForm}
                                    <div className='login-card__pwd-wpr' >
                                        <p onClick={e => setIsForgotPassword(true)} className='login-card__pwd-lnk'>{txt.LINK_FORGOT_PASSWORD}</p>
                                    </div>
                                </>
                            }

                        </>
                    }

                    <div className="login-card__err-msg">
                        {errorMessage && <ErrorMessage error={errorMessage} />}
                    </div>
                    <div className="login-card__btns">
                        <div className='login-btn-wrapper'>
                            {loading ? <Loader color='black' size={40} marginTop={0} height={topButtonHeight} /> : <BlockButton type='submit' height={sp ? '10rem' : '6.5rem'} width={sp ? '26rem' : '16rem'} title={isSignup ? txt.BUTTON_CONFIRM_SIGNUP : isForgotPassword ? txt.BUTTON_SEND_PASSWORD : txt.BUTTON_LOGIN} fontSize={sp ? 3 : 2} />}
                        </div>


                        <div className='signup-btn-wrapper'>
                            <BlockButton
                                height={sp ? '10rem' : '6.5rem'}
                                width={sp ? 'calc(100% - 3rem)' : '33rem'}
                                title={isSignup ? txt.BUTTON_SIGNIN : isForgotPassword ? txt.BUTTON_BACK_SIGNIN : txt.BUTTON_SIGNUP}
                                onClick={toggleSignup}
                                fontSize={sp ? 3 : 2} />
                        </div>

                        {showGuest && <div className='signup-btn-wrapper'>
                            <BlockButton
                                height={sp ? '10rem' : '6.5rem'}
                                width={sp ? 'calc(100% - 3rem)' : '33rem'}
                                title={txt.BUTTON_GUEST}
                                onClick={guestLoginHandler}
                                fontSize={sp ? 3 : 2} />
                        </div>}
                    </div>
                </form>
            </div>
        </ModalContainer>

    )
}

export default LoginModal