import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import ConditionalWrapper from '../../convenience/ConditionalWrapper'
import DescText from '../../typography/DescText'
import AnimateIn from '../../UI/AnimateIn'

import { IMG_PATH } from '../../../constants/conveniences'

const Chef = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return (
        <section className='chef'>
            <ConditionalWrapper condition={sp} wrapper={children => <div className='chef__sp-top'>{children}</div>}>
                {sp && <img className='chef__img--sp' src={IMG_PATH + 'about/toss.jpg'} alt="" />}
                <div className='chef__head'>
                    <AnimateIn offsetAmt={100}>
                        <h2 className='about-title chef__title' >Chef</h2>
                    </AnimateIn>
                    <img className='chef__img' src={IMG_PATH + '/about/chef.png'} alt="our chef" />
                </div>
            </ConditionalWrapper>

            <AnimateIn offsetAmt={100} direction={sp ? 'up' : 'left'}>
                <DescText className='chef__desc'>{txt.DESCRIPTION_CHEF}</DescText>
            </AnimateIn>

        </section>
    )
}

export default Chef