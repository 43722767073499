import { useRef } from "react";

import useContainerDimensions from "../../../hooks/use-dimensions";
import { useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";

import { menuCategories } from "common/constants/menuConstants";




export const MenuCategoryLink = ({ category, shrink, disableAnimation, hideOnShrink = false, onClick = () => { } }) => {
    const ref = useRef(null)
    const location = useLocation()
    const dimensions = useContainerDimensions(ref, 'mencatlink');

    return <NavLink to={`/menu/${category.dbKey}`} className='menu-category' onClick={location.pathname.includes(`/menu/${category.dbKey}`) ? () => { } : onClick} >
        {!(shrink && hideOnShrink) && <h1 className={`menu-category__txt${category.sm ? ' menu-category__txt--sm' : ''}`}>{category.displayName}</h1>}
        <div ref={ref} className='menu-category__img-container' style={{ height: `${shrink ? 0 : dimensions.width}px`, ...(disableAnimation && { transition: 'none' }) }}>
            <img className='menu-category__img' src={process.env.PUBLIC_URL + '/assets/images/menu/' + category.dbKey + '.jpg'} alt={category.displayName} />
        </div>
    </NavLink>
}

const MenuCategorySection = ({ isSticky, disableAnimation }) => {
    const ref = useRef(null)

    const categories = [menuCategories.appetizers, menuCategories.curry, menuCategories.main_other, menuCategories.rice_roti, menuCategories.set_menu, menuCategories.south_indian, menuCategories.drinks]

    return <div ref={ref} className={`section-menu-categories${isSticky ? ' sticky' : ''}`} style={{ zIndex: '100' }}>
        {categories.map(c => (<div className='col-1-of-7' key={c.dbKey} ><MenuCategoryLink shrink={isSticky} category={c}></MenuCategoryLink></div>))}
    </div>
}

export default MenuCategorySection;