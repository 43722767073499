import { useEffect, useState } from 'react'
import useCountdown from '../../hooks/use-countdown';
import { Fragment } from 'react';
import CProgressIndicator from './CProgressIndicator';
import { IMG_PATH } from '../../constants/conveniences';





const Carousel = props => {

    const [selectedImg, setSelectedImg] = useState(0);
    const [isOdd, setIsOdd] = useState(false)
    const lastSelected = selectedImg > 0 ? selectedImg - 1 : props.images.length - 1;


    const css = (index, image) => {
        if ((index % 2 === 0) === isOdd) {
            return { backgroundImage: `url(../assets/images/carousel/${image}`, left: 0 }
        } else {

            return { backgroundImage: `url(../assets/images/carousel/${image}`, left: 0 }
        }
    }

    useEffect(() => {
        if (props.images.length % 2 !== 0 && selectedImg === 0) {
            setIsOdd(pVal => !pVal);
        }
    }, [selectedImg, props.images])

    const images = props.images.map((e, i) => <div key={i} className={`carousel__img-wrapper${selectedImg === i ? ' selected' : ''}${lastSelected === i ? ' previous' : ''}`}  >
        <img className='carousel__image' src={process.env.PUBLIC_URL + '/assets/images/carousel/' + e} alt="" />
    </div>);


    const changeImage = () => {
        setSelectedImg(pVal => {
            return pVal < (images.length - 1) ? pVal + 1 : 0;
        });
    }

    const { percentage } = useCountdown(2700, changeImage, 10, true);


    return <Fragment>
        <div className='carousel'>
            <img src={IMG_PATH + 'carousel/pc_top_img1.jpg'} alt="" />
            {images}
        </div>
        <div className='carousel__progress-indicator'>

            <CProgressIndicator showNumbers={false} numSections={props.images.length} currentSection={selectedImg} progress={percentage} />

        </div>
    </Fragment>
}

export default Carousel;