

import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language';

import SplashSection from '../SplashSection';
import ReservationForm from '../../forms/ReservationForm';


const Reservation = () => {

    const sp = useMediaQuery()
    const { txt } = useLanguage()

    return <div className='res-section-wpr'>
        <SplashSection
            title='Reservation'
            subtitle='Book Your Table Below'
            txt={txt.DESCRIPTION_RESERVATION} imgName='home/inside-2.jpg'
            btmWrapper={'reservation-form-wrapper'}
            height={sp ? 150 : 105.5}
            rightOffset={sp ? 100 : 60}
            bkgSize={sp ? '170%' : '103%'}
            bottomOffset={-10}
            lgTitle
        >

            <ReservationForm />


        </SplashSection  >
    </div>

}

export default Reservation;