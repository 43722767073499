import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import DescText from '../../typography/DescText'
import AnimateIn from '../../UI/AnimateIn'
import { IMG_PATH } from '../../../constants/conveniences'

const ImagePatchwork = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()
    const staffDesc = <AnimateIn direction={sp ? 'up' : 'right'} offsetAmt={sp ? 40 : 300}><DescText className='img-pw-staff__desc'>{txt.DESCRIPTION_STAFF}</DescText></AnimateIn>
    const tossImg = <img className='img-pw__toss' src={IMG_PATH + 'about/toss.jpg'} alt="" />

    return (
        <section className="img-pw">


            <div className="img-pw-staff__sec-1">

                <img src={IMG_PATH + '/about/staff.png'} alt="our staff" />
                <div className="img-pw-staff__txt">
                    <AnimateIn direction='right' offsetAmt={sp ? 40 : 200} delay={300}>
                        <h2 className='about-title img-pw-staff__title'>Staff</h2>
                    </AnimateIn>
                    {staffDesc}
                </div>
            </div>
            {tossImg}

            <div className='img-pw-chef'>
                <div className="img-pw-chef__img-wrapper">
                    <img src={IMG_PATH + '/about/chef.png'} alt="our chef" />
                </div>
                <div className="img-pw-chef__txt">
                    <AnimateIn offsetAmt={100}>
                        <h2 className='about-title img-pw-chef__title' >Chef</h2>
                    </AnimateIn>
                    <AnimateIn offsetAmt={100} direction={sp ? 'up' : 'left'}>
                        <DescText className='img-pw-chef__desc'>{txt.DESCRIPTION_CHEF}</DescText>
                    </AnimateIn>
                </div>

            </div>
            <div className="container">
                <div className="white"></div>
                <div className="green"></div>
                <div className="red"></div>
            </div>
        </section>
    )
}

export default ImagePatchwork