import React from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import CustomRadioButton from '../UI/CustomRadioButton'

const CartRadio = ({ checked, onChange, labels, divider, children, selectedValue = null, disabled = [false, false] }) => {
    const sp = useMediaQuery()
    const radioSize = sp ? 3 : 2.2
    return (
        <div className='cart__radio'>
            {!selectedValue ? <>
                <div className='cart__radio-1'>
                    <CustomRadioButton checked={checked[0]} size={radioSize} onChange={onChange[0]} label={labels[0]} disabled={disabled[0]} />
                </div>
                {divider && <div className='cart__radio-divider' />}
                <div className={`cart__radio-2${children ? ' top' : ''}`}>
                    <CustomRadioButton size={radioSize} checked={checked[1]} onChange={onChange[1]} label={labels[1]} disabled={disabled[1]} />
                    {children && <div className='cart__radio-secondary'>{children}</div>}
                </div>
            </> : <p>{selectedValue}</p>}
        </div>
    )
}

export default CartRadio