import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFetchRequest from '../hooks/api/useFetchRequest'
import useLanguage from '../hooks/use-language'
import useUpdateRequest from '../hooks/api/useUpdateRequest'
import usePasswordValidator from '../hooks/use-password-validator'
import { useDispatch } from 'react-redux'
import useErrorFormatter from '../hooks/use-error-formatter'
import useErrorHandler from '../hooks/use-error-handler'

import { userLogin } from '../store/actions/userActions'

import Loader from '../components/UI/Loader'
import BlockButton from '../components/buttons/BlockButton'


import dir from '../constants/directoryConstants'


const PasswordResetScreen = () => {

    //HOOKS
    const dispatch = useDispatch()
    const { token } = useParams()
    const navigate = useNavigate()
    const { txt } = useLanguage()
    const formatError = useErrorFormatter()
    const { getErrorMessage } = useErrorHandler()
    //QUERIES
    const { loading: loadingCheck, error: errorCheck, data: dataCheck, performFetchRequest } = useFetchRequest()
    const { loading: loadingReset, error: errorReset, data: dataReset, performUpdateRequest, resetUpdateRequest } = useUpdateRequest()
    //STATE VARS

    const [error, setError] = useState(null)
    const { pwOK, pwError, pwForm, checkPW, password } = usePasswordValidator();

    useEffect(() => {
        if (token) {
            performFetchRequest('', dir.USER_RESET_PASSWORD_CHECK, token)
        } else {
            navigate('/')
        }

        if (errorReset) {
            setError(getErrorMessage(errorReset))
        } else if (pwError) {
            setError(pwError)
        } else {
            setError(null)
        }


    }, [token, pwError, errorReset])

    const resetHandler = (e) => {
        e.preventDefault();
        checkPW();
        if (loadingReset || !pwOK) { return }

        if (!pwOK || pwError) { return }

        performUpdateRequest({ newPassword: password }, dir.USER_RESET_PASSWORD, token, (data) => {
            dispatch(userLogin(data.email, password))
            setTimeout(() => {
                navigate('/')
            }, 3000)
        })
    }

    return (
        <main className='password-reset'>

            {(loadingCheck || loadingReset) && <Loader></Loader>}


            {dataCheck && !dataReset && !loadingReset && <>
                <h1 className='txt-header'>Password Reset/การกู้คืนรหัสผ่าน</h1>
                <h2 className='txt-subhead'>Please enter a new password for {dataCheck.email}</h2>
                <h2 className='txt-subhead'>โปรดกรอกรหัสผ่านใหม่สำหรับ {dataCheck.email}</h2>

                <form className='password-reset__form' onSubmit={resetHandler}>
                    {pwForm}
                    {error && <p className='password-reset__err-msg'>{error}</p>}
                    <BlockButton type='submit' title='Submit' />
                </form>
            </>}
            {errorCheck && <div className='password-reset__err-msg'>{txt.ERROR_RESET_PASSWORD_TIMEOUT}</div>}
            {errorReset && <div className='password-reset__err-msg'>{formatError(errorReset)}</div>}
            {dataReset && <h1>Password updated successfully! รหัสผ่านได้ถูกอัพเดทแล้ว!</h1>}
        </main>

    )
}

export default PasswordResetScreen