import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import ModalContainer from './ModalContainer'
import BlockButton from '../buttons/BlockButton'
import { ui } from '../../constants/reduxConstants'

const AlertModal = () => {
    const dispatch = useDispatch()
    const { alert } = useSelector(state => state.ui)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    useEffect(() => {
        if (alert && alert.message) setAlertMessage(alert.message)
        if (alert && alert.title) setAlertTitle(alert.title)

    }, [alert])

    return (
        <ModalContainer showing={alert !== null} >
            <div className='alert-modal'>
                <div className="alert-modal__txt">
                    <h1>{alertTitle}</h1>
                    <div className="alert-modal__message">
                        <p>{alertMessage}</p>
                    </div>
                </div>
                <BlockButton title='OK' onClick={() => dispatch({ type: ui.SET_ALERT, payload: null })} />
            </div>
        </ModalContainer>
    )
}

export default AlertModal