import React, { useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import useMediaQuery from './useMediaQuery'



const useREM = () => {
  const sp = useMediaQuery()

  const getNavHeight = useCallback(() => {
    if (!sp) return 0;
    return 12 * parseFloat(getComputedStyle(document.documentElement).fontSize);
  })

  const [navHeight, setNavHeight] = useState(getNavHeight)

  useEffect(() => {

    const changeHandler = () => {
      setNavHeight(getNavHeight)
    }

    window.addEventListener('resize', changeHandler)

    return () => window.removeEventListener('resize', changeHandler)
  }, [getNavHeight])



  return { getNavHeight, navHeight }
}

export default useREM