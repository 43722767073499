export const DEFAULT_STORE_INFO = {
  open_time: '09:20',
  close_time: '22:00',
  last_order: '21:45',
  last_order_site: '21:45',
  lunch_start: '11:15',
  lunch_end: '14:30',
  dinner_start: '17:00',
  dinner_end: '21:45',
  days_open: 'Mon-Sun',
  days_closed: 'Irregular',
  open: true,
  service_note: '',
  address_eng:
    '722 Moo 2, Mittrapab Rd., Ban Nong Kung, Sila, Khon Kaen, 40000',
  address_thai: '722 หมู่ที่ 2 อำเภอเมืองขอนแก่น ขอนแก่น 40000',
  directions_eng: "We have parking. It's on the left side of the restaurant",
  directions_thai: "We have parking. It's on the left side of the restaurant",
  tel: '0919942246',
  email: 'quinnrestaurants@gmail.com',
  map: 'https://g.page/QuinnsOneOnly?share',
  facebook: 'https://www.facebook.com/Quinnsoneonly/',
  line: 'https://page.line.me/awm7346s',
  instagram: 'https://www.instagram.com/quinnsoneonly/',
  tiktok: 'https://www.tiktok.com/@quinns_oneandonly',
  youtube: 'https://www.youtube.com/@QUINNSOneOnly',
  paymentQR: '/uploads/paymentQR-1659258968193.JPG',
  bank_name: 'Kasikorn',
  account_number: '1234567890',
  account_name: 'Quinn',
};
