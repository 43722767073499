import React from 'react'

import useMediaQuery from '../../../hooks/useMediaQuery'
import useLanguage from '../../../hooks/use-language'

import { SplashHeader } from '../SplashSection'
import AnimateIn from '../../UI/AnimateIn'
import ParallaxImage from '../../UI/ParallaxImage'
import DescText from '../../typography/DescText'
import { IMG_PATH } from '../../../constants/conveniences'





const Roots = () => {
    const sp = useMediaQuery()
    const { txt } = useLanguage()

    const imgNames = ['flowers', 'candle', 'umbrellas'];
    return (
        <section className='roots'>
            <div className='roots__bkg-wrapper'>
                <ParallaxImage bkgAttachment='local' multiplier={0.03} bkgSize={sp ? '200%' : '100%'} rightOffset={sp ? 54 : 35} bottomOffset={sp ? 80 : 100} imgName='about/mural.jpg' />
            </div>

            <SplashHeader title='Our Roots' subtitle='Delivering the taste of India directly to your table' />

            <div className='roots__images'>
                {imgNames.map((img, i) => {

                    return <AnimateIn key={i} delay={200 * i} direction={'up'} offsetAmt={200} startingOpacity={0}><img className='roots__img' src={IMG_PATH + `about/${img}.jpg`} alt="" /></AnimateIn>

                })}

            </div>

            <AnimateIn direction={'up'} offsetAmt={100} startingOpacity={0.5}>
                <DescText className='roots__txt'>{txt.DESCRIPTION_OUR_ROOTS}</DescText>

            </AnimateIn>
        </section>

    )
}

export default Roots