export const storeConsts = {
  open_time: { dbKey: 'open_time', displayName: 'Open', type: 'time' },
  close_time: { dbKey: 'close_time', displayName: 'Close', type: 'time' },
  last_order: { dbKey: 'last_order', displayName: 'Last Order', type: 'time' },
  days_open: { dbKey: 'days_open', displayName: 'Days Open', type: 'text' },
  days_closed: {
    dbKey: 'days_closed',
    displayName: 'Days Closed',
    type: 'text',
  },
  service_note: {
    dbKey: 'service_note',
    displayName: 'Service Message',
    type: 'text',
  },
  address_eng: {
    dbKey: 'address_eng',
    displayName: 'Address (Eng)',
    type: 'text',
  },
  address_thai: {
    dbKey: 'address_thai',
    displayName: 'Address (Thai)',
    type: 'text',
  },
  directions_eng: {
    dbKey: 'directions_eng',
    displayName: 'Directions (Eng)',
    type: 'text',
  },
  directions_thai: {
    dbKey: 'directions_thai',
    displayName: 'Directions (Thai)',
    type: 'text',
  },
  tel: { dbKey: 'tel', displayName: 'Telephone', type: 'tel' },
  email: { dbKey: 'email', displayName: 'Email', type: 'email' },
  map: { dbKey: 'map', displayName: 'Map Link', type: 'url' },
  facebook: { dbKey: 'facebook', displayName: 'Facebook Link', type: 'url' },
  line: { dbKey: 'line', displayName: 'Line Link', type: 'url' },
  instagram: { dbKey: 'instagram', displayName: 'Instagram Link', type: 'url' },
  tiktok: { dbKey: 'tiktok', displayName: 'TikTok Link', type: 'url' },
};

export const scheduleConsts = {
  open_time: { dbKey: 'open_time', displayName: 'Open', type: 'time' },
  close_time: { dbKey: 'close_time', displayName: 'Close', type: 'time' },
  last_order: { dbKey: 'last_order', displayName: 'Last Order', type: 'time' },
  days_open: { dbKey: 'days_open', displayName: 'Days Open', type: 'text' },
  days_closed: {
    dbKey: 'days_closed',
    displayName: 'Days Closed',
    type: 'text',
  },
  service_note: {
    dbKey: 'service_note',
    displayName: 'Service Message',
    type: 'text',
  },
};

export const locationConsts = {
  address_eng: {
    dbKey: 'address_eng',
    displayName: 'Address (Eng)',
    type: 'text',
  },
  address_thai: {
    dbKey: 'address_thai',
    displayName: 'Address (Thai)',
    type: 'text',
  },
  directions_eng: {
    dbKey: 'directions_eng',
    displayName: 'Directions (Eng)',
    type: 'text',
  },
  directions_thai: {
    dbKey: 'directions_thai',
    displayName: 'Directions (Thai)',
    type: 'text',
  },
  map: { dbKey: 'map', displayName: 'Map Link', type: 'url' },
};

export const contactConsts = {
  tel: { dbKey: 'tel', displayName: 'Telephone', type: 'tel' },
  email: { dbKey: 'email', displayName: 'Email', type: 'email' },
  facebook: { dbKey: 'facebook', displayName: 'Facebook Link', type: 'url' },
  line: { dbKey: 'line', displayName: 'Line Link', type: 'url' },
  instagram: { dbKey: 'instagram', displayName: 'Instagram Link', type: 'url' },
  tiktok: { dbKey: 'tiktok', displayName: 'TikTok Link', type: 'url' },
};
