import { useSelector } from 'react-redux'
import useLanguage from '../../hooks/use-language'
import useMediaQuery from '../../hooks/useMediaQuery'
import useTogglePrivacyPolicy from '../../hooks/useTogglePrivacyPolicy'
import SocialLink from '../UI/SocialLink'
import { formatPhone } from '../../util/formatters'

import LanguageSelector from './LanguageSelector'

import { storeConsts as str } from '../../constants/storeInfoConstants'





const NavFooter = () => {

    const { storeInfo } = useSelector(state => state.storeInfo)
    const togglePP = useTogglePrivacyPolicy()
    const { lang } = useLanguage();
    const sp = useMediaQuery()

    return <footer className='footer'>
        {!sp && <LanguageSelector />}
        {storeInfo && <>

            <div className='social-links'>
                <SocialLink url={storeInfo.facebook} icon={'facebook-square'} />
                <SocialLink url={storeInfo.line} icon={'line'} />
                <SocialLink url={storeInfo.youtube} icon={'youtube-square'} />
                <SocialLink url={storeInfo.instagram} icon={'instagram'} />
                <SocialLink url={storeInfo.tiktok} icon={'tiktok'} fs={sp ? 7 : 3.3} />
            </div>
            <div className='store-info'>
                <a className='store-info__tel' href={`tel:+66${storeInfo.tel}`}>
                    <span className="material-icons leading-icon">call</span>
                    {formatPhone(storeInfo.tel)}
                </a>
                <p className='store-info__hours'>{`Open: ${storeInfo[str.days_open.dbKey]} ${storeInfo[str.open_time.dbKey]}-${storeInfo[str.close_time.dbKey]}\nLast order: ${storeInfo[str.last_order.dbKey]}\nClose: ${storeInfo[str.days_closed.dbKey]}`}</p>
                <p className='store-info__address'>{storeInfo[lang === 'en' ? str.address_eng.dbKey : str.address_thai.dbKey]}</p>


            </div>
            {sp && <><div style={{ width: '100%', marginTop: '2rem' }}>
                <LanguageSelector align={'center'} />
            </div>
                <div className='footer__trailing'>
                    <a href="#" onClick={togglePP}>Privacy Policy</a>
                    <p>{`All Rights Reserved © ${new Date().getFullYear()}  `}</p>
                </div>
            </>}

        </>}
    </footer>
}

export default NavFooter;