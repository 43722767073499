import React from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import CustomRadioButton from '../UI/CustomRadioButton'
import { orderType } from 'common/constants/orderConstants'
import { paymentMethod } from 'common/constants/orderConstants'
import useLanguage from '../../hooks/use-language'
import { useSelector } from 'react-redux'

const PaymentRadio = ({ oType, paymentType, setValue, bankSlipUploadHandler, step }) => {

    const { storeInfo } = useSelector(state => state.storeInfo)
    const { lang, txt } = useLanguage()

    const bank = oType === orderType.DELIVERY || paymentType === paymentMethod.bank
    const selectedValue = step === 2 ? null : paymentType.displayName[lang]
    const sp = useMediaQuery()

    const radioSize = sp ? 3 : 2.2

    const paymentMethodHandler = (e, pType) => {

        if (e.target.checked && (pType === paymentMethod.bank || oType === orderType.PICKUP)) {
            setValue(pType.dbKey, 'paymentType')
        }
    }

    return (
        <div className='cart__radio'>
            {!selectedValue ? <>
                <div className='cart__radio-1'>
                    <CustomRadioButton checked={bank} size={radioSize} onChange={e => paymentMethodHandler(e, paymentMethod.bank)} label={paymentMethod.bank.displayName[lang]} />
                    {bank && storeInfo.paymentQR && <div className='bank-transfer-info'>
                        <p className='bank-transfer-info__msg'>{txt.MESSAGE_UPLOAD_QR}</p>
                        <div>
                            <img src={storeInfo.paymentQR} alt="Payment QR" />
                            <div className='bank-transfer-info__fields'>
                                <p className='bank-transfer-info__field'><strong>{txt.LABEL_BANK}: </strong>{storeInfo['bank_name']}</p>
                                <p className='bank-transfer-info__field'><strong>{txt.LABEL_BANK_ACCT}: </strong>{storeInfo['account_number']}</p>
                                <p className='bank-transfer-info__field'><strong>{txt.LABEL_BANK_NAME}: </strong>{storeInfo['account_name']}</p>
                                <input type="file" name="file" accept="image/jpg, image/jpeg, image/png, image/heic" onChange={bankSlipUploadHandler} />
                            </div>
                        </div>
                    </div>}
                </div>

                <div className={`cart__radio-2`}>
                    <CustomRadioButton size={radioSize} checked={!bank} onChange={e => paymentMethodHandler(e, paymentMethod.restaurant)} label={paymentMethod.restaurant.displayName[lang]} disabled={oType === orderType.DELIVERY} />

                </div>
            </> : <p>{selectedValue}</p>}
        </div>
    )
}

export default PaymentRadio