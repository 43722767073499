import moment from "moment"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom"

import useErrorHandler from "./use-error-handler"
import useMediaQuery from "./useMediaQuery"
import useLanguage from "./use-language"
import useFetchRequest from "./api/useFetchRequest"
import useUpdateRequest from "./api/useUpdateRequest"
import useFormUploadRequest from "./useFormUploadRequest"
import { useScheduleInfo } from "./useSchedule"

import { getStoreInfo } from "../store/actions/storeActions"

import BlockButton from "../components/buttons/BlockButton"

import { toggleLogin } from '../store/actions/loginScreenActions'
import { showError, showAlert } from "../store/actions/uiActions"

import { orderType, paymentMethod, getOrderTypeConst } from 'common/constants/orderConstants'
import { setMenuSubcategory } from 'common/constants/menuConstants';
import dir from 'common/constants/directoryConstants'
import { usr, crt } from '../constants/reduxConstants'
import { resetCart } from '../store/actions/cartActions'
import { errorType } from 'common/errors'



const useOrder = (saveAddress) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    //REDUX
    const { lastOrder } = useSelector(state => state.schedule)
    const { storeInfo } = useSelector(state => state.storeInfo)
    const { cartItems, notes } = useSelector(state => state.cart)
    const { userInfo } = useSelector(state => state.userLogin)

    //CUSTOM HOOKS
    const { getErrorMessage } = useErrorHandler()
    const { checkIsLunch, checkIsDinner } = useScheduleInfo()
    const { txt } = useLanguage()
    const sp = useMediaQuery();

    //NETWORK HOOKS
    const { performUpdateRequest, resetUpdateRequest } = useUpdateRequest()
    const { performFetchRequest: fetchSoldOut } = useFetchRequest(true)
    const { error: errorCreate, data: createData, clear: clearCreateRequest, performFormUploadRequest: performCreateRequest, loading } = useFormUploadRequest()

    // VALIDATION
    const [soldOut, setSoldOut] = useState([])
    const pickupOnly = cartItems.filter(i => !i.availableForDelivery).length > 0;

    // NAVIGATION
    const [step, setStep] = useState(0)
    const [prevStep, setPrevStep] = useState(0)

    // ORDER STATE
    const [bankSlip, setBankSlip] = useState(null)
    const [order, setOrder] = useState({
        customerName: '',
        _customerID: null,
        email: '',
        tel: '',
        address: { tambon: '400101', province: 'Khon Kaen' },
        orderType: pickupOnly ? orderType.PICKUP.dbKey : orderType.DELIVERY.dbKey,
        pickupTime: null,
        paymentType: paymentMethod.bank.dbKey,
    })
    const type = getOrderTypeConst(order.orderType)



    // * ORDER VALIDATION

    // CHECK FOR SOLD OUT
    useEffect(() => {
        fetchSoldOut('', dir.MENU_SOLD_OUT, null, (data) => {
            if (data.items) setSoldOut(data.items)
        })
    }, [])

    // CHECK FOR LUNCH/DINNER
    const getUnavailable = () => {
        let time = order.pickupTime ?? moment().format("HH:mm");
        let unavailable = []
        if (!checkIsLunch(time)) unavailable = [...unavailable, ...cartItems.filter((item) => item.subcategory === setMenuSubcategory.lunch.dbKey)]
        if (!checkIsDinner(time)) unavailable = [...unavailable, ...cartItems.filter((item) => item.subcategory === setMenuSubcategory.dinner.dbKey)]
        return unavailable
    }

    // * UPDATE ORDER
    useEffect(() => {
        if (userInfo) {
            setOrder(pVal => {
                return {
                    ...pVal,
                    tel: userInfo.tel,
                    customerName: userInfo.name,
                    _customerID: userInfo._id,
                    email: userInfo.email,
                    notes,
                    items: cartItems
                }
            })
        }

    }, [userInfo, cartItems, notes])

    const setValue = (v, key) => {

        setOrder(pVal => {
            return {
                ...pVal,
                [key]: v
            }
        })
    }

    const getTotal = () => cartItems.filter(i => !soldOut.includes(i._id)).reduce((a, b) => a + (b.price * b.amount), 0)

    const bankSlipUploadHandler = (e) => {
        setBankSlip(e.target.files[0])
    }

    const saveNewAddress = () => {
        if (!order.address.street) {
            return
        }
        resetUpdateRequest()

        performUpdateRequest({ addresses: [...userInfo.addresses, order.address] }, dir.USER_PROFILE, userInfo.token, (data) => {
            setOrder(pVal => ({ ...pVal, address: { ...pVal.address, _id: data.addresses[data.addresses.length - 1]._id } }))
            dispatch({ type: usr.LOGIN_SUCCESS, payload: data });
        })
    }


    // * SEND ORDER
    useEffect(() => {
        if (createData) {
            dispatch(showAlert("Success!", txt.MESSAGE_ORDER_SUCCESS))
            dispatch(resetCart())
            if (sp) {
                navigate('/')
            } else {
                dispatch({ type: crt.TOGGLE })
            }
        } else if (errorCreate) {
            dispatch(showError(getErrorMessage(errorCreate)))
            if (errorCreate.type === errorType.closed.type || errorCreate.type === errorType.lastOrder.type) {
                dispatch(getStoreInfo());
            }
            clearCreateRequest();
        }
    }, [createData, errorCreate])



    // * NAVIGATE FORM
    const stepBack = () => {
        const pStep = prevStep;
        setStep(pStep);
        if (pStep > 0) setPrevStep(pStep - 1)
    }

    const handleBack = (e) => {

        if (step > 1 && lastOrder) {

            e.preventDefault()
            if (window.confirm(txt.WARNING_CART_BACK_LAST_ORDER)) {
                stepBack()
            }
        } else if (step === 0) {

            if (sp && searchParams.get('redirect')) {
                navigate(searchParams.get('redirect'))
                return
            } else if (!storeInfo.open || lastOrder) {

                dispatch({ type: crt.TOGGLE })
                return
            }
            navigate('/menu')
            dispatch({ type: crt.TOGGLE })
        } else {

            stepBack()
        }
    }

    const move = (st) => {
        const cStep = step;
        setPrevStep(cStep)
        setStep(st)
        window.scrollTo(0, 0)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const unavailable = getUnavailable()
        if (unavailable.length > 0) {
            dispatch(showAlert("", txt.MESSAGE_SOLD_OUT_CART))
            return
        }

        if (step < 2 && lastOrder) {
            dispatch(showAlert("", txt.MESSAGE_LAST_ORDER))
            return
        }

        if (cartItems.filter(i => soldOut.includes(i._id)) > 0) {
            dispatch(showAlert("", txt.MESSAGE_SOLD_OUT_CART))
            return
        }

        if (step === 0) {

            const nextStep = type === orderType.DELIVERY ? 1 : 2;
            if (userInfo) {
                move(nextStep)
            } else {
                dispatch(toggleLogin(true, () => {
                    move(userInfo ? 2 : 1)
                }))
            }

        } else if (step === 1) {
            if (userInfo && userInfo.addresses.filter(a => a._id === order.address._id).length < 1 && saveAddress) {
                saveNewAddress()
            }
            move(2)


        } else if (step === 2) {
            if (order.paymentType === paymentMethod.bank.dbKey && bankSlip === null) {
                dispatch(showError(txt.ERROR_UPLOAD_BANKSLIP))

                return
            }
            performCreateRequest({ 'order': { ...order, total: getTotal(), items: cartItems, notes: notes } }, { ...(bankSlip && { 'image': bankSlip }) }, dir.ORDERS, null, `multipart/form-data`)
        }
    }

    const backBtn = <BlockButton width={`${sp ? '100%' : '40rem'}`} title={step === 0 ? (sp || !storeInfo.open || lastOrder) ? txt.BUTTON_BACK : txt.BUTTON_BACK_TO_MENU : txt.BUTTON_BACK} arrow='left' onClick={handleBack} />

    return { order, backBtn, step, soldOut, type, setValue, onSubmit, bankSlipUploadHandler, loading }
}

export default useOrder