import useScrollObserver from "../../hooks/use-scroll-observer";
import { useRef, useState, useEffect } from "react";
import classes from "./AnimateIn.module.css";



const AnimateIn = ({ direction = 'left', offset = 20, offsetAmt, startingOpacity, delay = 0, immediate = false, children, log, delayOnImmediate = 0 }) => {

    const ref = useRef(null);
    const { windowScroll, startingPosition, isVisible } = useScrollObserver(ref, log);



    const [didAnimate, setDidAnimate] = useState(false);
    const cn = `${classes['animated-div']} ${didAnimate && classes['visible']}`;
    const anOffsetAmt = offsetAmt ?? 300;
    const animationAxis = direction === 'left' || direction === 'right' ? 'X' : 'Y';
    const animationOffset = direction === 'left' || direction === 'up' ? anOffsetAmt : -anOffsetAmt;

    useEffect(() => {
        const scrollDif = windowScroll - startingPosition;
        // console.log(`current offset = ${ref.current.offsetTop} scroll = ${window.scrollY} start = ${startingPosition}`);
        if (log) {

            console.log(`windowScroll ${windowScroll} starting ${startingPosition} scrolldif ${scrollDif}`);
        }
        if (!didAnimate && isVisible) {

            if (scrollDif > offset || scrollDif === 0) {
                var d = delay;
                if (d === 0 && scrollDif === 0) {
                    if (log) { console.log(`going to use immediate delay ${d} ${scrollDif}`) }
                    d = delayOnImmediate;
                }

                if (log) {
                    console.log(`${log} delay ${d}`);
                }
                setTimeout(() => {
                    setDidAnimate(true);
                }, d)
            } else {
                if (log) {
                    console.log(`${log} not performing transition ${scrollDif} ${offset}`);
                }
            }
        } else if (!didAnimate && (immediate || window.scrollY > ref.current.offsetTop)) {
            setTimeout(() => {
                setDidAnimate(true);
            }, delay)
        }

    }, [isVisible, didAnimate, offset, windowScroll, ref, startingPosition])


    return <div ref={ref} className={cn} style={{
        transform: `translate${animationAxis}(${(didAnimate) ? '0' : `${animationOffset}px`})`,
        opacity: `${didAnimate ? 1 : startingOpacity ?? 0}`
    }}>
        {children}
    </div>

}

export default AnimateIn;