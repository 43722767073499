

import { useEffect, useRef, useState } from "react";
import useOnScreen from "./use-on-screen";



const useParallax = (ref, initialOffset, multiplier, limit = null) => {


    const isVisible = useOnScreen(ref);
    const [position, setPosition] = useState(initialOffset ?? 0);

    useEffect(() => {

        var preVal;
        const scrollHandler = () => {
            if (window.scrollY < 1) {
                setPosition(initialOffset ?? 0);
            }

            if (isVisible) {

                if (!preVal) {
                    preVal = window.scrollY;
                    return
                }
                const dif = window.scrollY - preVal;


                setPosition(pVal => {
                    const newValue = pVal + (dif * (multiplier ?? 0.01))
                    if (!limit || (Math.abs(newValue) <= limit)) {
                        preVal = window.scrollY;
                        return newValue
                    } else {
                        return pVal;
                    }

                }
                );



            } else {
                preVal = null;
            }


        }
        window.addEventListener('scroll', scrollHandler)

        return () => { window.removeEventListener('scroll', scrollHandler) }
    }, [isVisible])

    return position;


}

export default useParallax;

