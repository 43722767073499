import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import useLanguage from '../../../hooks/use-language';
import { addToCart } from '../../../store/actions/cartActions';
import AmountPicker from './AmountPicker';

const AddToCart = ({ item, sp, drink }) => {
    const { txt } = useLanguage()
    const [num, setNum] = useState(1);
    const dispatch = useDispatch()
    const bigMobile = !drink && sp


    const handleAdd = () => {
        dispatch(addToCart(item, num))
    }

    return <div className='menu-card__cart-row'>
        {(sp && !item.availableOnline) ? <div className='menu-card__no-online'> <p>Not available online</p></div> : <>
            <div className={`menu-card__amt${bigMobile ? ' lg' : ''}`}  >
                <AmountPicker disabled={!item.inStock || !item.availableOnline} item={item} onSet={(amt) => setNum(amt)} btnSize={sp ? drink ? 6 : 5.5 : 3} />

            </div>

            <div className='menu-card__cart-btn'>

                <button className={`menu-card__cart-btn--btn${!item.inStock || !item.availableOnline ? ' sold-out' : ''}`} onClick={handleAdd} disabled={!item.inStock || !item.availableOnline} >{item.inStock ? <i className="fas fa-shopping-basket" /> : txt.LABEL_SOLD_OUT}</button>
            </div>
        </>}

    </div >
}

export default AddToCart;