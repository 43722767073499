import axios from "axios";
import { useCallback, useState } from "react"


const useFetchRequest = (log) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);


    const performFetchRequest = useCallback(async (id, dir, token, completionHandler) => {

        try {
            setError(null)
            setLoading(true)

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
            }

            const { data } = await axios.get(
                `/api/${dir}${id}`,
                config
            );

            // if (log) { console.log('got data!') }

            setData(data)
            setLoading(false)

            completionHandler && completionHandler(data)
        } catch (error) {
            const err = error.response ? error.response.data ? error.response.data : error.response : error
            setLoading(false)
            setError(err)
        }

    })

    return { loading, error, data, performFetchRequest }

}

export default useFetchRequest