import { useEffect } from 'react'

const useDynamicScrollbar = (ref) => {
    useEffect(() => {
        const checkOverflow = () => {
            if (ref.current.scrollHeight > ref.current.clientHeight) {
                ref.current.style.overflowY = 'scroll'
            } else {
                ref.current.style.overflowY = 'hidden'
            }
        }

        // Initial check
        checkOverflow()

        // Add event listeners
        window.addEventListener('resize', checkOverflow)

        // Cleanup event listeners on unmount
        return () => window.removeEventListener('resize', checkOverflow)
    }, [ref])
}

export default useDynamicScrollbar